/* eslint-disable */
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./Components/Authentication/Login";
import Signup from "./Components/Authentication/Signup.jsx";
import Subscribe from "./Pages/Subscribe";
import Error from "./Pages/Error";
import Settings from "./Pages/Settings.jsx";
import Protected from "./Components/Authentication/Protected";
import { useState, useEffect } from "react";
import Chatbots from "./Components/Chatbot/Chatbots";
import { Provider } from "react-redux";
import store from "../src/Redux/store";
import Pricing from "./Pages/Pricing";
import Live_page from "./Components/Live/Live_page.jsx";
import Integrations from "./Pages/Integrations.jsx";
import { validateToken, login, logout } from "./Redux/Authentication/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "./Redux/User/userSlice";
import { Toaster } from "./Shadcn/ui/toaster";
import { useToast } from "./Shadcn/ui/use-toast.js";
import Calenders from "./Pages/calender.js";
import { SingleConversation } from "./Pages/SingleConversation";
import Dashboard from "./Pages/Dashboard.jsx";
import ChatDashboard from "./Pages/ChatDashboard.jsx";
import ChatFunction from "./Components/Chatbot/ChatFunction.jsx";
import EditFunction from "./Components/Chatbot/EditFunction.jsx";
import Sources from "./Components/Chatbot/Sources.jsx";
import ChatConversations from "./Components/Chatbot/ChatConversations.jsx";
import ChatAppointments from "./Components/Chatbot/ChatAppointments.jsx";
import CreateFunction from "./Components/Chatbot/CreateFunction.jsx";
import { GetLeads } from "./Components/Chatbot/GetLeads.jsx";
import GetIntegrations from "./Components/Chatbot/GetIntegrations.jsx";
import ChatWoocommerce from "./Components/Chatbot/ChatWoocommerce.jsx";
import AppointmentSettings from "./Components/Chatbot/ChatAppointmentsSettings.jsx";
import ChatEmbed from "./Components/Chatbot/ChatEmbed.jsx";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { fetchList as fetchLiveChatList } from "./Redux/Live/liveSlice";
import { socketDomain } from "./Env";
import EditLive from "./Components/Chatbot/LivePage.jsx";
import LiveChatPage from "./Pages/LiveChatPage/page.jsx";

// Paywall Components
import { ChatAppointments as Pay_Appts } from "./Components/Paywall/ChatAppointments.jsx";
import { ChatAppointmentsSettings as Pay_Appts_Settings } from "./Components/Paywall/ChatAppointmentsSettings.jsx";
import { ChatWoocommerce as Pay_Woo } from "./Components/Paywall/ChatWoocommerce.jsx";
import { GetLeads as Pay_Leads } from "./Components/Paywall/Leads.jsx";
import { Calenders as Pay_Cal } from "./Components/Paywall/Calender.jsx";
import { Live_Paw } from "./Components/Paywall/Live.jsx";
import LivePagePaywall from "./Components/Paywall/LivePage.jsx";

function App() {
  const authToken = localStorage.getItem("auth_token");
  const dispatch = useDispatch();
  const [initialised, setInitialised] = useState(false);
  const checked = useSelector((state) => state.auth.checked);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.user.user[0]);
  const allowed = useSelector((state) => state.user.features);
  const [socketUrl, setSocketUrl] = useState(null);
  const { toast } = useToast();
  let count = 0;

  useEffect(() => {
    if (user && user.id && user.id !== "" && allowed.LiveChat) {
      setSocketUrl(`ws://${socketDomain}ws/notify/${user.id}/`);
    }
  }, [user, allowed]);

  const { lastMessage } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => true,
    reconnectInterval: 3000,
  });

  // Effect for handling received messages
  useEffect(() => {
    if (lastMessage !== null) {
      const messageData = JSON.parse(lastMessage.data);
      toast({
        title: "New Live Chat",
        description: `A new live chat has started.`,
      });
      dispatch(fetchLiveChatList());
    }
  }, [lastMessage, dispatch, toast]);

  // Initial authentication and user data fetching
  useEffect(() => {
    if (!checked && authToken && count === 0) {
      dispatch(login());
      // dispatch(validateToken(authToken)); // Assuming this is commented out intentionally
      dispatch(fetchUserData(authToken));
      count++;
    }
    if (!authToken && checked) {
      dispatch(logout());
    }

    setInitialised(true);
  }, [authToken, checked, dispatch, user]);

  if (!initialised) {
    return null;
  }

  return (
    <div className="">
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            {/* Auth Routes  */}
            <Route path="/login" element={<Login />} />
            <Route path="/login/:token" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signup/:token" element={<Signup />} />
            <Route path="/login/fb/:token" element={<Login />} />
            <Route path="/signup/fb/:token" element={<Signup />} />

            {/* Dashboard Routes */}

            <Route
              path="/"
              element={
                <Protected isSignedIn={isAuthenticated} path={"/"}>
                  <Dashboard />
                </Protected>
              }
            >
              <Route
                path="/chatbots"
                element={
                  <Protected isSignedIn={isAuthenticated}>
                    <Chatbots />
                  </Protected>
                }
              />

              <Route
                path="/settings/:integration"
                element={
                  <Protected isSignedIn={isAuthenticated}>
                    <Settings />
                  </Protected>
                }
              />
              <Route
                path="/settings"
                element={
                  <Protected isSignedIn={isAuthenticated}>
                    <Settings />
                  </Protected>
                }
              />
              <Route
                path="/connections"
                element={
                  <Protected isSignedIn={isAuthenticated}>
                    <Integrations />
                  </Protected>
                }
              />
              <Route
                path="/calenders"
                element={
                  <Protected
                    isSignedIn={isAuthenticated}
                    isAllowed={allowed.AppointmentBooking}
                    Paywall={Pay_Cal}
                  >
                    <Calenders />
                  </Protected>
                }
              />
              {/* <Route
                path="/live"
                element={
                  <Protected
                    isSignedIn={isAuthenticated}
                    isAllowed={allowed.LiveChat}
                    Paywall={LivePagePaywall}
                  >
                    <LiveChatPage />
                  </Protected>
                }
              /> */}
            </Route>

            <Route
              path="/pricing"
              element={
                <Protected isSignedIn={isAuthenticated}>
                  <Pricing isSignedIn={isAuthenticated} />
                </Protected>
              }
            />

            {/* Chat Dashboard Routes */}
            <Route
              path="/chats/:chatId/"
              element={
                <Protected isSignedIn={isAuthenticated}>
                  <ChatDashboard />
                </Protected>
              }
            >
              <Route
                path="/chats/:chatId/chat"
                element={
                  <Protected isSignedIn={isAuthenticated}>
                    <ChatFunction />
                  </Protected>
                }
              />

              <Route
                path="/chats/:chatId/ai-settings"
                element={
                  <Protected isSignedIn={isAuthenticated}>
                    <EditFunction />
                  </Protected>
                }
              />

              <Route
                path="/chats/:chatId/customize"
                element={
                  <Protected isSignedIn={isAuthenticated}>
                    <ChatEmbed />
                  </Protected>
                }
              />

              <Route
                path="/chats/:chatId/conversation"
                element={
                  <Protected isSignedIn={isAuthenticated}>
                    <ChatConversations />
                  </Protected>
                }
              />

              <Route
                path="/chats/:chatId/sources"
                element={
                  <Protected isSignedIn={isAuthenticated}>
                    <Sources />
                  </Protected>
                }
              />

              <Route
                path="/chats/:chatId/appointments"
                element={
                  <Protected
                    isSignedIn={isAuthenticated}
                    isAllowed={allowed.AppointmentBooking}
                    Paywall={Pay_Appts}
                  >
                    <ChatAppointments />
                  </Protected>
                }
              />

              {/* <Route
                path="/chats/:chatId/live"
                element={
                  <Protected
                    isSignedIn={isAuthenticated}
                    isAllowed={allowed.LiveChat}
                    Paywall={Live_Paw}
                  >
                    <EditLive />
                  </Protected>
                }
              /> */}

              <Route
                path="/chats/:chatId/apt-settings"
                element={
                  <Protected
                    isSignedIn={isAuthenticated}
                    isAllowed={allowed.AppointmentBooking}
                    Paywall={Pay_Appts_Settings}
                  >
                    <AppointmentSettings />
                  </Protected>
                }
              />

              <Route
                path="/chats/:chatId/leads"
                element={
                  <Protected
                    isSignedIn={isAuthenticated}
                    isAllowed={allowed.LeadCollection}
                    Paywall={Pay_Leads}
                  >
                    <GetLeads />
                  </Protected>
                }
              />

              <Route
                path="/chats/:chatId/integrations"
                element={
                  <Protected isSignedIn={isAuthenticated}>
                    <GetIntegrations />
                  </Protected>
                }
              />

              <Route
                path="/chats/:chatId/woocommerce"
                element={
                  <Protected
                    isSignedIn={isAuthenticated}
                    isAllowed={allowed.WooCommerce}
                    Paywall={Pay_Woo}
                  >
                    <ChatWoocommerce />
                  </Protected>
                }
              />
            </Route>

            <Route
              path="/create_chat"
              element={
                <Protected isSignedIn={isAuthenticated}>
                  <CreateFunction />
                </Protected>
              }
            />

            <Route
              path="/conversation/:conversationId"
              element={
                <Protected isSignedIn={isAuthenticated}>
                  <SingleConversation />
                </Protected>
              }
            />
            <Route path="/thank-you" element={<Subscribe />} />
            <Route path="*" element={<Error />} />
          </Routes>
          <Toaster />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
