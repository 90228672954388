/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Domain } from '../../Env';

const initialState = {
  isAuthenticated: false,
  token: '',
  validateuse: 0,
  checked: false,
};

const validateToken = createAsyncThunk('auth/validateToken', async (token) => {
  const response = await fetch(`${Domain}account/api/validate_token/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      token,
    }),
  });

  const data = await response.json();
  return data.valid;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Login action
    login(state, action) {
      state.isAuthenticated = true;
    },

    // Logout action
    logout(state, action) {
      state.isAuthenticated = false;
      localStorage.removeItem('auth_token');
      localStorage.removeItem('user');
      state.checked = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateToken.pending, (state) => {})
      .addCase(validateToken.fulfilled, (state, action) => {
        if (action.payload === false) {
          state.isAuthenticated = false;
          state.checked = true;
          localStorage.removeItem('auth_token');
          localStorage.removeItem('user');
        } else if (action.payload === true) {
          state.isAuthenticated = true;
          state.checked = true;
        }
      })
      .addCase(validateToken.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.checked = true;
        localStorage.removeItem('auth_token');
        localStorage.removeItem('user');
      });
  },
});

export { validateToken };

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
