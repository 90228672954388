import React from "react";
import { Avatar } from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import nitrobotLogo from "../assets/Logo.svg";
import { RefreshCcwIcon, SendHorizonal } from "lucide-react";

function ChatEmbedViewer({
  initialMessage,
  chatName,
  bodyBg,
  header,
  bubble,
  ai_message,
  user_message,
  suggestionSettings,
}) {
  return (
    <div className="col-span-2 flex h-full w-full basis-1/2 flex-col items-center justify-center md:h-[85vh]">
      <div className="flex h-full flex-col items-center justify-center">
        <div
          className="mx-auto flex h-[85%] w-full flex-col justify-center overflow-hidden rounded-2xl border bg-white shadow-xl shadow-gray-400/20 md:w-[22rem]"
          style={{
            background: `${bodyBg || "#fff"}`,
          }}
        >
          <div className="border-gray flex justify-between border-b-2">
            <div
              style={{
                background: `${header.bg_color || "#431376"}`,
                color: `${header.text_color || "#FFF"}`,
              }}
              className=" flex w-full items-center justify-between gap-3 px-4 py-2 "
            >
              <div className="flex items-center gap-3">
                <Avatar
                  src={header.image ? header.image : nitrobotLogo}
                  alt="avatar"
                  size="sm"
                />
                {chatName}
              </div>
              <div className="flex gap-3">
                <RefreshCcwIcon className="h-6 w-6 text-white" />
                <XMarkIcon className="h-6 w-6 text-white" />
              </div>
            </div>
          </div>
          <div className="flex justify-start p-3">
            <small
              style={{
                background: `${ai_message.bg_color}`,
                color: `${ai_message.text_color}`,
                width: "fit-content",
                height: "fit-content",
              }}
              className="text-md rounded-lg px-4 py-2 text-gray-900"
            >
              {initialMessage}
            </small>
          </div>
          <div className="flex justify-end p-3 py-0">
            <small
              style={{
                background: `${user_message.bg_color}`,
                color: `${user_message.text_color}`,
                width: "fit-content",
                height: "fit-content",
              }}
              className="text-md rounded-lg px-4 py-2 text-gray-900"
            >
              Hi, I wanted ........
            </small>
          </div>
          <div className="flex flex-grow justify-end p-3 h-64 min-h-64"></div>{" "}
          <div className="">
            <div className="mx-2 flex flex-wrap-reverse justify-end ">
              {suggestionSettings.list.length > 0 &&
                suggestionSettings.list.map(
                  (item, index) =>
                    item !== "" && (
                      <div
                        key={index}
                        style={{
                          border:
                            suggestionSettings.suggestionStyle &&
                            suggestionSettings.suggestionStyle === "border" &&
                            `1px solid ${suggestionSettings.suggestionBg}`,
                          width: "fit-content",

                          background:
                            suggestionSettings.suggestionStyle &&
                            suggestionSettings.suggestionStyle === "bg" &&
                            `${suggestionSettings.suggestionBg}`,
                          color: `${suggestionSettings.suggestionText}`,
                        }}
                        className="mx-1 mb-2 rounded-md px-1.5 py-1.5 text-xs"
                      >
                        {item.trim()}
                      </div>
                    ),
                )}
            </div>
          </div>
          <div className="text-gray mx-3 flex items-center justify-between rounded-md border-2 bg-white px-4 py-3 text-sm">
            <span>Enter a Message...</span>{" "}
            <SendHorizonal
              style={{
                color: `${header.bg_color || "#431376"}`,
              }}
              className="h-5 w-5"
            />
          </div>
          <div className="w-full py-1 text-center text-sm text-gray-700">
            Powered by Nitrobot
          </div>
        </div>

        <div className="ml-auto mt-3 md:w-80">
          {bubble.image && bubble.image !== "" ? (
            <Avatar
              className={`float-right object-contain p-0.5`}
              style={
                bubble.bg_color
                  ? {
                      background: `${bubble.bg_color}`,
                      color: `${bubble.text_color}`,
                    }
                  : { background: "#282828", color: "#fff" }
              }
              src={bubble.image}
              alt="Bubble Image"
              size="lg"
            />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              fill={`${bubble.text_color || "#fff"}`}
              className="float-right h-14 w-14 rounded-full p-2"
              style={
                bubble.bg_color && bubble.text_color
                  ? { background: `${bubble.bg_color}` }
                  : { background: "#282828" }
              }
            >
              <path d="M11 18H7a1 1 0 0 0-.71.29L4 20.59V7.74A.74.74 0 0 1 4.74 7h13.52a.74.74 0 0 1 .74.74V9a1 1 0 0 0 2 0V7.74A2.74 2.74 0 0 0 18.26 5H4.74A2.74 2.74 0 0 0 2 7.74V23a1 1 0 0 0 .62.92A.84.84 0 0 0 3 24a1 1 0 0 0 .71-.29L7.41 20H11a1 1 0 0 0 0-2z" />
              <path d="M29 18a1 1 0 0 0 1-1v-3.26A2.74 2.74 0 0 0 27.26 11H15.74A2.74 2.74 0 0 0 13 13.74v7.52A2.74 2.74 0 0 0 15.74 24h8.85l3.7 3.71A1 1 0 0 0 29 28a.84.84 0 0 0 .38-.08A1 1 0 0 0 30 27v-6a1 1 0 0 0-2 0v3.59l-2.29-2.3A1 1 0 0 0 25 22h-9.26a.74.74 0 0 1-.74-.74v-7.52a.74.74 0 0 1 .74-.74h11.52a.74.74 0 0 1 .74.74V17a1 1 0 0 0 1 1z" />
            </svg>
          )}
        </div>
      </div>
      
    </div>
  );
}

export default ChatEmbedViewer;
