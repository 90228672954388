import React, { useState, useEffect } from "react";
import "./style.css";
import { Button } from "../../Shadcn/ui/button";
import axios from "axios";
import { apiDomain, whatsAppverifyToken } from "../../Env";
import { useSelector } from "react-redux";
import { SiTwilio } from "react-icons/si";
import { Label } from "../../Shadcn/ui/label";
import { Input } from "../../Shadcn/ui/input";
import { Switch } from "../../Shadcn/ui/switch";
import { Clipboard } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../../Shadcn/ui/dialog";
import { useToast } from "../../Shadcn/ui/use-toast";
import { Copy } from "lucide-react";
import { fetchChatData } from "../../Redux/Chat/dtlSlice";
import { useDispatch } from "react-redux";

export default function Whatsapp() {
  const token = localStorage.getItem("auth_token");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const chatId = useSelector((state) => state.dtl.chat_id);
  const twilioSettings = useSelector((state) => state.dtl.twilio);
  const dispatch = useDispatch();

  // Messenger Settings
  const [twilio_enabled, setTwilioEnabled] = useState(false);
  const [twilio_account_sid, setTwilioAccountSid] = useState("");
  const [twilio_account_auth_token, setTwilioAuthToken] = useState("");
  const [twilio_phone_number, setTwilioPhoneNumber] = useState("");

  const { toast } = useToast();

  useEffect(() => {
    if (twilioSettings) {
      setTwilioEnabled(twilioSettings.twilio_enabled || false);
      setTwilioAccountSid(twilioSettings.twilio_account_sid || "");
      setTwilioAuthToken(twilioSettings.twilio_account_auth_token || "");
      setTwilioPhoneNumber(twilioSettings.twilio_phone_number || "");
    }
  }, [chatId]);

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("twilio_enabled", twilio_enabled);
    formData.append("twilio_account_sid", twilio_account_sid);
    formData.append("twilio_account_auth_token", twilio_account_auth_token);
    formData.append("twilio_phone_number", twilio_phone_number);

    try {
      setLoading(true);
      axios
        .post(`${apiDomain}twilio/edit-settings/${chatId}/`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setLoading(false);
          if (response.data.success) {
            dispatch(fetchChatData(chatId));
            toast({
              title: "Success!",
              description: response.data.success,
            });
          } else if (response.data.error) {
            toast({
              variant: "destructive",
              title: "Error!",
              description: response.data.error,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response && error.response.data.error) {
            toast({
              variant: "destructive",
              title: "Error!",
              description: error.response.data.error,
            });
          } else {
            toast({
              variant: "destructive",
              title: "Error!",
              description: "Something went wrong.",
            });
          }
        });
    } catch (error) {
      if (error.response && error.response.data.error) {
        toast({
          variant: "destructive",
          title: "Error!",
          description: error.response.data.error,
        });
      } else {
        toast({
          variant: "destructive",
          title: "Error!",
          description: "Something went wrong.",
        });
      }
    }
  };

  const CopyToClipboard = () => {
    navigator.clipboard.writeText(`${apiDomain}twilio/webhook/${chatId}/`);
    toast({
      title: "Success!",
      description: "Webhook URL Copied to clipboard.",
    });
  };

  return (
    <>
      <div className="flex h-[200px] w-full flex-col justify-between rounded-2xl border bg-white p-6 pt-8 hover:shadow-lg">
        <div>
          <div className={`flex items-center justify-start`}>
            <SiTwilio className="h-10 w-10 text-black" />
          </div>
          <h6 className="mb-3 mt-4 font-normal tracking-tight text-black sm:text-sm lg:text-xl">
            Twilio
          </h6>
        </div>
        <div className="flex w-full">
          <Dialog>
            <DialogTrigger asChild>
              <Button className="w-full">Settings</Button>
            </DialogTrigger>
            <DialogContent className="w-full text-black lg:w-2/5">
              <DialogHeader>
                <DialogTitle className="text-xl font-semibold text-black">
                  Twilio Settings
                </DialogTitle>
                <DialogDescription>
                  You can connect your Twilio number to nitrobot here.
                </DialogDescription>
              </DialogHeader>

              <div className="flex w-full items-center justify-between rounded-md bg-white py-3 ">
                <Label
                  htmlFor="twilio_enabled"
                  className="text-md font-semibold"
                >
                  Enable
                </Label>
                <Switch
                  id="twilio_enabled"
                  checked={twilio_enabled}
                  onCheckedChange={() => setTwilioEnabled(!twilio_enabled)}
                />
              </div>

              {twilio_enabled && (
                <>
                  <div className="space-y-1.5">
                    <Label htmlFor="webhook_url">Webhook URL</Label>

                    <div className="flex w-full items-center gap-x-4">
                      <Input
                        type="text"
                        id="webhook_url"
                        placeholder="Webhook URL"
                        readonly
                        value={`${apiDomain}twilio/webhook/${chatId}/`}
                        className="w-full"
                      />

                      <Button type="button" size="sm" className="px-3">
                        <span className="sr-only">Copy</span>
                        <Copy
                          className="h-4 w-4"
                          onClick={() => {
                            CopyToClipboard();
                          }}
                        />
                      </Button>
                    </div>
                  </div>

                  <div className="space-y-1.5">
                    <Label htmlFor="twilio_account_sid">Account SID</Label>
                    <Input
                      type="text"
                      id="twilio_account_sid"
                      placeholder="Account SID"
                      value={twilio_account_sid}
                      onChange={(e) => setTwilioAccountSid(e.target.value)}
                      className="w-full"
                    />
                  </div>

                  <div className="space-y-1.5">
                    <Label htmlFor="twilio_account_auth_token">
                      Auth Token
                    </Label>
                    <Input
                      type="text"
                      id="twilio_account_auth_token"
                      placeholder="Auth Token"
                      value={twilio_account_auth_token}
                      onChange={(e) => setTwilioAuthToken(e.target.value)}
                      className="w-full"
                    />
                  </div>

                  <div className="space-y-1.5">
                    <Label htmlFor="twilio_phone_number">Phone Number</Label>
                    <Input
                      type="text"
                      id="twilio_phone_number"
                      placeholder="Phone Number"
                      value={twilio_phone_number}
                      onChange={(e) => setTwilioPhoneNumber(e.target.value)}
                      className="w-full"
                    />
                    <p className="text-xs">
                      Enter phone number with country code (e.g. +15556784546)
                    </p>
                  </div>
                </>
              )}

              <DialogFooter>
                <Button onClick={handleSubmit} disabled={loading}>
                  {loading && (
                    <div
                      className="text-surface inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                      role="status"
                    >
                      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                      </span>
                    </div>
                  )}
                  Save
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </>
  );
}
