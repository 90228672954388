/* eslint-disable */
import Facebook from "../Integrations/Facebook";
import Whatsapp from "../Integrations/Whatsapp";
// import Whatsapp from "../IntegrationPage/WhatsappPage";
import Twilio from "../Integrations/Twillio";
import { SiSlack, SiZapier } from "react-icons/si";
import { Button } from "../../Shadcn/ui/button";
import { useSelector } from "react-redux";
import {
  FacebookPaywall,
  WhatsappPaywall,
  TwilioPaywall,
} from "../Paywall/ChatIntegrations";

export default function GetIntegrations() {
  const allowed = useSelector((state) => state.user.features);
  const onSlackConnect = () => {
    return;
  };
  const Integration = [
    {
      title: " Slack",
      subtitle: "Connect your Slack to your account",
      icon: <SiSlack className="h-10 w-10 text-black" />,
      status: "Dis-Connected",
      function: onSlackConnect,
      disabled: true,
    },
    {
      title: "Zapier",
      subtitle: "Connect your Zapier to your account",
      icon: <SiZapier className="h-10 w-10 text-black" />,
      status: "Dis-Connected",
      function: onSlackConnect,
      disabled: true,
    },
  ];

  return (
    <div className="h-[92vh] min-h-[92vh] px-8">
      <div className="grid grid-cols-1 gap-4 py-8 sm:mb-0 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {allowed.FacebookMessenger ? <Facebook /> : <FacebookPaywall />}
        {allowed.WhatsApp ? <Whatsapp /> : <WhatsappPaywall />}
        {allowed.Twilio ? <Twilio /> : <TwilioPaywall />}

        {Integration.map((integration) => (
          <div className="border-stone-600 transition-smooth flex h-[200px] w-full flex-col justify-between rounded-xl border bg-white p-6 pt-8 duration-100 ease-in-out hover:shadow-md">
            <div>
              <div className={`flex items-center justify-start`}>
                {integration.icon}
              </div>
              <h6 className="mt-4 font-normal tracking-tight text-[#22281E] sm:text-sm lg:text-xl">
                {integration.title}
              </h6>
            </div>
            <div
              className={`w-full ${
                integration.secondary ? "flex gap-x-2" : ""
              }`}
            >
              <Button
                onClick={(e) => integration.function()}
                className="w-full"
                disabled={integration.disabled}
              >
                {integration.disabled ? "Coming Soon" : "Connect"}
              </Button>{" "}
              {integration.secondary ? (
                <Button
                  onClick={(e) => integration.secondary()}
                  className="w-full"
                >
                  Subscribe
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
