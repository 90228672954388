/* eslint-disable */
import { Link } from 'react-router-dom';
import { cn } from '../../lib/utils';
import { buttonVariants } from '../../Shadcn/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../Shadcn/ui/tooltip';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';


export function Nav({ links, isCollapsed, selectedLink, onSelectLink }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/') {
    } else {
      onSelectLink(location.pathname);
    }
  }, [location.pathname, navigate]);
  return (
    <div
      data-collapsed={isCollapsed}
      className="group flex flex-col gap-4 py-1 data-[collapsed=true]:py-1"
    >
      <nav className="grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
        {links.map((link, index) => {
          const isSelected = selectedLink === link.to;
          const variant = isSelected ? 'default' : 'ghost';

          return isCollapsed ? (
            <Tooltip key={index} delayDuration={0}>
              <TooltipTrigger asChild>
                <Link
                  to={link.to}
                  onClick={() => onSelectLink(link.to)}
                  className={cn(
                    buttonVariants({ variant: variant, size: 'icon' }),
                    'h-9 w-9',
                    variant === 'default' &&
                      'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white'
                  )}
                >
                  <link.icon
                    className="h-4 w-4"
                    color={variant === 'default' ? '#ffffff' : '#000000'}
                  />
                  <span className="sr-only">{link.title}</span>
                </Link>
              </TooltipTrigger>
              <TooltipContent side="right" className="flex items-center gap-4">
                {link.title}
                {link.label && (
                  <span className="ml-auto text-muted-foreground">
                    {link.label}
                  </span>
                )}
              </TooltipContent>
            </Tooltip>
          ) : (
            <Link
              key={index}
              to={link.to}
              onClick={() => onSelectLink(link.to)}
              className={cn(
                buttonVariants({ variant: variant, size: 'sm' }),
                'justify-start'
              )}
            >
              <link.icon
                className="mr-2 h-4 w-4"
                color={variant === 'default' ? '#ffffff' : '#000000'}
              />
              {link.title}
              {link.label && (
                <span
                  className={cn(
                    'ml-auto',
                    variant === 'default' && 'text-background dark:text-white'
                  )}
                >
                  {link.label}
                </span>
              )}
            </Link>
          );
        })}
      </nav>
    </div>
  );
}
