import React, { useState, useEffect } from "react";
import "./style.css";
import { Button } from "../../Shadcn/ui/button";
import axios from "axios";
import { apiDomain, whatsAppverifyToken } from "../../Env";
import { useSelector } from "react-redux";
import { SiMessenger } from "react-icons/si";
import { Label } from "../../Shadcn/ui/label";
import { Input } from "../../Shadcn/ui/input";
import { Switch } from "../../Shadcn/ui/switch";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../../Shadcn/ui/dialog";
import { Copy } from "lucide-react";
import { TbFidgetSpinner } from "react-icons/tb";
import { useToast } from "../../Shadcn/ui/use-toast";
import { fetchChatData } from "../../Redux/Chat/dtlSlice";
import { useDispatch } from "react-redux";

export default function Facebook() {
  const dispatch = useDispatch();
  const URL = apiDomain;
  const token = localStorage.getItem("auth_token");
  const [loading, setLoading] = useState(false);
  const chatId = useSelector((state) => state.dtl.chat_id);
  const messengerSettings = useSelector(
    (state) => state.dtl.facebook_messenger,
  );
  const { toast } = useToast();

  // Messenger Settings
  const [fb_connected, setFbConnected] = useState(false);
  const [fb_page_id, setFbPageId] = useState("");
  const [fb_app_id, setFbAppId] = useState("");
  const [fb_app_secret, setFbAppSecret] = useState("");
  const [fb_verify_token, setFbVerifyToken] = useState("");
  const [fb_page_access_token, setFbPageAccessToken] = useState("");

  useEffect(() => {
    if (messengerSettings) {
      setFbConnected(messengerSettings.fb_connected || false);
      setFbPageId(messengerSettings.fb_page_id || "");
      setFbAppId(messengerSettings.fb_app_id || "");
      setFbAppSecret(messengerSettings.fb_app_secret || "");
      setFbVerifyToken(messengerSettings.fb_verify_token || "");
      setFbPageAccessToken(messengerSettings.fb_page_access_token || "");
    }
  }, [chatId]);

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("fb_connected", fb_connected);
    formData.append("fb_page_id", fb_page_id);
    formData.append("fb_app_id", fb_app_id);
    formData.append("fb_app_secret", fb_app_secret);
    formData.append("fb_verify_token", fb_verify_token);
    formData.append("fb_page_access_token", fb_page_access_token);

    try {
      setLoading(true);
      axios
        .post(`${apiDomain}api/edit-messenger-settings/${chatId}/`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setLoading(false);
          if (response.data.success) {
            dispatch(fetchChatData(chatId));
            toast({
              title: "Success!",
              description: response.data.success,
            });
          } else if (response.data.error) {
            toast({
              variant: "destructive",
              title: "Error!",
              description: response.data.error,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response && error.response.data.error) {
            toast({
              variant: "destructive",
              title: "Error!",
              description: error.response.data.error,
            });
          } else {
            toast({
              variant: "destructive",
              title: "Error!",
              description: "Something went wrong.",
            });
          }
        });
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data.error) {
        toast({
          variant: "destructive",
          title: "Error!",
          description: error.response.data.error,
        });
      } else {
        toast({
          variant: "destructive",
          title: "Error!",
          description: "Something went wrong.",
        });
      }
    }
  };

  const handleURLcopy = () => {
    navigator.clipboard.writeText(`${URL}api/meta-message-webhook/${chatId}/`);
    toast({
      title: "Success!",
      description: "Webhook URL Copied to clipboard.",
    });
  };

  const handleVerifyTokenCopy = () => {
    navigator.clipboard.writeText(fb_verify_token);
    toast({
      title: "Success!",
      description: "Verify Token Copied to clipboard.",
    });
  };

  return (
    <>
      <div className="flex h-[200px] w-full flex-col justify-between rounded-2xl border bg-white p-6 pt-8 hover:shadow-lg">
        <div>
          <div className={`flex items-center justify-start`}>
            <SiMessenger className="h-10 w-10 text-black" />
          </div>
          <h6 className="mb-3 mt-4 font-normal tracking-tight text-black sm:text-sm lg:text-xl">
            Facebook Messenger
          </h6>
        </div>
        <div className="flex w-full">
          <Dialog>
            <DialogTrigger asChild>
              <Button className="w-full">Settings</Button>
            </DialogTrigger>
            <DialogContent className="w-full min-w-[55vw] text-black lg:w-2/5">
              <DialogHeader>
                <DialogTitle className="text-xl font-semibold text-black">
                  Facebook Messenger Settings
                </DialogTitle>
                <DialogDescription>
                  You can connect your messenger to nitrobot here.
                </DialogDescription>
              </DialogHeader>

              <div className="mx-auto h-[100%] w-[95%] space-y-4  ">
                <div className="flex w-full items-center justify-between rounded-md bg-white py-3 ">
                  <Label
                    htmlFor="fb_connected"
                    className="text-md font-semibold"
                  >
                    Enable Facebook
                  </Label>
                  <Switch
                    id="fb_connected"
                    checked={fb_connected}
                    onCheckedChange={() => setFbConnected(!fb_connected)}
                  />
                </div>
                {fb_connected && (
                  <>
                    <div className="grid w-full grid-cols-2 items-center justify-center gap-x-4">
                      <div className="grid flex-1 gap-2">
                        <Label htmlFor="W-URL" className="text-black">
                          Webhook URL
                        </Label>
                        <div className="flex w-full items-center gap-x-4">
                          <Input
                            id="W-URL"
                            value={`${URL}api/meta-message-webhook/${chatId}/`}
                            readOnly
                          />
                          <Button type="button" size="sm" className="px-3">
                            <span className="sr-only">Copy</span>
                            <Copy
                              onClick={() => {
                                handleURLcopy();
                              }}
                              className="h-4 w-4"
                            />
                          </Button>
                        </div>
                      </div>
                      <div className="grid flex-1 gap-2">
                        <Label htmlFor="V-T" className="text-black">
                          Verify token
                        </Label>
                        <div className="flex w-full items-center gap-x-4">
                          <Input
                            id="V-T"
                            defaultValue={fb_verify_token}
                            value={fb_verify_token}
                            onChange={(e) => setFbVerifyToken(e.target.value)}
                            // readOnly
                          />
                          <Button type="button" size="sm" className="px-3">
                            <span className="sr-only">Copy</span>
                            <Copy
                              onClick={() => {
                                handleVerifyTokenCopy();
                              }}
                              className="h-4 w-4"
                            />
                          </Button>
                        </div>
                      </div>
                    </div>

                    <div className="grid w-full grid-cols-2 items-center justify-center gap-x-4">
                      <div className="grid flex-1 gap-2">
                        <Label htmlFor="App_sec" className="text-black">
                          Facebook App Secret
                        </Label>
                        <Input
                          id="App_sec"
                          placeholder="App Secret"
                          value={fb_app_secret}
                          onChange={(e) => setFbAppSecret(e.target.value)}
                          className="w-full"
                        />
                      </div>
                      <div className="grid flex-1 gap-2">
                        <Label htmlFor="fb_app_id" className="text-black">
                          Facebook App ID
                        </Label>
                        <Input
                          id="fb_app_id"
                          placeholder="App ID"
                          value={fb_app_id}
                          onChange={(e) => setFbAppId(e.target.value)}
                          className="w-full"
                        />
                      </div>
                    </div>

                    <div className="grid w-full grid-cols-2 items-center justify-center gap-x-4">
                      <div className="grid flex-1 gap-2">
                        <Label htmlFor="fb_page_id" className="text-black">
                          Facebook Page ID
                        </Label>
                        <Input
                          id="fb_page_id"
                          placeholder="Page ID"
                          value={fb_page_id}
                          onChange={(e) => setFbPageId(e.target.value)}
                          className="w-full"
                        />
                      </div>
                      <div className="grid flex-1 gap-2">
                        <Label htmlFor="WP-Token" className="text-black">
                          Page Access Token
                        </Label>
                        <Input
                          id="WP-Token"
                          placeholder="Page Access Token"
                          value={fb_page_access_token}
                          onChange={(e) => setFbPageAccessToken(e.target.value)}
                          className="w-full"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <DialogFooter>
                <div className="mt-4 flex w-full justify-end">
                  <Button
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <TbFidgetSpinner className="mr-2 h-4 w-4 animate-spin" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </>
  );
}
