import { configureStore } from "@reduxjs/toolkit";
import auth from "./Authentication/authSlice";
import dtl from "./Chat/dtlSlice";
import user from "./User/userSlice";
import intc from "./Instances/intcSlice";
import chats from "./Chat/chatSlice";
import live from "./Live/liveSlice";
import singleLiveChat from "./Live/singleLiveChat";

export default configureStore({
  reducer: {
    auth,
    dtl,
    user,
    intc,
    chats,
    live,
    singleLiveChat,
  },
});
