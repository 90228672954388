/* eslint-disable */
import { Button } from "../../Shadcn/ui/button";
import React, { useState, useEffect } from "react";
import { SiGooglecalendar } from "react-icons/si";
import { IoTrashOutline } from "react-icons/io5";
import { Clipboard } from "lucide-react";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../Shadcn/ui/dialog";
import { Label } from "../../Shadcn/ui/label";
import { Input } from "../../Shadcn/ui/input";
import { useToast } from "../../Shadcn/ui/use-toast";
import { TbFidgetSpinner } from "react-icons/tb";
import { apiDomain, Domain, Home } from "../../Env";
import { getGoogleCalendarConnections } from "../Connections/google-calendar-connections";

function IntegrationsGoogleCalendar() {
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [clientId, setClientId] = useState("");
  const [email, setEmail] = useState("");
  const [connections, setConnections] = useState([]);
  const [addConnectionStep, setAddConnectionStep] = useState(1);
  const { toast } = useToast();
  const auth_token = localStorage.getItem("auth_token");

  useEffect(() => {
    const fetchConnections = async () => {
      const connections = await getGoogleCalendarConnections();
      // console.log(connections);
      setConnections(connections);
    };

    fetchConnections();
  }, [auth_token]);

  const handleCreateConnection = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("email", email);

    axios
      .post(`${apiDomain}account/api/create-gcal-connection/`, formData, {
        headers: { Authorization: `Bearer ${auth_token}` },
      })
      .then((response) => {
        toast({
          title: "Success!",
          description:
            "Data added successfully. You will now be redirected to Google to authorize the connection.",
        });

        setClientSecret("");
        setConnections([...connections, response.data.connection]);
        setAddConnectionStep(2);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.response.data);
        toast({
          variant: "destructive",
          title: "An error occurred",
          description:
            "There was an error adding the connection. Please try again.",
        });
        setLoading(false);
      });
  };

  const handleAddConnectionData = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("google_client_id", clientId);
    formData.append("google_client_secret", clientSecret);
    formData.append(
      "connection_id",
      connections[connections.length - 1].connection_id,
    );

    axios
      .post(`${apiDomain}account/api/add-gcal-connection-data/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((response) => {
        // console.log(response.data);
        toast({
          title: "Success!",
          description:
            response.data.message || "Connection created successfully.",
        });

        setConnections(connections.slice(0, -1));
        setConnections([...connections, response.data.connection]);
        setAddConnectionStep(1);
        setTimeout(() => {
          handleConnectIntent(response.data.connection.connection_id);
        }, 3000);

        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.response.data);
        toast({
          variant: "destructive",
          title: "An error occurred",
          description:
            error.response.data.message ||
            "There was an error adding the connection. Please try again.",
        });
        setLoading(false);
      });
  };

  const handleConnectIntent = (connection_id) => {
    axios
      .get(`${apiDomain}account/api/get-auth-url/${connection_id}/`, {
        headers: { Authorization: `Bearer ${auth_token}` },
      })
      .then((response) => {
        window.location.href = response.data.redirect;
      })
      .catch((error) => {
        toast({
          variant: "destructive",
          title: "An error occurred",
          description:
            error.response.data.message ||
            "There was an error connecting your account. Please try again.",
        });
      });
  };

  const handleDeleteIntent = (connection_id) => {
    const formData = new FormData();
    formData.append("connection_id", connection_id);

    axios
      .post(`${apiDomain}account/api/delete-gcal-connection/`, formData, {
        headers: { Authorization: `Bearer ${auth_token}` },
      })
      .then((response) => {
        // console.log(response.data);
        toast({
          title: "Success!",
          description:
            response.data.message || "Connection deleted successfully.",
        });

        setConnections(
          connections.filter(
            (connection) => connection.connection_id !== connection_id,
          ),
        );
      })
      .catch((error) => {
        // console.log(error.response.data);
        toast({
          variant: "destructive",
          title: "An error occurred",
          description:
            error.response.data.message ||
            "There was an error while deleting the connection. Please try again.",
        });
      });
  };

  return (
    <>
      <div className="border-stone-600 transition-smooth flex h-[200px] w-full flex-col justify-between rounded-xl border bg-white p-6 pt-8 duration-100 ease-in-out hover:shadow-md">
        <div>
          <SiGooglecalendar className="h-10 w-10 text-black" />
          <h6 className="mt-4 font-normal tracking-tight text-[#22281E] sm:text-sm lg:text-xl">
            Google Calendar
          </h6>
        </div>
        <div className="flex gap-2">
          {/* View Connections */}
          <Dialog>
            <DialogTrigger asChild className="flex-1">
              <Button className="">View</Button>
            </DialogTrigger>

            <DialogContent className="min-w-[30vw] text-black">
              <DialogHeader>
                <DialogTitle className="text-black">My Connections</DialogTitle>
              </DialogHeader>

              <div className="flex flex-col gap-2">
                {connections.length > 0 ? (
                  connections.map((connection) => (
                    <div
                      key={connection.connection_id}
                      className="rounded-md border p-2"
                    >
                      <p className="text-black">
                        Email: {connection.details.email}
                      </p>
                      <p className="text-sm text-black/50">
                        Client Secret: {connection.details.google_client_secret}
                      </p>
                      <p className="text-sm text-black/50">
                        Client ID: {connection.details.google_client_id}
                      </p>
                      {connection.details.connection_status ? (
                        <Button className="mt-3 cursor-not-allowed bg-green-800 text-white">
                          Connected ✔
                        </Button>
                      ) : (
                        <Button
                          className="mt-3"
                          onClick={() =>
                            handleConnectIntent(connection.connection_id)
                          }
                        >
                          Connect
                        </Button>
                      )}
                      <Dialog>
                        <DialogTrigger asChild className="flex-1">
                          <Button className="ml-3 bg-red-100 text-red-700 hover:bg-red-700 hover:text-white">
                            <IoTrashOutline className="mr-2" /> Delete
                          </Button>
                        </DialogTrigger>
                        <DialogContent className="min-w-[30vw] text-black">
                          <DialogHeader>
                            <span className="text-xl font-medium">
                              Confirmation
                            </span>
                          </DialogHeader>
                          <p>
                            Are you sure you want to delete this connection?
                            <br></br>
                            Chatbots that have this connection will no longer be
                            able to use it.
                          </p>
                          <Button
                            className="bg-red-100 text-red-700 hover:bg-red-700 hover:text-white"
                            onClick={() =>
                              handleDeleteIntent(connection.connection_id)
                            }
                          >
                            <IoTrashOutline className="mr-2" />
                            Delete
                          </Button>
                        </DialogContent>
                      </Dialog>
                    </div>
                  ))
                ) : (
                  <p className="text-black/50">
                    You have not added any connections yet
                  </p>
                )}
              </div>
            </DialogContent>
          </Dialog>
          {/* Add a new Connection */}
          <Dialog>
            <DialogTrigger asChild className="flex-1">
              <Button className="">Add</Button>
            </DialogTrigger>

            <DialogContent className="min-w-[30vw] text-black">
              <DialogHeader>
                <DialogTitle className="text-black">
                  Add a New Connection
                </DialogTitle>
                <DialogDescription>
                  Read the docs on how to add a new connection{" "}
                  <a href="#" className="underline" target="_blank">
                    here
                  </a>
                </DialogDescription>
              </DialogHeader>
              {connections.length > 0 && addConnectionStep === 2 && (
                <form onSubmit={handleAddConnectionData} className="space-y-2">
                  <div className="space-y-1.5">
                    <Label htmlFor="redired_uri">Authorized Redirect URI</Label>
                    <div className="flex gap-2">
                      <Input
                        type="text"
                        id="redired_uri"
                        placeholder="Authorized Redirect URI"
                        disabled
                        value={`${apiDomain}account/gcal-callback/${connections[connections.length - 1].connection_id}/`}
                        className="w-full"
                      />

                      <Button variant="secondary" className="border">
                        <Clipboard
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${apiDomain}account/gcal-callback/${connections[connections.length - 1].connection_id}/`,
                            );

                            toast({
                              title: "Copied to clipboard",
                              description:
                                "Authorized Redirect URI copied to clipboard",
                            });
                          }}
                        />
                      </Button>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <div>
                      <Label htmlFor="client-id" className="text-black">
                        Client ID
                      </Label>
                      <Input
                        id="client-id"
                        className="border"
                        placeholder="Client ID"
                        required
                        value={clientId}
                        onChange={(e) => setClientId(e.target.value)}
                      />
                    </div>
                    <div>
                      <Label htmlFor="client-secret" className="text-black">
                        Client Secret
                      </Label>
                      <Input
                        id="client-secret"
                        className="border"
                        placeholder="Client Secret"
                        required
                        value={clientSecret}
                        onChange={(e) => setClientSecret(e.target.value)}
                      />
                    </div>
                    <div>
                      <Button type="submit" disabled={loading}>
                        {loading ? (
                          <>
                            <TbFidgetSpinner className="h-4 w-4 animate-spin" />
                            <span className="pl-2">Adding</span>
                          </>
                        ) : (
                          "Confirm"
                        )}
                      </Button>
                    </div>
                  </div>
                </form>
              )}
              {addConnectionStep === 1 && (
                <form onSubmit={handleCreateConnection}>
                  <div className="flex flex-col gap-2">
                    <div>
                      <Label htmlFor="email-address" className="text-black">
                        Email Address
                      </Label>
                      <Input
                        id="email-address"
                        className="border"
                        placeholder="Email Address"
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div>
                      <Button type="submit" disabled={loading}>
                        {loading ? (
                          <>
                            <TbFidgetSpinner className="h-4 w-4 animate-spin" />
                            <span className="pl-2">Adding</span>
                          </>
                        ) : (
                          "Add Connection"
                        )}
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </>
  );
}

export default IntegrationsGoogleCalendar;
