/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Domain } from "../../Env";

const initialState = {
  info: {
    chat_id: "e99bb76a-9c7a-407b-afcd-072f189cb2b5",
    name: "Chatbot Title",
    date_created: "2024-03-07",
    description: "Chatbot Description",
    shared: true,
  },
  ai: {
    chat_model: "gpt-3.5-turbo-0125",
    temperature: 0,
    sys_message: "Loading...",
    api_key: "Loading...",
  },
  messages: "[]",
  features: {
    collect_appointments: false,
    collect_leads: true,
    live_enabled: false,
  },
  woocommerce: {
    woocommerce_enabled: false,
    woocommerce_store_url: null,
    woocommerce_consumer_key: null,
    woocommerce_consumer_secret: null,
    features: {
      wc_product_search: false,
      wc_collect_orders: false,
      wc_order_status: false,
      wc_discount_coupons: false,
      discount_settings: {
        discount_type: null,
        amount: null,
        individual_use: false,
        exclude_sale_items: false,
        minimum_amount: null,
      },
    },
  },
  bubble: {
    body_bg: "#ffffff",
    intital_message: "Loading...",
    suggestions: {
      message_suggestions: [],
      bg_color: "#ffffff",
      text_color: "#FFFFFF",
      style: "border",
    },
    header: {
      text_color: "#ffffff",
      bg_color: "#ffffff",
      image: null,
    },
    bubble: {
      bg_color: "#ffffff",
      text_color: "#ffffff",
      image: null,
    },
    ai_message: {
      bg_color: "#ffffff",
      text_color: "#FFFFFF",
    },
    user_message: {
      bg_color: "#ffffff",
      text_color: "#ffffff",
    },
  },
  twilio: {
    twilio_enabled: false,
    twilio_account_sid: null,
    twilio_account_auth_token: null,
    twilio_phone_number: null,
  },
  whatsapp: {
    wa_connected: false,
    wa_phone_number: "",
    wa_phone_number_id: "",
    wa_secret: "",
    wa_access_token: "",
  },
  facebook_messenger: {
    fb_connected: false,
    fb_page_id: "",
    fb_app_id: "",
    fb_app_secret: "",
    fb_verify_token: "",
    fb_page_access_token: "",
  },
  appointments: {
    enabled: false,
    appointment_start_time: "",
    appointment_end_time: "",
    appointment_duration: "",
    time_zone: "",
    days_off: "",
    provider: "",
    google_calendar_id: "",
    google_calendar_connection_id: "",
  },
};

const fetchChatData = createAsyncThunk("dtl/fetchData", async (chat_id) => {
  const authToken = localStorage.getItem("auth_token");
  const response = await fetch(`${Domain}api/get-chat/${chat_id}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });

  if (response.ok) {
    const data = await response.json();
    return data.chat;
  } else {
    return false;
  }
});

export const dtlSlice = createSlice({
  name: "dtl",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatData.pending, (state) => {})
      .addCase(fetchChatData.fulfilled, (state, action) => {
        let parsedMessageSuggestions = [];
        const chat = action.payload;
        // BASIC INFO
        state.chat_id = chat.info.chat_id;
        state.name = chat.info.name;
        state.date_created = chat.info.date_created;
        state.desc = chat.info.description;
        state.shared = chat.info.shared;
        // AI SETTINGS
        state.chat_model = chat.ai.chat_model;
        state.temperature = chat.ai.temperature;
        state.sys_message = chat.ai.sys_message;
        state.api_key = chat.ai.api_key;
        // MESSAGES
        state.messages = chat.messages;

        // COLLECT LEADS
        state.collect_leads = chat.features.collect_leads;

        // LIVE CHAT
        state.live_enabled = chat.features.live_enabled;

        // WOOCOMMERCE
        state.woocommerce = {
          woocommerce_enabled: chat.woocommerce.woocommerce_enabled,
          woocommerce_store_url: chat.woocommerce.woocommerce_store_url,
          woocommerce_consumer_key: chat.woocommerce.woocommerce_consumer_key,
          woocommerce_consumer_secret:
            chat.woocommerce.woocommerce_consumer_secret,
          woocommerce_features: {
            wc_product_search: chat.woocommerce.features.wc_product_search,
            wc_collect_orders: chat.woocommerce.features.wc_collect_orders,
            wc_order_status: chat.woocommerce.features.wc_order_status,
            wc_discount_coupons: chat.woocommerce.features.wc_discount_coupons,
            discount_settings: {
              discount_type:
                chat.woocommerce.features.discount_settings.discount_type,
              discount_amount:
                chat.woocommerce.features.discount_settings.amount,
              individual_use:
                chat.woocommerce.features.discount_settings.individual_use,
              exclude_sale_items:
                chat.woocommerce.features.discount_settings.exclude_sale_items,
              minimum_amount:
                chat.woocommerce.features.discount_settings.minimum_amount,
            },
          },
        };
        // TWILIO
        state.twilio = {
          twilio_enabled: chat.twilio.twilio_enabled,
          twilio_account_sid: chat.twilio.twilio_account_sid,
          twilio_auth_token: chat.twilio.twilio_auth_token,
          twilio_phone_number: chat.twilio.twilio_phone_number,
        };

        // Suggestions
        try {
          const rawMessageSuggestions =
            chat.bubble.suggestions.message_suggestions;
          if (
            rawMessageSuggestions &&
            typeof rawMessageSuggestions === "string" &&
            rawMessageSuggestions.trim() !== ""
          ) {
            parsedMessageSuggestions = JSON.parse(rawMessageSuggestions);
          }
        } catch (error) {
          console.error("Failed to parse message_suggestions:", error);
          // Optionally, set a default value or handle the error as needed
          parsedMessageSuggestions = [];
        }

        // BUBBLE
        state.bubble = {
          body_bg: chat.bubble.body_bg,
          initial_message: chat.bubble.intital_message,
          suggestions: {
            message_suggestions: parsedMessageSuggestions,
            bg_color: chat.bubble.suggestions.bg_color,
            text_color: chat.bubble.suggestions.text_color,
            style: chat.bubble.suggestions.style,
          },
          header: {
            bg_color: chat.bubble.header.bg_color,
            text_color: chat.bubble.header.text_color,
            image: chat.bubble.header.image,
          },
          bubble: {
            bg_color: chat.bubble.bubble.bg_color,
            text_color: chat.bubble.bubble.text_color,
            image: chat.bubble.bubble.image,
          },
          ai_message: {
            bg_color: chat.bubble.ai_message.bg_color,
            text_color: chat.bubble.ai_message.text_color,
          },
          user_message: {
            bg_color: chat.bubble.user_message.bg_color,
            text_color: chat.bubble.user_message.text_color,
          },
        };

        // WHATSAPP
        state.whatsapp = chat.whatsapp;

        // FACEBOOK MESSENGER
        state.facebook_messenger = chat.facebook_messenger;

        // APPOINTMENTS
        state.appointments = chat.appointments;
      })
      .addCase(fetchChatData.rejected, (state, action) => {
        // console.log(action);
      });
  },
});

export { fetchChatData };
export default dtlSlice.reducer;
