/* eslint-disable */
import React, { useState } from "react";
import "./style.css";
import { Domain } from "../Env";
import {
  CheckIcon,
  BuildingOfficeIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import LOGO from "../Components/assets/Vector.svg";
import { cn } from "../lib/utils";
import { buttonVariants } from "../Shadcn/ui/button";

const pricingData = [
  {
    name: "Free",
    icon: <CheckIcon className="h-6 w-6" />,
    price: "$0",
    annually: "$0",
    description: "For testing and hobby projects. No credit card required.",
    features: [
      "Unlimited messages",
      "1 Chatbots",
      "1MB / Chatbot",
      "2 URLs per Chatbot",
      "Own API key",
      "GPT-4 Turbo access",
      "Embed on unlimited websites",
      "Upload multiple files",
      "Customize chatbot widget",
      "Multiple File types supported",
    ],
  },
  {
    name: "Basic",
    icon: <CheckIcon className="h-6 w-6" />,
    price: "$19",
    annually: "$190",
    description: "For most businesses that want to optimize web queries.",
    features: [
      "3 Chatbots",
      "3 MB / Chatbot",
      "10 URLs per Chatbot",
      "Lead Collection",
      "Facebook Messenger Integration",
      "WhatsApp Integration",
    ],
  },
  {
    name: "Premium",
    icon: <SparklesIcon className="h-6 w-6" />,
    price: "$80",
    annually: "$800",
    description: "For medium sized businesses and enterprises.",
    features: [
      "15 Chatbots",
      "15 MB / Chatbot",
      "20 URLs per Chatbot",
      "Appointment Booking",
      "Twilio Integration",
      "Live Chat Handover (300 sessions/month)",
      "Zapier Integration (Coming Soon)",
      "Slack Integration (Coming Soon)",
      "Early access to Future features",
      "Remove Nitrobot.io branding",
    ],
  },
  {
    name: "Enterprise",
    icon: <BuildingOfficeIcon className="h-6 w-6" />,
    price: "$120",
    annually: "$500",
    description:
      "For large businesses catering to a larger audience.  ",
    features: [
      "30 Chatbots",
      "30 MB / Chatbot",
      "50 URLs per Chatbot",
      "Woocommerce Integration",
      "Woocommerce Collect Order",
      "Woocommerce Product Recommendations",
      "Woocommerce Product Search",
      "Woocommerce Discount Coupons generation",
      "Woocommerce Order Tracking",
      "Live Chat Handover (1000 sessions/month)",
      "Priority Support",
    ],
  },
];

export default function Pricing({ isSignedIn }) {
  const authToken = localStorage.getItem("auth_token");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const handlePlanSubmission = async (value) => {
    if (!authToken) {
      window.location.href = "/login";
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(`${Domain}account/api/subscribe/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          plan: value,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const checkout_url = data.checkout_url;
        setLoading(false);
        setMessage(
          "You will be redirected to the checkout page shortly. Please wait...",
        );
        setTimeout(() => {
          window.location.href = checkout_url;
        }, 1000);
      } else {
        setLoading(false);
        setMessage("Uh oh! Something went wrong. Please try again later.");
      }
    } catch (error) {
      setLoading(false);
      setMessage("Uh oh! Something went wrong. Please try again later.");
    }
  };

  return (
    <section className="body-font min-h-screen w-full px-4 pb-4 font-Inter text-black">
      <nav className="flex w-full items-center justify-between px-10">
        <Link to="/">
          <img
            src={LOGO}
            alt="Nitrobot LOGO"
            className="mx-2 my-3 h-14 w-14 rounded-lg bg-black p-2"
          />
        </Link>
        <div className="flex items-center gap-4">
          {authToken ? (
            <Link to="/" className={cn(buttonVariants({ variant: "default" }))}>
              Dashboard
            </Link>
          ) : (
            <Link
              to="/signup"
              className={cn(buttonVariants({ variant: "default" }))}
            >
              Signup
            </Link>
          )}
        </div>
      </nav>

      {message != "" && (
        <div className="flex items-center gap-2 rounded border bg-white p-4 md:mx-auto md:max-w-6xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 flex-shrink-0 text-purple-700"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            aria-hidden="true"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M5 12l5 5l10 -10"></path>
          </svg>
          {message}
        </div>
      )}

      <div className="flex flex-col items-center justify-center md:my-14">
        <span class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-center text-xs font-medium uppercase text-purple-700 ring-1 ring-inset ring-purple-700/10">
          Pricing
        </span>
        <div class="mx-auto max-w-2xl sm:text-center">
          <h2 class="mt-2 text-3xl font-semibold tracking-tight text-gray-900 sm:text-center sm:text-4xl">
            Pricing plans for businesses of all sizes
          </h2>
          <p class="mt-4 text-sm leading-4 text-gray-600 sm:text-center">
            Choose an affordable plan that's packed with the features for
            engaging your audience , building customer loyalty, and driving
            sales.
          </p>
        </div>
      </div>

      <div className="my-5 space-y-3 sm:mt-5 sm:grid sm:grid-cols-4 sm:gap-6 sm:space-y-0 md:mx-auto md:max-w-7xl">
        {pricingData.map((plan, index) => (
          <div
            className={`${
              index === 2 || index === 3
                ? "border-2 border-purple-700"
                : "border-slate-200 border-2"
            } divide-slate-200 divide-y rounded-lg bg-white shadow-sm`}
          >
            <div className="p-6">
              <h2 className="item-center flex gap-x-2 text-xl font-bold leading-6 text-black">
                {plan.icon}
                {plan.name}
              </h2>
              <p className="text-slate-700 mt-2 text-base leading-tight">
                {plan.description}
              </p>
              <p className="mt-8">
                <span className="text-slate-900 text-4xl font-bold tracking-tighter">
                  {plan.price}
                </span>

                <span className="text-slate-500 text-base font-medium">
                  /mo
                </span>
              </p>
              <button
                className="bg-slate-900 mt-8 block w-full rounded-md border-2 border-chat-purple py-2 text-center text-sm font-semibold text-black hover:bg-chat-purple hover:text-white disabled:cursor-not-allowed disabled:opacity-50"
                onClick={(e) => handlePlanSubmission(index + 1)}
                disabled={loading}
              >
                {loading && (
                  <div
                    className="mr-2 inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                  >
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                      Loading...
                    </span>
                  </div>
                )}
                Choose Plan
              </button>
            </div>
            <div className="px-6 pb-8 pt-6">
              <h3 className="text-slate-900 text-sm font-bold capitalize tracking-wide">
              {index > 0 ? `Everything in ${pricingData[index-1].name} &` : "What's included"}  
              </h3>
              <ul role="list" className="mt-4 space-y-3">
                {plan.features.map((feature) => (
                  <li className="flex space-x-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 flex-shrink-0 text-purple-700"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      aria-hidden="true"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M5 12l5 5l10 -10"></path>
                    </svg>
                    <span className="text-slate-700 text-base">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
