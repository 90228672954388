/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./style.css";
import { Calendar } from "../Shadcn/ui/calendar";
import { apiDomain } from "../Env";
import { SingleAppointmentCard } from "../Components/Chatbot/SingleAppointmentCard";
import { addDays, format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { Button } from "../Shadcn/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "../Shadcn/ui/popover";
import { Separator } from "../Shadcn/ui/separator";

export default function Calenders() {
  const [date, setDate] = useState({
    from: new Date(),
    to: addDays(new Date(), 7), // Add 7 days to the current date
  });
  const [appointments, setAppointments] = useState([]);
  const authToken = localStorage.getItem("auth_token");

  const fetchAppointments = async () => {
    try {
      const response = await fetch(`${apiDomain}api/get-appointments/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        method: "POST",
        body: JSON.stringify({
          from: date.from,
          to: date.to,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setAppointments(data.appointments);
      } else {
        setAppointments([]);
      }
    } catch (error) {
      setAppointments([]);
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  return (
    <div className="flex w-full items-start justify-center px-4 py-4 sm:px-10 sm:py-6 lg:flex-none lg:px-8 xl:px-8">
      <div className="flex flex-col w-full items-start justify-center gap-x-4 md:p-3">
        {" "}
        <div className="flex w-full  flex-col items-center justify-center gap-y-3 md:flex-row md:justify-between md:gap-y-0">
          <div className="flex max-w-2xl flex-col items-start gap-y-2 text-black sm:text-left">
            <h4 className="text-3xl font-bold tracking-tight text-black sm:text-4xl ">
              Upcoming Appointments
            </h4>
            <p className="font-small lg:text-md tracking-tight text-gray-700 sm:text-sm">
              Here are the list upcoming appointments
            </p>
          </div>
          <div className="flex w-full flex-wrap items-center justify-center gap-x-4 gap-y-4 md:w-fit md:gap-y-0">
            <div className="grid gap-2 ">
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    id="date"
                    variant="outline"
                    className={`
              "w-[300px] text-black" justify-start text-left font-normal
            `}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4 text-black" />
                    {date?.from ? (
                      date.to ? (
                        <>
                          <p className="text-black">
                            {format(date.from, "LLL dd, y")} -{" "}
                            {format(date.to, "LLL dd, y")}
                          </p>
                        </>
                      ) : (
                        format(date.from, "LLL dd, y")
                      )
                    ) : (
                      <span className="text-black">Pick a date</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    initialFocus
                    mode="range"
                    defaultMonth={date?.from}
                    selected={date}
                    onSelect={setDate}
                    numberOfMonths={2}
                  />
                </PopoverContent>
              </Popover>
            </div>
            <Button onClick={fetchAppointments} variant="default">
              Refresh
            </Button>
          </div>
        </div>{" "}
        <Separator orientation="horizontal" className='my-3 md:my-6' />
        <div className="mt-6 grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4">
          {appointments.length > 0 ? (
            appointments.map((appointment) => (
              <SingleAppointmentCard appointment={appointment} />
            ))
          ) : (
            <div className="col-span-3 flex h-20 w-full items-center justify-center">
              <p className="text-black">
                Woohoo! You have no appointments. Enjoy your free time!
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
