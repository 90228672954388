/* eslint-disable */
import * as React from "react";
import { Inbox, MessageSquare } from "lucide-react";
import LOGO from "../Components/assets/Vector.svg";
import { Nav } from "../Components/UI/nav";
import HeaderDropdown from "../Components/UI/HeaderDropdown";
import { cn } from "../lib/utils";
import { Separator } from "../Shadcn/ui/separator";
import { TooltipProvider } from "../Shadcn/ui/tooltip";
import { Button } from "../Shadcn/ui/button";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../Shadcn/ui/sheet";
import { Link, Outlet } from "react-router-dom";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../Shadcn/ui/resizable";
import {
  ChatBubbleLeftRightIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/solid";
import { ScrollArea } from "../Shadcn/ui/scroll-area";
import { AlignLeft, Unplug, CalendarDays, DollarSign } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";

const Links = [
  {
    name: "Chatbots",
    link: "/chatbots",
    icon: <ChatBubbleLeftRightIcon className="h-4 w-4 sm:h-6 sm:w-6" />,
  },
  {
    name: "Pricing",
    link: "/pricing",
    icon: <DollarSign className="h-4 w-4 sm:h-6 sm:w-6" />,
  },

  {
    name: "Connections",
    link: "/connections",
    icon: <Unplug className="h-4 w-4 sm:h-6 sm:w-6" />,
  },
  {
    name: "Calenders",
    link: "/calenders",
    icon: <CalendarDays className="h-4 w-4 sm:h-6 sm:w-6" />,
  },
  {
    name: "Live",
    link: "/live",
    icon: <MessageSquare className="h-4 w-4 sm:h-6 sm:w-6" />,
  },
  {
    name: "Settings",
    link: "/settings",
    icon: <Cog6ToothIcon className="h-4 w-4 sm:h-6 sm:w-6" />,
  },
];

export default function Dashboard({
  defaultLayout = [265, 440, 655],
  defaultCollapsed = false,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedLink, setSelectedLink] = useState("/chatbots");
  const Coll = localStorage.getItem("dashboardCollapsed");
  const [isCollapsed, setIsCollapsed] = useState(
    Coll === "true" ? true : false,
  );

  useEffect(() => {
    localStorage.setItem("dashboardCollapsed", isCollapsed.toString());
  }, [isCollapsed]);

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/chatbots");
    }
  }, [location.pathname]);

  const handleSelectLink = (link) => {
    setSelectedLink(link);
    navigate(link);
  };
  return (
    <section className="h-screen w-screen">
      <TooltipProvider delayDuration={0}>
        <ResizablePanelGroup
          direction="horizontal"
          onLayout={(sizes) => {
            document.cookie = `react-resizable-panels:layout=${JSON.stringify(
              sizes,
            )}`;
          }}
          className="h-full max-h-screen items-stretch"
        >
          <ResizablePanel
            defaultSize={isCollapsed ? 4 : defaultLayout[0]}
            collapsedSize={4}
            collapsible={true}
            minSize={15}
            maxSize={20}
            onCollapse={() => {
              setIsCollapsed(() => true);
            }}
            onExpand={() => {
              setIsCollapsed(() => false);
            }}
            className={cn(
              isCollapsed &&
                "hidden min-w-[50px] transition-all duration-300  ease-in-out md:block ",
              !isCollapsed &&
                "hidden min-w-[265px] transition-all duration-300  ease-in-out md:block ",
            )}
          >
            <div
              className={cn(
                "flex h-[56px] items-center justify-center bg-[#0f172a]",
                isCollapsed ? "h-[56px]" : "px-2",
              )}
            >
              <img
                src={LOGO}
                alt="Nitrobot LOGO"
                className="mx-2 my-3 h-8 w-8 "
              />
            </div>
            <Separator />
            <Nav
              isCollapsed={isCollapsed}
              selectedLink={selectedLink}
              onSelectLink={handleSelectLink}
              links={[
                {
                  title: "Chatbots",
                  label: "",
                  icon: Inbox,
                  variant: "default",
                  to: "/chatbots",
                },
                {
                  title: "Pricing",
                  label: "",
                  icon: DollarSign,
                  variant: "ghost",
                  to: "/pricing",
                },

                {
                  title: "Connections",
                  label: "",
                  icon: Unplug,
                  variant: "ghost",
                  to: "/connections",
                },
                {
                  title: "Calenders",
                  label: "",
                  icon: CalendarDays,
                  variant: "ghost",
                  to: "/calenders",
                },
                // {
                //   title: "Live",
                //   label: "",
                //   icon: MessageSquare,
                //   variant: "ghost",
                //   to: "/live",
                // },
                {
                  title: "Settings",
                  label: "",
                  icon: Cog6ToothIcon,
                  variant: "ghost",
                  to: "/settings",
                },
              ]}
            />
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel
            defaultSize={isCollapsed ? null : defaultLayout[1]}
            minSize={30}
          >
            <section className="h-screen">
              <div className="flex h-[8vh] min-h-[56px] items-center justify-between px-4 py-2  md:justify-end">
                <div className="md:hidden">
                  <SheetSide />
                </div>
                <HeaderDropdown />
              </div>
              <Separator />
              <ScrollArea className="min-h-dash-height h-[92vh] w-full">
                <Outlet />
              </ScrollArea>
            </section>
          </ResizablePanel>
        </ResizablePanelGroup>
      </TooltipProvider>
    </section>
  );
}

export function SheetSide() {
  return (
    <Sheet className="dark">
      <SheetTrigger className="" asChild>
        <Button size="icon">
          <AlignLeft className="h-6 w-6" />
        </Button>
      </SheetTrigger>
      <SheetContent className="dark  w-[300px] sm:w-[400px]" side="left">
        <SheetHeader>
          <SheetTitle>
            <img src={LOGO} alt="Nitrobot LOGO" className="mb-4 h-10 w-10" />
          </SheetTitle>
        </SheetHeader>
        <Separator />
        <div className="grid gap-2 py-4">
          {Links.map((link) => (
            <div className="grid grid-cols-4 items-center gap-4">
              <SheetClose asChild>
                <Button
                  asChild
                  variant="ghost_2"
                  className="dark col-span-4 flex justify-start gap-x-4"
                >
                  <Link to={link.link}>
                    {link.icon}
                    {link.name}
                  </Link>
                </Button>
              </SheetClose>
            </div>
          ))}
        </div>
      </SheetContent>
    </Sheet>
  );
}
