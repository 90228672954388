/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ChatBubbleLeftRightIcon,
  PlusIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/solid";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { Home, Domain } from "../../Env";
import { useDispatch, useSelector } from "react-redux";
import { fetchChats } from "../../Redux/Chat/chatSlice";
import { useToast } from "../../Shadcn/ui/use-toast";
import { Skeleton } from "../../Shadcn/ui/skeleton";
import { Separator } from "../../Shadcn/ui/separator";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogClose,
} from "../../Shadcn/ui/dialog";
import { Button } from "../../Shadcn/ui/button";

const Chatbots = () => {
  const [chats, setChats] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const chatbots = useSelector((state) => state.chats.chats);
  var count = useSelector((state) => state.chats.count);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();
  const [open, setIsDialogOpen] = useState(false);
  const [chatId, setChatId] = useState("");

  useEffect(() => {
    if (count === 0) {
      dispatch(fetchChats());
      count++;
    }
    sessionStorage.removeItem("chatHistory");
  }, []);

  useEffect(() => {
    if (chatbots.length >= 0 || count > 0) {
      setChats(chatbots);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [chatbots]);

  const handleDelete = async () => {
    setChats(chats.filter((chat) => chat.chat_id !== chatId));
    const authToken = localStorage.getItem("auth_token");
    try {
      const response = await fetch(`${Domain}api/delete-chat/${chatId}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
    //  // console.log(response);
      if (response.ok) {
        toast({
          title: "Success!",
          description: "Chatbot deleted successfully.",
        });
        setIsDialogOpen(false);
      }
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "Chatbot could not be deleted. Please try again.",
      });
    }
  };

  return (
    <>
      <div className="flex w-full items-start justify-center px-4 py-4 sm:px-10 sm:py-6 lg:flex-none lg:px-8 xl:px-8">
        <div className="flex w-full flex-col items-start justify-center gap-x-4 md:p-3">
          {" "}
          <div className="flex w-full  flex-col items-center justify-start gap-y-3 md:flex-row md:justify-between md:gap-y-0">
            <div className="flex max-w-2xl flex-col items-start gap-y-2 text-black sm:text-left">
              <h4 className="text-3xl font-bold tracking-tight text-black sm:text-4xl ">
                Chatbots
              </h4>
              <p className="font-small lg:text-md tracking-tight text-gray-700 sm:text-sm">
                Create new Nitrobots or leverage your existing ones
              </p>
            </div>
          </div>{" "}
          <Separator
            orientation="horizontal"
            className="my-2 md:mb-2 md:mt-6"
          />
          <div className="mt-6 grid w-full grid-cols-4 ">
            <div className="col-span-4 grid w-full grid-cols-1 gap-5 lg:col-span-4 lg:grid-cols-4 ">
              {loading ? (
                <div className="flex h-[220px] w-full flex-col space-y-3 rounded-xl border p-4">
                  <Skeleton className="h-[140px] w-full  rounded-xl" />
                  <div className="space-y-2">
                    <Skeleton className="h-4  w-full" />
                    <Skeleton className="h-4 w-[80%]" />
                  </div>
                </div>
              ) : (
                <>
                  <Link to="/create_chat">
                    <div class="hover:smooth-hover group flex h-[220px] cursor-pointer flex-col items-center justify-center space-y-2 rounded-xl border border-chat-purp-sc bg-black px-4 py-16">
                      <div class="group-hover:smooth-hover flex h-20 w-20 items-center justify-center rounded-full bg-black text-white group-hover:text-white">
                        <PlusIcon className="h-10 w-10" />
                      </div>
                      <div class="group-hover:smooth-hover text-center text-white group-hover:text-white">
                        Create Chatbot
                      </div>
                    </div>
                  </Link>
                  {chats.map((chat) => (
                    <div
                      key={chat.id}
                      onClick={(e) => {
                        navigate(`/chats/${chat.chat_id}/`);
                      }}
                      className={`border-stone-700 hover:smooth-hover hover:smooth-hover group relative flex h-[220px] cursor-pointer flex-col items-center justify-center space-y-1 rounded-xl border bg-white px-4 duration-100 ease-in-out hover:-translate-y-2 hover:shadow-md `}
                    >
                      <div className="mb-4 inline-flex h-20 w-20 items-center justify-center rounded-full bg-black text-white">
                        <ChatBubbleLeftRightIcon className="h-12 w-12" />
                      </div>
                      <h4 className="text-center text-2xl font-semibold capitalize leading-none tracking-tight text-black">
                        {chat.name}
                      </h4>
                      <p className="max-w-full truncate text-sm text-gray-600">
                        {chat.desc}
                      </p>
                      <button
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="ellipsis-icon smooth-hover group absolute right-4  top-4 z-10 inline-flex h-10 w-10   cursor-pointer items-center justify-center rounded-md p-4 text-xs text-white/50"
                      >
                        <Menu placement="bottom-start">
                          <MenuHandler>
                            <button type="button " className="">
                              <EllipsisVerticalIcon className="h-8 w-10 text-gray-600 focus:outline-none group-hover:text-black" />
                            </button>
                          </MenuHandler>
                          <MenuList>
                            <MenuItem
                              onClick={(e) => {
                                setChatId(chat.chat_id);
                                setIsDialogOpen(true);
                              }}
                              className="text-red-500 hover:bg-red-500/10 hover:text-red-500 focus:bg-red-500/10 focus:text-red-500"
                            >
                              Delete
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </button>
                    </div>
                  ))}
                </>
              )}{" "}
            </div>
          </div>
        </div>
      </div>

      <>
        <Dialog open={open} onOpenChange={setIsDialogOpen}>
          <DialogContent className="h-[200px] p-6 sm:max-w-[450px]">
            <DialogHeader>
              <DialogTitle className="text-red-500">Delete Chatbot</DialogTitle>
              <DialogDescription>
                <h2 className="text-md mb-4 mt-2 text-black">
                  Are you sure you want to delete this chatbot? This action
                  cannot be undone.
                </h2>
              </DialogDescription>
            </DialogHeader>

            <div className="flex w-full justify-end gap-2">
              <DialogClose asChild>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => setIsDialogOpen(false)}
                >
                  No, Take me Back
                </Button>
              </DialogClose>
              <Button
                type="button"
                className="bg-red-600 hover:bg-red-900"
                onClick={handleDelete}
                disabled={loading}
              >
                {loading ? "Deleting..." : "Yes, Delete"}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </>
    </>
  );
};

export default Chatbots;
