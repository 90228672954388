/* eslint-disable */
import { useState, useEffect, React } from "react";
import { SingleAppointmentCard } from "./SingleAppointmentCard";
import { Separator } from "../../Shadcn/ui/separator";
import UpgradeDialog from "./UpgradeDialog";

export function ChatAppointments() {
  const [paywallopen, setPaywallOpen] = useState(false);
  const appointments = [
    {
      chat_id: "35854c34-f6bf-47b1-9aea-0ccb06f770f5",
      appointment_id: "8933e72a-6c7b-4c43-8cc6-1120e1edb10a",
      appointment_start_time: "2024-03-21T21:27:08Z",
      appointment_end_time: "2024-03-21T18:00:00Z",
      appointment_summary: "Discuss next Billion Dollar idea",
      email: "Johndoe@mail.com",
      phone_number: "+13471234567",
      name: "John Doe",
      status: "Pending",
      calendar_provider: "nitrobot",
      calendar_provider_event_id: null,
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setPaywallOpen(true);
    }, 1000);
  }, []);

  return (
    <div className="flex h-[92vh] min-h-[92vh] w-full items-start justify-center px-4 py-4 sm:px-10 sm:py-6 lg:flex-none lg:px-8 xl:px-8">
      <div className="flex w-full flex-col items-start justify-center gap-x-4 md:p-3">
        {" "}
        <div className="flex w-full  flex-col items-center justify-start gap-y-3 md:flex-row md:justify-start md:gap-y-0">
          <div className="flex max-w-3xl flex-col items-start gap-y-2 text-black sm:text-left">
            <h4 className="flex w-full items-center gap-x-4 text-xl font-bold tracking-tight text-black sm:text-4xl">
              Booked Appointments
              <span class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-center text-xs font-medium uppercase text-purple-700 ring-1 ring-inset ring-purple-700/10">
                Upgrade Plan
              </span>
            </h4>
            <p className="font-small lg:text-md tracking-tight text-gray-700 sm:text-sm">
              Here's a demo of a list of all the appointments booked by the
              chatbot. This feature is not included in your current plan. Please
              upgrade your account to access this feature.
            </p>
          </div>
        </div>{" "}
        <Separator orientation="horizontal" className="my-3 md:my-6" />
        <div className="mt-8 grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {appointments.length > 0 ? (
            appointments.map((appointment) => (
              <SingleAppointmentCard appointment={appointment} />
            ))
          ) : (
            <p className="w-full text-center text-black">
              No Appointments available.
            </p>
          )}
        </div>
      </div>

      <UpgradeDialog open={paywallopen} setOpen={setPaywallOpen} />
    </div>
  );
}
