/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../Shadcn/ui/dialog";
import { Label } from "../../Shadcn/ui/label";
import { Input } from "../../Shadcn/ui/input";
import { Button } from "../../Shadcn/ui/button";
import { useToast } from "../../Shadcn/ui/use-toast";
import { apiDomain, Domain, Home } from "../../Env";
import { getOpenAIConnections } from "../Connections/open-ai-connections";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../../Redux/User/userSlice";
import { Separator } from "../../Shadcn/ui/separator";
import { Pencil, Trash, Loader2 } from "lucide-react";

function IntegrationsOpenAiConnections() {
  const [loading, setLoading] = useState(false);
  const [connections, setConnections] = useState([]);
  const [title, setTitle] = useState("");
  const [apiKey, setApiKey] = useState("");
  const { toast } = useToast();

  const testOpenAIKey = async (apiKey) => {
    const data = {
      model: "gpt-3.5-turbo-0125",
      messages: [{ role: "user", content: "Say this is a test!" }],
      temperature: 0.7,
    };

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        },
      );

      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      toast({
        variant: "destructive",
        title: "OpenAI Key is invalid.",
        description: "Please enter a valid OpenAI key.",
      });

      return false;
    }
  };

  const handleAddConnection = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("openai_label", title);
    formData.append("openai_key", apiKey);
    const validated = await testOpenAIKey(apiKey);

    if (!validated) {
      setLoading(false);
      return;
    }

    axios
      .post(`${apiDomain}api/create_openai_key/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((response) => {
        setTitle("");
        setApiKey("");
        toast({
          title: "Success!",
          description:
            response.data.message ||
            "OpenAI API key was added successfully. You can now use it in chatbots.",
        });

        setConnections([...connections, response.data.connection]);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.response.data);
        toast({
          variant: "destructive",
          title: "An error occurred",
          description:
            error.response.data.message ||
            "There was an error adding the connection. Please try again.",
        });
        setLoading(false);
      });
  };

  return (
    <>
      <div className="flex h-[200px] w-full flex-col justify-between rounded-xl border bg-white p-6 pt-8 hover:shadow-lg">
        <div>
          <div className={`flex items-center justify-start`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
              width="800px"
              height="800px"
              viewBox="0 0 24 24"
              role="img"
              className="h-10 w-10"
            >
              <title>OpenAI icon</title>
              <path d="M22.2819 9.8211a5.9847 5.9847 0 0 0-.5157-4.9108 6.0462 6.0462 0 0 0-6.5098-2.9A6.0651 6.0651 0 0 0 4.9807 4.1818a5.9847 5.9847 0 0 0-3.9977 2.9 6.0462 6.0462 0 0 0 .7427 7.0966 5.98 5.98 0 0 0 .511 4.9107 6.051 6.051 0 0 0 6.5146 2.9001A5.9847 5.9847 0 0 0 13.2599 24a6.0557 6.0557 0 0 0 5.7718-4.2058 5.9894 5.9894 0 0 0 3.9977-2.9001 6.0557 6.0557 0 0 0-.7475-7.0729zm-9.022 12.6081a4.4755 4.4755 0 0 1-2.8764-1.0408l.1419-.0804 4.7783-2.7582a.7948.7948 0 0 0 .3927-.6813v-6.7369l2.02 1.1686a.071.071 0 0 1 .038.052v5.5826a4.504 4.504 0 0 1-4.4945 4.4944zm-9.6607-4.1254a4.4708 4.4708 0 0 1-.5346-3.0137l.142.0852 4.783 2.7582a.7712.7712 0 0 0 .7806 0l5.8428-3.3685v2.3324a.0804.0804 0 0 1-.0332.0615L9.74 19.9502a4.4992 4.4992 0 0 1-6.1408-1.6464zM2.3408 7.8956a4.485 4.485 0 0 1 2.3655-1.9728V11.6a.7664.7664 0 0 0 .3879.6765l5.8144 3.3543-2.0201 1.1685a.0757.0757 0 0 1-.071 0l-4.8303-2.7865A4.504 4.504 0 0 1 2.3408 7.872zm16.5963 3.8558L13.1038 8.364 15.1192 7.2a.0757.0757 0 0 1 .071 0l4.8303 2.7913a4.4944 4.4944 0 0 1-.6765 8.1042v-5.6772a.79.79 0 0 0-.407-.667zm2.0107-3.0231l-.142-.0852-4.7735-2.7818a.7759.7759 0 0 0-.7854 0L9.409 9.2297V6.8974a.0662.0662 0 0 1 .0284-.0615l4.8303-2.7866a4.4992 4.4992 0 0 1 6.6802 4.66zM8.3065 12.863l-2.02-1.1638a.0804.0804 0 0 1-.038-.0567V6.0742a4.4992 4.4992 0 0 1 7.3757-3.4537l-.142.0805L8.704 5.459a.7948.7948 0 0 0-.3927.6813zm1.0976-2.3654l2.602-1.4998 2.6069 1.4998v2.9994l-2.5974 1.4997-2.6067-1.4997Z" />
            </svg>
          </div>
          <h6 className="mt-4 font-normal tracking-tight text-black sm:text-sm lg:text-xl">
            Open AI
          </h6>
        </div>
        <div>
          <div className="flex w-full gap-x-2">
            <OpenAI />
            <Dialog>
              <DialogTrigger asChild>
                <Button className="w-full">Add</Button>
              </DialogTrigger>
              <DialogContent className="min-w-[30vw] text-black">
                <DialogHeader>
                  <DialogTitle className="text-black">
                    Add Open AI Connection
                  </DialogTitle>
                </DialogHeader>
                <form onSubmit={handleAddConnection}>
                  <div className="flex flex-col gap-4">
                    <div>
                      <Label htmlFor="title">Title</Label>
                      <Input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        id="title"
                      />
                    </div>
                    <div>
                      <Label htmlFor="api-key">API Key</Label>
                      <Input
                        type="text"
                        value={apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                        id="api-key"
                      />
                    </div>
                    <Button type="submit" disabled={loading}>
                      {" "}
                      {loading ? (
                        <Loader2 className="h-4 w-4 animate-spin" />
                      ) : (
                        "Add Connection"
                      )}{" "}
                    </Button>
                  </div>
                </form>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    </>
  );
}

export default IntegrationsOpenAiConnections;

function OpenAI() {
  const token = localStorage.getItem("auth_token");
  const [keys, setKeys] = useState([]);
  const dispatch = useDispatch();
  const username = useSelector((state) => state.user.user[0]?.username);
  // Subscribe to webhooks messages fro the pages
  const { toast } = useToast();
  const [label, setLabel] = useState("");
  const [api_key, setApi_key] = useState("");
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const makeApiRequest = useCallback(
    async (url, method, data = null) => {
      try {
        const response = await axios({
          method,
          url: `${apiDomain}${url}`,
          headers: { Authorization: `Bearer ${token}` },
          data,
        });
        return response.data;
      } catch (error) {
        console.error(`Error during ${method} request to ${url}:`, error);
        throw error;
      }
    },
    [token],
  );

  const testOpenAIKey = async (apiKey) => {
    const data = {
      model: "gpt-3.5-turbo-0125",
      messages: [{ role: "user", content: "Say this is a test!" }],
      temperature: 0.7,
    };

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        },
      );

      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      toast({
        variant: "destructive",
        title: "OpenAI Key is invalid.",
        description: "Please enter a valid OpenAI key.",
      });

      return false;
    }
  };

  const fetchKeys = useCallback(async () => {
    try {
      const data = await makeApiRequest("api/get_openai_key/", "get");
      // console.log(data.keys);
      setKeys(data.keys);
    } catch (error) {
      // Handle error appropriately
    }
  }, [makeApiRequest]);

  useEffect(() => {
    if (!username) {
      dispatch(fetchUserData(token));
    }
  }, [dispatch, token, username]);

  const handleEdit = (number) => {
    setLabel(number.label);
    setApi_key(number.key);
    setId(number.id);
    setOpen(true);
  };

  const handleDelete = (numberToDelete) => {
    const formData = new FormData();
    formData.append("openai_id", numberToDelete.id);

    axios
      .post(`${apiDomain}api/delete_openai_key/`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        // Remove the deleted number from the numbers state
        setOpen(false);
        setKeys(keys.filter((number) => number.id !== numberToDelete.id));
        toast({
          title: "Deleted!",
          description: "OpenAI API Key deleted successfully.",
        });
      })
      .catch((error) => {
        console.error(`Error during deletion:`, error);
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: `Error during deletion: ${error.message}`,
        });
      });
  };

  const handleUpdate = async () => {
    const formData = new FormData();
    if (id && api_key && label) {
      setLoading(true);

      formData.append("openai_id", id);
      formData.append("openai_label", label);
      formData.append("openai_key", api_key);

      const validated = await testOpenAIKey(api_key);

      if (!validated) {
        setLoading(false);
        return;
      }

      axios
        .post(`${apiDomain}api/update_openai_key/`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          // After a successful update, fetch the numbers again
          fetchKeys();
          setOpen(false);
          setLoading(false);
          toast({
            title: "Success!",
            description: "OpenAI API key updated successfully.",
          });
        })
        .catch((error) => {
          setLoading(false);
          toast({
            variant: "destructive",
            title: "Uh oh! Something went wrong.",
            description: "Error updating. Please try again.",
          });
        });
    } else {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "Please fill all the fields.",
      });
    }
  };

  return (
    <>
      <Dialog>
        <DialogTrigger asChild>
          <Button
            className="w-full"
            onClick={() => {
              fetchKeys();
            }}
          >
            View
          </Button>
        </DialogTrigger>
        <DialogContent className={` ${open ? "min-w-[80%]" : "min-w-[50%]"}`}>
          <DialogHeader>
            <DialogTitle className="text-black">OpenAI API keys</DialogTitle>
            <DialogDescription>
              You can view and edit your OpenAI API keys here.
            </DialogDescription>
          </DialogHeader>

          <div className="flex w-full gap-x-4">
            {keys.length > 0 ? (
              <div className="grid w-full grid-cols-1 grid-rows-3 gap-4 pt-4">
                {keys.map((key) => (
                  <div
                    key={key.label}
                    className="grid w-full grid-cols-8 items-center gap-4 rounded-md border px-4 py-3 "
                  >
                    <div className="col-span-6 flex items-center justify-between gap-x-4 py-3 text-sm font-medium leading-none">
                      <span className="text-black">{key.label}</span>
                    </div>

                    <div className="col-span-2 flex items-center justify-evenly">
                      <Button
                        size="icon"
                        variant="outline"
                        onClick={() => {
                          handleEdit(key);
                        }}
                      >
                        <Pencil className="h-4 w-4 text-black" />
                      </Button>
                      <Button
                        size="icon"
                        variant="outline"
                        onClick={() => {
                          handleDelete(key);
                        }}
                      >
                        <Trash className="h-4 w-4 text-red-500" />
                      </Button>
                    </div>
                  </div>
                ))}{" "}
              </div>
            ) : (
              <p className="font-small lg:text-md tracking-tight text-gray-600 sm:text-sm">
                No API keys found
              </p>
            )}

            {keys.length > 0 && open ? (
              <>
                <Separator orientation="vertical" />
                <div className="grid w-full grid-cols-1 grid-rows-3 gap-4">
                  <div className="grid flex-1 gap-2">
                    <Label htmlFor="PH_ID" className="text-black">
                      Label
                    </Label>
                    <Input
                      id="PH_ID"
                      value={label}
                      onChange={(event) => {
                        setLabel(event.target.value);
                      }}
                    />
                  </div>
                  <div className="grid flex-1 gap-2">
                    <Label htmlFor="WP_BS_ID" className="text-black">
                      API key
                    </Label>
                    <Input
                      id="WP_BS_ID"
                      value={api_key}
                      onChange={(event) => {
                        setApi_key(event.target.value);
                      }}
                    />
                  </div>

                  <div className="flex w-full items-center justify-end gap-x-4 ">
                    <Button
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </Button>

                    <Button onClick={() => handleUpdate()} disabled={loading}>
                      {loading ? (
                        <Loader2 className="h-4 w-4 animate-spin" />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
