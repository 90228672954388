/* eslint-disable */
import React, { useState, useRef, useEffect, useCallback } from "react";
import { CalendarDaysIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "../../Shadcn/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../Shadcn/ui/card";
import { Input } from "../../Shadcn/ui/input";
import { Label } from "../../Shadcn/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../Shadcn/ui/tabs";
import { Textarea } from "../../Shadcn/ui/textarea";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../Shadcn/ui/select";
import { ScrollArea } from "../../Shadcn/ui/scroll-area";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../Shadcn/ui/hover-card";
import { Slider } from "../../Shadcn/ui/slider";
import { fetchUserData } from "../../Redux/User/userSlice";
import { useToast } from "../../Shadcn/ui/use-toast";
import { Progress } from "../../Shadcn/ui/progress";
import { Link, useNavigate } from "react-router-dom";
import "../style.css";
import { useSelector, useDispatch } from "react-redux";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { TbFidgetSpinner } from "react-icons/tb";
import { Domain, apiDomain } from "../../Env";
import axios from "axios";
import "../../Pages/style.css";
import { BsFilePdfFill } from "react-icons/bs";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../../lib/utils";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../Shadcn/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../Shadcn/ui/popover";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../Shadcn/ui/dialog";
import { Loader2 } from "lucide-react";

function ComboboxDemo({ setOpenAiKey, key_value }) {
  const token = localStorage.getItem("auth_token");
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(key_value || "");
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [apiKey, setApiKey] = useState("");
  const { toast } = useToast();
  const [keys, setKeys] = useState([]);

  const makeApiRequest = useCallback(
    async (url, method, data = null) => {
      try {
        const response = await axios({
          method,
          url: `${apiDomain}${url}`,
          headers: { Authorization: `Bearer ${token}` },
          data,
        });
        return response.data;
      } catch (error) {
        console.error(`Error during ${method} request to ${url}:`, error);
        throw error;
      }
    },
    [token],
  );

  const fetchKeys = useCallback(async () => {
    try {
      const data = await makeApiRequest("api/get_openai_key/", "get");
      // console.log(data.keys);
      setKeys(data.keys);
    } catch (error) {
      // Handle error appropriately
    }
  }, [makeApiRequest]);

  useEffect(() => {
    fetchKeys();
  }, []);

  useEffect(() => {
    setOpenAiKey(value);
  }, [value, setOpenAiKey]);

  const testOpenAIKey = async (apiKey) => {
    const data = {
      model: "gpt-3.5-turbo-0125",
      messages: [{ role: "user", content: "Say this is a test!" }],
      temperature: 0.7,
    };

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        },
      );

      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      toast({
        variant: "destructive",
        title: "OpenAI Key is invalid.",
        description: "Please enter a valid OpenAI key.",
      });

      return false;
    }
  };

  const handleAddConnection = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("openai_label", title);
    formData.append("openai_key", apiKey);
    const validated = await testOpenAIKey(apiKey);

    if (!validated) {
      setLoading(false);
      return;
    }

    axios
      .post(`${apiDomain}api/create_openai_key/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((response) => {
        setTitle("");
        setApiKey("");
        toast({
          title: "Success!",
          description:
            response.data.message ||
            "OpenAI API key was added successfully. You can now use it in chatbots.",
        });
        fetchKeys();
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.response.data);
        toast({
          variant: "destructive",
          title: "An error occurred",
          description:
            error.response.data.message ||
            "There was an error adding the connection. Please try again.",
        });
        setLoading(false);
      });
  };

  const isSelected = (frameworkKey, selectedValue) => {
    return frameworkKey === selectedValue;
  };

  return (
    <Popover className="w-full" open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
        >
          {value
            ? keys.find((framework) => framework.key === value)?.label
            : "Select API key..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[26vw] p-0">
        <Command loop>
          <CommandInput placeholder="Search API keys..." />
          <CommandEmpty>
            {" "}
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="ghost" className="w-full">
                  Add
                </Button>
              </DialogTrigger>
              <DialogContent className="min-w-[30vw] text-black">
                <DialogHeader>
                  <DialogTitle className="text-black">
                    Add Open AI Connection
                  </DialogTitle>
                </DialogHeader>
                <form onSubmit={handleAddConnection}>
                  <div className="flex flex-col gap-4">
                    <div>
                      <Label htmlFor="title">Title</Label>
                      <Input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        id="title"
                      />
                    </div>
                    <div>
                      <Label htmlFor="api-key">API Key</Label>
                      <Input
                        type="text"
                        value={apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                        id="api-key"
                      />
                    </div>
                    <Button type="submit" disabled={loading}>
                      {" "}
                      {loading ? (
                        <Loader2 className="h-4 w-4 animate-spin" />
                      ) : (
                        "Add Connection"
                      )}{" "}
                    </Button>
                  </div>
                </form>
              </DialogContent>
            </Dialog>
          </CommandEmpty>
          {/* <HoverCardTrigger /> */}
          <CommandGroup>
            {keys.map((framework) => (
              <CommandItem
                key={framework.id}
                value={framework.key}
                onSelect={(currentValue) => {
                  setValue(framework.key === value ? "" : framework.key);
                  setOpen(false);
                }}
              >
                <Check
                  className={`mr-2 h-4 w-4 ${
                    isSelected(framework.key, value)
                      ? "opacity-100"
                      : "opacity-0"
                  }`}
                />
                {framework.label}
              </CommandItem>
            ))}
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="ghost" className="w-full">
                  Add
                </Button>
              </DialogTrigger>
              <DialogContent className="min-w-[30vw] text-black">
                <DialogHeader>
                  <DialogTitle className="text-black">
                    Add Open AI Connection
                  </DialogTitle>
                </DialogHeader>
                <form onSubmit={handleAddConnection}>
                  <div className="flex flex-col gap-4">
                    <div>
                      <Label htmlFor="title">Title</Label>
                      <Input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        id="title"
                      />
                    </div>
                    <div>
                      <Label htmlFor="api-key">API Key</Label>
                      <Input
                        type="text"
                        value={apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                        id="api-key"
                      />
                    </div>
                    <Button type="submit" disabled={loading}>
                      {" "}
                      {loading ? (
                        <Loader2 className="h-4 w-4 animate-spin" />
                      ) : (
                        "Add Connection"
                      )}{" "}
                    </Button>
                  </div>
                </form>
              </DialogContent>
            </Dialog>
          </CommandGroup>
        </Command>
        {/* </HoverCard> */}
      </PopoverContent>
    </Popover>
  );
}

export default function CreateFunction() {
  // Chat Create Form

  const { toast } = useToast();
  const [chatName, setChatName] = useState("");
  const [desc, setDesc] = useState("");
  const [sysmsg, setSysmsg] = useState("");
  const [chatModel, setChatModel] = useState("gpt-3.5-turbo-0125");
  const [temperature, setTemperature] = useState(0);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [selectedFilesSize, setSelectedFilesSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState("");
  const [urls, setUrls] = useState([]);
  const [url, setUrl] = useState("");
  const [allsize, setAllsize] = useState(0);
  const [currsize, setCurrsize] = useState(0);
  const [OpenAiKey, setOpenAiKey] = useState("");

  const planDetails = {
    basic: { size: "3 MB", totaal: 3145728 },
    pro: { size: "15 MB", totaal: 15728640 },
    ultimate: { size: "30 MB", totaal: 31457280 },
    free: { size: "1 MB", totaal: 1048576 },
    as1: { size: "3 MB", totaal: 3145728 },
    as2: { size: "15 MB", totaal: 15728640 },
    as3: { size: "15 MB", totaal: 15728640 },
    as4: { size: "15 MB", totaal: 15728640 },
    as5: { size: "30 MB", totaal: 31457280 },
    as6: { size: "30 MB", totaal: 31457280 },
  };

  const planName = useSelector((state) => state.user.plan_name);

  // --------------------------------------------------------

  useEffect(() => {
    dispatch(fetchUserData());
  }, []);

  useEffect(() => {
    if (planDetails[planName]) {
      setSize(planDetails[planName].size);
      setAllsize(planDetails[planName].totaal);
    }
  }, [planName]);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    let totalSize = 0;
    files.forEach((file) => {
      totalSize += file.size;
    });
    setTotalFileSize(totalSize);
    setCurrsize(totalSize);
  }, [files]);

  const handleDrop = (e) => {
    e.preventDefault();
    const selectedFiles = Array.from(e.dataTransfer.files);
    const newTotalSize =
      totalFileSize + selectedFiles.reduce((acc, file) => acc + file.size, 0);
    const newSelectedFilesSize = selectedFiles.reduce(
      (acc, file) => acc + file.size,
      0,
    );
    setCurrsize(newTotalSize);

    if (planDetails[planName]) {
      const { size, totaal } = planDetails[planName];

      if (newTotalSize > totaal || newSelectedFilesSize > totaal) {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: `Total file size should not exceed ${size}.`,
        });

        return;
      }
    }

    setSelectedFilesSize(newSelectedFilesSize);
    setTotalFileSize(newTotalSize);
    setFiles([...files, ...selectedFiles]);
  };

  function handleFileInputChange(e) {
    const selectedFiles = Array.from(e.target.files);
    const newTotalSize =
      totalFileSize + selectedFiles.reduce((acc, file) => acc + file.size, 0);
    const newSelectedFilesSize = selectedFiles.reduce(
      (acc, file) => acc + file.size,
      0,
    );
    setCurrsize(newTotalSize);

    if (planDetails[planName]) {
      const { size, totaal } = planDetails[planName];

      if (newTotalSize > totaal || newSelectedFilesSize > totaal) {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: `Total file size should not exceed ${size}.`,
        });
        return;
      }
    }

    setSelectedFilesSize(newSelectedFilesSize);
    setTotalFileSize(newTotalSize);
    setFiles([...files, ...selectedFiles]);
  }

  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const handleURLS = () => {
    if (!url || url === "") return;
    setUrls([...urls, url]);
    setUrl("");
  };

  const delete_url = (index) => {
    setUrls((prevUrls) => {
      const newUrls = [...prevUrls];
      newUrls.splice(index, 1);
      return newUrls;
    });
  };


  // --------------------------------------------------------

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !chatName ||
      !desc ||
      !files ||
      !chatModel ||
      (files.length === 0 && urls.length === 0)
    ) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "Please fill all the fields.",
      });
      return;
    }

    setLoading(true);

    const formData = new FormData();

    formData.append("chat_name", chatName);
    formData.append("description", desc);
    formData.append("sys_message", sysmsg);
    formData.append("chat_model", chatModel);
    formData.append("temperature", temperature || 0);
    formData.append("openai_key", OpenAiKey);
    formData.append("shared", true);
    urls.forEach((url) => {
      formData.append("urls", url);
    });
    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await axios.post(`${Domain}api/create-chat/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      });
      // console.log(response.data);
      if (response.status === 200) {
        setLoading(false);
        toast({
          title: "Success",
          description: "Chat created successfully.",
        });
        window.location = "/chatbots";
      } else {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "There was a problem with your request. false",
        });
        setLoading(false);
      }
    } catch (error) {
      const msg = error.response.data.detail;
      setLoading(false);
      if (msg) {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: msg,
        });
      } else {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "There was a problem with your request.",
        });
      }
    }
  };

  // --------------------------------------------------------

  return (
    <>
      <section className="create_chat min-h-screen">
        <div className="flex w-[100vw] flex-col items-start justify-center md:h-screen md:flex-row md:items-center">
          {" "}
          <div className="BACK flex h-full w-full flex-col items-center justify-center md:w-[4vw]">
            <Button
              asChild
              variant="outline"
              className="dark h-full w-full rounded-none"
            >
              <Link to="/chatbots">
                <ChevronLeftIcon className="h-5 w-5" />
              </Link>
            </Button>
          </div>
          <div className="mx-auto grid min-h-[100vh] w-full grid-cols-2 gap-6 p-2 md:my-auto md:h-[90vh] md:w-[96vw] md:grid-cols-3 md:p-6">
            <div className="sidetab col-span-2 flex flex-col items-center justify-center text-white md:col-span-1">
              {/* Tab Start Here */}

              <Card className="flex h-full max-h-screen w-full flex-col ">
                <CardHeader>
                  <CardTitle>Create Chatbot</CardTitle>
                  <CardDescription>
                    Make changes to your Chatbot details here.
                  </CardDescription>
                </CardHeader>
                <CardContent className="grow space-y-2">
                  <div className="space-y-1">
                    <Label htmlFor="name">Name</Label>
                    <Input
                      value={chatName}
                      onChange={(event) => {
                        setChatName(event.target.value);
                      }}
                      id="name"
                      defaultValue="Your Name"
                      autocomplete="off"
                    />
                  </div>
                  <div className="space-y-1">
                    <Label htmlFor="description">Description</Label>
                    <Input
                      id="description"
                      autocomplete="off"
                      value={desc}
                      onChange={(event) => setDesc(event.target.value)}
                    />
                  </div>
                  <div className="space-y-1">
                    <Label htmlFor="sysmsg">System Message</Label>
                    <Textarea
                    className="resize-none"
                    rows="3"
                      id="sysmsg"
                      value={sysmsg}
                      onChange={(event) => setSysmsg(event.target.value)}
                      placeholder="Type your message here."
                    />
                  </div>

                  <div className="space-y-1">
                    <Label htmlFor="name">Model</Label>
                    <Select
                      className=""
                      value={chatModel}
                      onValueChange={setChatModel}
                      defaultValue="gpt-3.5-turbo-0125"
                    >
                      <SelectTrigger className="">
                        <SelectValue placeholder="GPT Model" />
                      </SelectTrigger>
                      <SelectContent className="">
                        <SelectGroup>
                          <SelectLabel>Model</SelectLabel>
                          <SelectItem value="gpt-3.5-turbo-0125">
                            GPT 3.5 Turbo
                          </SelectItem>
                          <SelectItem value="gpt-4-0125-preview">
                            GPT 4 Turbo (More Efficient)
                          </SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="space-y-1">
                    <ComboboxDemo setOpenAiKey={setOpenAiKey} />
                  </div>
                  <div className="space-y-1 ">
                    <div className="grid gap-2 pt-2">
                      <HoverCard openDelay={200}>
                        <HoverCardTrigger asChild>
                          <div className="grid gap-4">
                            <div className="flex items-center justify-between">
                              <Label htmlFor="temperature">Temperature</Label>
                              <span className="w-12 rounded-md border border-transparent px-2 py-0.5 text-right text-sm text-muted-foreground hover:border-border">
                                {temperature}
                              </span>
                            </div>
                            <Slider
                              id="temperature"
                              max={1}
                              defaultValue={[temperature]}
                              step={0.1}
                              onValueChange={(values) =>
                                setTemperature(values[0])
                              }
                              className="[&_[role=slider]]:h-4 [&_[role=slider]]:w-4"
                              aria-label="Temperature"
                            />
                          </div>
                        </HoverCardTrigger>
                        <HoverCardContent
                          align="start"
                          className="w-[260px] text-sm"
                          side="left"
                        >
                          Controls randomness: lowering results in less random
                          completions. As the temperature approaches zero, the
                          model will become deterministic and repetitive.
                        </HoverCardContent>
                      </HoverCard>
                    </div>
                  </div>
                </CardContent>
                <CardFooter>
                  <Button
                    onClick={handleSubmit}
                    disabled={loading}
                    className="w-full"
                  >
                    {loading ? (
                      <TbFidgetSpinner className="mr-2 h-4 w-4 animate-spin" />
                    ) : (
                      <></>
                    )}{" "}
                    Create
                  </Button>
                </CardFooter>
              </Card>

              {/* Tab End Here */}
            </div>

            <div className="sidetab col-span-2 flex h-full w-full flex-col items-center justify-center">
              <Tabs
                defaultValue="account"
                className="flex h-full max-h-screen w-full flex-col"
              >
                <TabsList className="grid h-[6vh] w-full grid-cols-2">
                  <TabsTrigger value="account">Files</TabsTrigger>
                  <TabsTrigger value="password">Urls</TabsTrigger>
                  {/*   <TabsTrigger value="popoe">Text</TabsTrigger> */}
                </TabsList>
                <TabsContent value="account" className="h-[86vh]">
                  <Card className="yel flex  h-full flex-col">
                    <CardHeader>
                      <CardTitle>Files</CardTitle>
                      <CardDescription>
                        Add files to your chatbot here. You can add up to {size}{" "}
                        of files
                      </CardDescription>
                    </CardHeader>
                    <CardContent className="h-full space-y-2">
                      <div
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        className="h-full max-h-full w-full rounded-lg"
                      >
                        <header className="flex flex-col items-center justify-evenly rounded-lg ">
                          <div class="h-full w-full rounded-lg py-4 md:px-9 ">
                            <div
                              class="transform rounded-lg border-2 border-dashed border-gray-300 bg-gray-100 p-8 text-center transition duration-300 ease-in-out hover:scale-105 hover:border-black hover:shadow-md"
                              id="dropzone"
                            >
                              <label
                                for="fileInput"
                                class="flex cursor-pointer flex-col items-center space-y-2"
                              >
                                <svg
                                  class="h-16 w-16 text-gray-400"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                  ></path>
                                </svg>
                                <span class="text-gray-600">
                                  Drag and drop your files here
                                </span>
                                <span class="text-sm text-gray-500">
                                  (or click to select)
                                </span>
                              </label>
                              <input
                                type="file"
                                ref={fileInputRef}
                                id="fileInput"
                                style={{ display: "none" }}
                                onChange={handleFileInputChange}
                                accept="application/pdf"
                                multiple
                              />
                            </div>
                          </div>
                        </header>

                        {files && files.length !== 0 ? (
                          <div className="flex w-full flex-col overflow-y-scroll md:h-[45%]">
                            <Progress
                              className=""
                              value={(currsize / allsize) * 100}
                            />
                            <div
                              id="style-7"
                              className="grid grid-cols-1 pt-4 md:mx-5 md:grid-cols-4"
                            >
                              {files.map((file, index) => (
                                <div
                                  key={index}
                                  className="mx-1 mb-2 flex h-10 items-center justify-between gap-2 rounded-xl border-2 border-black bg-gray-900 px-3 py-2 text-sm text-gray-50 "
                                >
                                  <div className="flex w-[85%] items-center gap-x-2">
                                    {(() => {
                                      switch (file.type) {
                                        case "application/pdf":
                                          return (
                                            <BsFilePdfFill className="h-5 w-5 text-red-500" />
                                          );
                                        default:
                                          return <></>;
                                      }
                                    })()}

                                    <div
                                      className="truncate"
                                      style={{ maxWidth: "60%" }}
                                    >
                                      {file.name}
                                    </div>
                                  </div>
                                  <button
                                    className="text-gray-50  hover:text-red-500"
                                    onClick={() => handleDeleteFile(index)}
                                  >
                                    <XMarkIcon className="h-5 w-5" />
                                  </button>
                                </div>
                              ))}
                            </div>{" "}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </CardContent>
                  </Card>
                </TabsContent>
                <TabsContent value="password" className="h-[86vh]">
                  <Card className="h-full">
                    <CardHeader className="h-[12%]">
                      <CardTitle>URLs</CardTitle>
                      <CardDescription>Add URls to crawl here.</CardDescription>
                    </CardHeader>
                    <CardContent className="h-[75%] overflow-y-scroll">
                      <ScrollArea className="h-[100%] w-full space-y-2 rounded-md py-4 pr-4">
                        <div className="mx-2 mt-2 ">
                          <Input
                            id="url"
                            type="url"
                            placeholder="https://...."
                            value={url}
                            autocomplete="off"
                            onChange={(event) => setUrl(event.target.value)}
                          />
                        </div>
                        {urls.map((url, index) => (
                          <div className="mx-2 mt-2">
                            <div className="flex w-full gap-x-4 ">
                              <Input
                                id="url"
                                type="url"
                                value={url}
                                disabled
                                autocomplete="off"
                                //  onChange={(event) => setUrl(event.target.value)}
                              />

                              <Button
                                variant="outline"
                                size="icon"
                                onClick={(e) => delete_url(index)}
                              >
                                <XMarkIcon className="h-4 w-4" />
                              </Button>
                            </div>
                          </div>
                        ))}
                      </ScrollArea>
                    </CardContent>
                    <CardFooter className="h-[13%]">
                      <div className="flex gap-x-4 pt-4">
                        <Button onClick={handleURLS}>Add another URL</Button>
                        <Button onClick={handleURLS}>Save</Button>
                      </div>
                    </CardFooter>
                  </Card>
                </TabsContent>
              </Tabs>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export { ComboboxDemo };
