/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Button } from "../../Shadcn/ui/button";
import { Card, CardContent, CardFooter } from "../../Shadcn/ui/card";
import { Input } from "../../Shadcn/ui/input";
import { Label } from "../../Shadcn/ui/label";
import { useToast } from "../../Shadcn/ui/use-toast";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../Shadcn/ui/select";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../Shadcn/ui/accordion";
import axios from "axios";
import { Domain } from "../../Env";
import ChatEmbedViewer from "./ChatEmbedViewer";
import { fetchChatData } from "../../Redux/Chat/dtlSlice";
import { useDispatch, useSelector } from "react-redux";
import { TrashIcon } from "@heroicons/react/24/outline";

function ChatEmbed() {
  // Inside your component
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { toast } = useToast();

  const [chatName, setChatName] = useState("");
  const [chatId, setChatId] = useState("");
  const [desc, setDesc] = useState("");

  const [initialMessage, setInitialMessage] = useState("");
  const [bodyBg, setBodyBg] = useState("");

  const [headerImg, setHeaderImg] = useState(null);
  const [bubbleImg, setBubbleImg] = useState(null);

  const [bubbleImageUrl, setBubbleImageUrl] = useState(null);
  const [headerImageUrl, setHeaderImageUrl] = useState(null);
  const [headerTImg, setHeaderTImg] = useState("");
  const [bubbleTImg, setBubbleTImg] = useState("");

  const [bubblebg, setBubblebg] = useState("");
  const [bubbletext, setBubbletext] = useState("");
  const [headerBg, setHeaderBg] = useState("");
  const [headerText, setHeaderText] = useState("");

  const [aiBg, setAiBg] = useState("");
  const [aiText, setAiText] = useState("");
  const [userBg, setUserBg] = useState("");
  const [userText, setUserText] = useState("");

  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBg, setSuggestionBg] = useState("");
  const [suggestionText, setSuggestionText] = useState("");
  const [suggestionStyle, setSuggestionStyle] = useState("border");

  const chatState = useSelector((state) => state.dtl);

  useEffect(() => {
    // // console.log(chatState);

    setChatName(chatState.name);
    setChatId(chatState.chat_id);
    setDesc(chatState.desc);

    setInitialMessage(chatState.bubble.initial_message);
    setBodyBg(chatState.bubble.body_bg);

    setSuggestions(
      chatState.bubble.suggestions.message_suggestions
        ? chatState.bubble.suggestions.message_suggestions
        : [],
    );
    setSuggestionBg(chatState.bubble.suggestions.bg_color);
    setSuggestionText(chatState.bubble.suggestions.text_color);
    setSuggestionStyle(chatState.bubble.suggestions.style);

    setHeaderBg(chatState.bubble.header.bg_color);
    setHeaderText(chatState.bubble.header.text_color);
    setHeaderTImg(chatState.bubble.header.image);

    setBubblebg(chatState.bubble.bubble.bg_color);
    setBubbletext(chatState.bubble.bubble.text_color);
    setBubbleTImg(chatState.bubble.bubble.image);

    setAiBg(chatState.bubble.ai_message.bg_color);
    setAiText(chatState.bubble.ai_message.text_color);

    setUserBg(chatState.bubble.user_message.bg_color);
    setUserText(chatState.bubble.user_message.text_color);
  }, [chatState.bubble]);

  const handleBubbleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setBubbleImg(file);
      setBubbleImageUrl(
        URL.createObjectURL(file) ||
          setBubbleImageUrl(URL.createObjectURL(bubbleImg)),
      );
    }
  };

  const handleHeaderImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setHeaderImg(file);
      setHeaderImageUrl(
        URL.createObjectURL(file) ||
          setHeaderImageUrl(URL.createObjectURL(headerImg)),
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("chat_id", chatId);
    formData.append("chat_name", chatName);
    formData.append("initial_message", initialMessage);
    formData.append("description", desc);

    formData.append("body_bg", bodyBg);

    if (headerImg) {
      formData.append("header_image", headerImg);
    }
    if (bubbleImg) {
      formData.append("bubble_image", bubbleImg);
    }

    formData.append("header_bg", headerBg);
    formData.append("header_text_color", headerText);

    formData.append("bubble_bg", bubblebg);
    formData.append("bubble_text_color", bubbletext);

    formData.append("ai_message_bg", aiBg);
    formData.append("ai_message_text_color", aiText);

    formData.append("user_message_bg", userBg);
    formData.append("user_message_text_color", userText);

    formData.append("message_suggestions", JSON.stringify(suggestions));
    formData.append("suggestion_bg", suggestionBg);
    formData.append("suggestion_text_color", suggestionText);
    formData.append("suggestion_style", suggestionStyle);

    try {
      const authToken = localStorage.getItem("auth_token");
      const response = await axios.post(`${Domain}api/edit-bubble/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status === 200) {
        await dispatch(fetchChatData(chatId)).unwrap();
        toast({
          title: "Success.",
          description: "Settings updated successfully.",
        });
      }
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "Please try again or reload the page.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-y-4 p-4 md:flex-row md:items-stretch md:justify-stretch md:gap-y-0 md:p-8">
      <div className="flex h-full basis-1/2 flex-col">
        <Card className="flex h-full w-full flex-col">
          <CardContent className="mt-4 grow space-y-2">
            <Accordion
              type="single"
              defaultValue="basic"
              collapsible
              className="space-y-3"
            >
              {/* BASIC SETTINGS */}
              <AccordionItem value="basic" className="border-0">
                <CardContent className="space-y-3 rounded-lg border p-4 px-5 shadow-sm">
                  <AccordionTrigger className="border-0 p-0 hover:no-underline">
                    Basic Settings
                  </AccordionTrigger>

                  <AccordionContent className="space-y-5 px-1">
                    <div className="flex flex-col gap-y-2">
                      <Label htmlFor="ChatName">Name:</Label>
                      <Input
                        id="ChatName"
                        value={chatName}
                        className="m-0"
                        onChange={(event) => setChatName(event.target.value)}
                      />
                    </div>

                    <div className="flex flex-col gap-y-2">
                      <Label htmlFor="initialMessage">Initial Message:</Label>
                      <Input
                        id="initialMessage"
                        value={initialMessage}
                        className="m-0"
                        onChange={(event) =>
                          setInitialMessage(event.target.value)
                        }
                      />
                    </div>

                    <div className="space-y-1">
                      <Label htmlFor="description">Description</Label>
                      <Input
                        id="description"
                        value={desc}
                        onChange={(event) => setDesc(event.target.value)}
                      />
                    </div>

                    <div className="flex items-center justify-between gap-x-2">
                      <Label htmlFor="body-bg">Body Background:</Label>
                      <Input
                        id="body-bg"
                        className="w-1/4 rounded-lg p-2 px-3"
                        type="color"
                        value={bodyBg}
                        onChange={(event) => setBodyBg(event.target.value)}
                      />
                    </div>
                  </AccordionContent>
                </CardContent>
              </AccordionItem>

              {/* BUBBLE SETTINGS */}
              <AccordionItem value="bubble" className="border-0">
                <CardContent className="space-y-3 rounded-lg border p-4 px-5 shadow-sm">
                  <AccordionTrigger className="border-0 p-0 hover:no-underline">
                    Bubble Settings
                  </AccordionTrigger>

                  <AccordionContent className="space-y-5 px-1">
                    <div className="flex flex-col gap-y-2">
                      <Label htmlFor="bubbleImg">Bubble Image</Label>
                      <Input
                        id="bubbleImg"
                        className=""
                        type="file"
                        onChange={handleBubbleImageChange}
                      />

                      {bubbleImageUrl && (
                        <div className="flex items-center gap-4">
                          <img
                            src={bubbleImageUrl}
                            alt="Bubble Image"
                            className="w-1/5 rounded-md border"
                          />

                          <Button
                            onClick={() => {
                              setBubbleImageUrl(null);
                              setBubbleImg(null);
                            }}
                            className="h-auto bg-red-100 p-2 text-xs text-red-900 hover:bg-red-800 hover:text-white"
                            size="sm"
                          >
                            Remove Image
                          </Button>
                        </div>
                      )}
                    </div>

                    <div className="flex  items-center justify-between gap-x-2">
                      <Label htmlFor="bubblebg">Bubble Background:</Label>
                      <Input
                        id="bubblebg"
                        className="w-1/4 rounded-lg p-2 px-3"
                        type="color"
                        value={bubblebg}
                        onChange={(event) => setBubblebg(event.target.value)}
                      />
                    </div>
                    <div className="flex items-center justify-between gap-x-2">
                      <Label htmlFor="bubbletext">Bubble Color:</Label>
                      <Input
                        id="bubbletext"
                        className="w-1/4 rounded-lg p-2 px-3"
                        type="color"
                        value={bubbletext}
                        onChange={(event) => setBubbletext(event.target.value)}
                      />
                    </div>
                  </AccordionContent>
                </CardContent>
              </AccordionItem>

              {/* HEADER SETTINGS */}
              <AccordionItem value="header" className="border-0">
                <CardContent className="space-y-3 rounded-lg border p-4 px-5 shadow-sm">
                  <AccordionTrigger className="border-0 p-0 hover:no-underline">
                    Header Settings
                  </AccordionTrigger>

                  <AccordionContent className="space-y-5 px-1">
                    <div className="flex flex-col gap-y-2">
                      <Label htmlFor="headerImg">Header Image</Label>
                      <Input
                        id="headerImg"
                        type="file"
                        className="w-full"
                        onChange={handleHeaderImageChange}
                      />
                    </div>

                    <div className="flex items-center justify-between gap-x-2">
                      <Label htmlFor="headerbg">Header Color:</Label>
                      <Input
                        id="headerbg"
                        className="w-1/4 rounded-lg p-2 px-3"
                        type="color"
                        value={headerBg}
                        onChange={(event) => setHeaderBg(event.target.value)}
                      />
                    </div>
                    <div className="flex items-center justify-between gap-x-2">
                      <Label htmlFor="headertext">Header Text Color:</Label>
                      <Input
                        id="headertext"
                        type="color"
                        className="w-1/4 rounded-lg p-2 px-3 "
                        value={headerText}
                        onChange={(event) => setHeaderText(event.target.value)}
                      />
                    </div>
                  </AccordionContent>
                </CardContent>
              </AccordionItem>

              {/* AI MESSAGE SETTINGS */}
              <AccordionItem value="ai_message" className="border-0">
                <CardContent className="space-y-3 rounded-lg border p-4 px-5 shadow-sm">
                  <AccordionTrigger className="border-0 p-0 hover:no-underline">
                    AI Message Settings
                  </AccordionTrigger>

                  <AccordionContent className="space-y-5 px-1">
                    <div className="flex items-center justify-between gap-x-2 py-2">
                      <Label htmlFor="ai_bg">Message Background:</Label>
                      <Input
                        id="ai_bg"
                        className="w-1/4 rounded-lg p-2 px-3"
                        type="color"
                        value={aiBg}
                        onChange={(event) => setAiBg(event.target.value)}
                      />
                    </div>
                    <div className="flex items-center justify-between gap-x-2">
                      <Label htmlFor="ai_text">Text Color:</Label>
                      <Input
                        id="ai_text"
                        className="w-1/4 rounded-lg p-2 px-3"
                        type="color"
                        value={aiText}
                        onChange={(event) => setAiText(event.target.value)}
                      />
                    </div>
                  </AccordionContent>
                </CardContent>
              </AccordionItem>

              {/* USER MESSAGE SETTINGS */}
              <AccordionItem value="user_message" className="border-0">
                <CardContent className="space-y-3 rounded-lg border p-4 px-5 shadow-sm">
                  <AccordionTrigger className="border-0 p-0 hover:no-underline">
                    User Message Settings
                  </AccordionTrigger>

                  <AccordionContent className="space-y-5 px-1">
                    <div className="flex items-center justify-between gap-x-2">
                      <Label htmlFor="user_bg">Message Background:</Label>
                      <Input
                        id="user_bg"
                        className="w-1/4 rounded-lg p-2 px-3"
                        type="color"
                        value={userBg}
                        onChange={(event) => setUserBg(event.target.value)}
                      />
                    </div>
                    <div className="flex items-center justify-between gap-x-2">
                      <Label htmlFor="user_text">Text Color:</Label>
                      <Input
                        id="user_text"
                        className="w-1/4 rounded-lg p-2 px-3"
                        type="color"
                        value={userText}
                        onChange={(event) => setUserText(event.target.value)}
                      />
                    </div>
                  </AccordionContent>
                </CardContent>
              </AccordionItem>

              {/* SUGGESTIONS SETTINGS */}
              <AccordionItem value="suggestions" className="border-0">
                <CardContent className="space-y-3 rounded-lg border p-4 px-5 shadow-sm">
                  <AccordionTrigger className="border-0 p-0 hover:no-underline">
                    Suggestions Settings
                  </AccordionTrigger>

                  <AccordionContent className="space-y-5 px-1">
                    <Label htmlFor="">Suggestions (Max 5)</Label>
                    {suggestions.length > 1 ? (
                      suggestions.map((suggestion, index) => (
                        <div className="flex gap-4">
                          <Input
                            value={suggestion}
                            onChange={(event) => {
                              const newSuggestions = [...suggestions];
                              newSuggestions[index] = event.target.value;
                              setSuggestions(newSuggestions);
                            }}
                          />
                          <Button
                            onClick={() => {
                              const newSuggestions = [...suggestions];
                              newSuggestions.splice(index, 1);
                              setSuggestions(newSuggestions);
                            }}
                            variant="outline"
                            size="icon"
                          >
                            <TrashIcon className="h-5 w-5 cursor-pointer  text-red-500" />
                          </Button>
                        </div>
                      ))
                    ) : (
                      <Input
                        value={suggestions[0]}
                        onChange={(event) => {
                          setSuggestions([event.target.value]);
                        }}
                      />
                    )}
                    <Button
                      onClick={() => {
                        if (suggestions.length < 5) {
                          setSuggestions([...suggestions, ""]);
                        }
                      }}
                      disabled={suggestions.length >= 5}
                    >
                      {suggestions.length > 1
                        ? "Add another"
                        : "Add a Suggestion"}
                    </Button>
                    <div className="flex flex-col gap-y-2">
                      <Label htmlFor="">Style:</Label>
                      <Select
                        className=""
                        value={suggestionStyle}
                        onValueChange={setSuggestionStyle}
                        defaultValue="border"
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Style" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem value="border">Border</SelectItem>
                            <SelectItem value="bg">Background</SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>

                      <div className="mt-5 flex items-center justify-between gap-x-2">
                        <Label htmlFor="suggestion-bg">Style Color:</Label>
                        <Input
                          id="suggestion-bg"
                          className="w-1/4 rounded-lg p-2 px-3"
                          type="color"
                          value={suggestionBg}
                          onChange={(event) =>
                            setSuggestionBg(event.target.value)
                          }
                        />
                      </div>
                      <div className="mt-4 flex items-center justify-between gap-x-2">
                        <Label htmlFor="suggestion-text">Text Color:</Label>
                        <Input
                          id="suggestion-text"
                          className="w-1/4 rounded-lg p-2 px-3"
                          type="color"
                          value={suggestionText}
                          onChange={(event) =>
                            setSuggestionText(event.target.value)
                          }
                        />
                      </div>
                    </div>
                  </AccordionContent>
                </CardContent>
              </AccordionItem>
            </Accordion>
          </CardContent>

          <CardFooter>
            <Button
              onClick={handleSubmit}
              className="w-full"
              disabled={loading}
            >
              {loading ? "Loading..." : "Update Chat"}
            </Button>
          </CardFooter>
        </Card>{" "}
        <div className="h-10 w-full bg-white"></div>
      </div>

      <ChatEmbedViewer
        initialMessage={initialMessage}
        chatName={chatName}
        bodyBg={bodyBg}
        header={{
          bg_color: headerBg,
          text_color: headerText,
          image: headerImageUrl,
        }}
        bubble={{
          bg_color: bubblebg,
          text_color: bubbletext,
          image: bubbleImageUrl,
        }}
        ai_message={{
          bg_color: aiBg,
          text_color: aiText,
        }}
        user_message={{
          bg_color: userBg,
          text_color: userText,
        }}
        suggestionSettings={{
          list: suggestions,
          suggestionBg,
          suggestionText,
          suggestionStyle,
        }}
      />
    </div>
  );
}

export default ChatEmbed;
