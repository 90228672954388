import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Domain } from "../../Env";

const initialState = {
  count: 0,
  chats: [],
};

var count = 0;

const fetchChats = createAsyncThunk("chats/fetchChats", async () => {
  if (count === 0) {
    const authToken = localStorage.getItem("auth_token");
    const response = await fetch(`${Domain}api/get-chats/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data.chats;
    } else {
      const data = await response.json();
      // console.log(data);
      return false;
    }
  } else {
    return "checked";
  }
});

export const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    createchat(state, action) {
      state.count = 0;
      count = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChats.pending, (state) => {})
      .addCase(fetchChats.fulfilled, (state, action) => {
        if (action.payload === "checked") {
          return;
        } else {
          // Check for duplicates before adding chats to state
          const uniqueChats = action.payload.filter((chat) => {
            return !state.chats.some(
              (existingChat) => existingChat.id === chat.id,
            );
          });

          if (uniqueChats.length > 0) {
            state.chats.push(...uniqueChats);
            state.count++;
            count++;
          }
        }
      })
      .addCase(fetchChats.rejected, (state, action) => {
        // console.log(action);
      });
  },
});

export { fetchChats };
export default chatsSlice.reducer;