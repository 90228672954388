/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from '../../Shadcn/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../../Shadcn/ui/card';
import { Input } from '../../Shadcn/ui/input';
import { Label } from '../../Shadcn/ui/label';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../Shadcn/ui/tabs';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogClose,
} from '../../Shadcn/ui/dialog';
import { fetchUserData } from '../../Redux/User/userSlice';
import { useToast } from '../../Shadcn/ui/use-toast';
import { Progress } from '../../Shadcn/ui/progress';
import '../style.css';
import { useSelector, useDispatch } from 'react-redux';
import { TbFidgetSpinner } from 'react-icons/tb';
import { Domain } from '../../Env';
import axios from 'axios';
import '../../Pages/style.css';
import { BsFilePdfFill } from 'react-icons/bs';
import { ScrollArea } from '../../Shadcn/ui/scroll-area';
import { TrashIcon } from '@heroicons/react/24/solid';

export default function Sources() {
  // Chat Create Form

  const { toast } = useToast();
  const chat_id = useSelector((state) => state.dtl.chat_id);

  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [selectedFilesSize, setSelectedFilesSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState('');
  const [urls, setUrls] = useState([]);
  const [url, setUrl] = useState('');
  const [allsize, setAllsize] = useState(0);
  const [currsize, setCurrsize] = useState(0);

  const planDetails = {
    basic: { size: '5 MB', totaal: 5242880 },
    pro: { size: '15 MB', totaal: 15728640 },
    ultimate: { size: '25 MB', totaal: 26214400 },
    free: { size: '1 MB', totaal: 104857600000 },
  };

  const planName = useSelector((state) => state.user.plan_name);

  // --------------------------------------------------------

  useEffect(() => {
    dispatch(fetchUserData());
  }, []);

  useEffect(() => {
    if (planDetails[planName]) {
      setSize(planDetails[planName].size);
      setAllsize(planDetails[planName].totaal);
    }
  }, [planName]);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    let totalSize = 0;
    files.forEach((file) => {
      totalSize += file.size;
    });
    setTotalFileSize(totalSize);
    setCurrsize(totalSize);
  }, [files]);

  const handleDrop = (e) => {
    e.preventDefault();
    const selectedFiles = Array.from(e.dataTransfer.files);
    const newTotalSize =
      totalFileSize + selectedFiles.reduce((acc, file) => acc + file.size, 0);
    const newSelectedFilesSize = selectedFiles.reduce(
      (acc, file) => acc + file.size,
      0
    );
    setCurrsize(newTotalSize);

    if (planDetails[planName]) {
      const { size, totaal } = planDetails[planName];

      if (newTotalSize > totaal || newSelectedFilesSize > totaal) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: `Total file size should not exceed ${size}.`,
        });

        return;
      }
    }

    setSelectedFilesSize(newSelectedFilesSize);
    setTotalFileSize(newTotalSize);
    setFiles([...files, ...selectedFiles]);
  };

  function handleFileInputChange(e) {
    const selectedFiles = Array.from(e.target.files);
    const newTotalSize =
      totalFileSize + selectedFiles.reduce((acc, file) => acc + file.size, 0);
    const newSelectedFilesSize = selectedFiles.reduce(
      (acc, file) => acc + file.size,
      0
    );
    setCurrsize(newTotalSize);

    if (planDetails[planName]) {
      const { size, totaal } = planDetails[planName];

      if (newTotalSize > totaal || newSelectedFilesSize > totaal) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: `Total file size should not exceed ${size}.`,
        });
        return;
      }
    }

    setSelectedFilesSize(newSelectedFilesSize);
    setTotalFileSize(newTotalSize);
    setFiles([...files, ...selectedFiles]);
  }

  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const handleURLS = () => {
    if (!url || url === '') return;
    setUrls([...urls, url]);
    setUrl('');
  };

  const delete_url = (index) => {
    setUrls((prevUrls) => {
      const newUrls = [...prevUrls];
      newUrls.splice(index, 1);
      return newUrls;
    });
  };

  // --------------------------------------------------------

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (files.length === 0 && urls.length === 0) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please upload a file to retrain.',
      });
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('chat_id', chat_id);
    if (urls.length !== 0) {
      formData.append('urls_to_load', urls.join(','));
    }
    if (files.length !== 0) {
      files.forEach((file) => {
        formData.append('files', file);
      });
    }

    try {
      const response = await axios.post(`${Domain}api/add-data/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      });
      // console.log(response.data);
      if (response.status === 200) {
        setLoading(false);
        toast({
          title: 'Success',
          description: 'Data updated successfully.',
        });
      } else {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem with your request. false',
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      });
    }
  };

  const [sources, setSources] = useState([]); // New state for sources
  const [sourceToDelete, setSourceToDelete] = useState(null); // State to keep track of source to delete

  useEffect(() => {
    const fetchSources = async () => {
      // console.log('fetching sources');
      try {
        const response = await axios.get(
          `${Domain}api/get-sources/${chat_id}/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
            },
          }
        );
        // console.log(response.data);
        setSources(response.data.sources);
      } catch (error) {
        console.error('Error fetching sources:', error);
        toast({
          variant: 'destructive',
          title: 'Error',
          description: 'Failed to fetch sources',
        });
      }
    };
    fetchSources();
  }, []);

  // Handle delete confirmation
  const handleDelete = async () => {
    // console.log(sourceToDelete);
    try {
      let formData = new FormData();
      formData.append('sources', sourceToDelete);
      formData.append('chat_id', chat_id);

      await axios.post(`${Domain}api/delete-source/`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setSources(sources.filter((source) => source !== sourceToDelete));
      setSourceToDelete(null);
      toast({
        title: 'Success',
        description: 'Source deleted successfully.',
      });
    } catch (error) {
      console.error('Error deleting source:', error);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to delete source',
      });
    }
  };

  // --------------------------------------------------------

  const renderSources = () => {
    return sources.map((source, index) => {
      const fileName = source.split('\\').pop(); // Extracts the file name from the path
      return (
        <>
          <div
            key={index}
            className="flex justify-between items-center mt-2 mx-2"
          >
            <div className="flex w-full gap-x-4 ">
              <Input
                id="url"
                type="url"
                value={fileName}
                readonly
                autocomplete="off"
              />

              <Dialog>
                <DialogTrigger asChild>
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={() => {
                      // console.log('clicked');
                      // console.log(source);
                      setSourceToDelete(source); // Keeps the full path for deletion
                    }}
                  >
                    <TrashIcon className="h-4 w-4 text-red-600 hover:text-red-900" />
                  </Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[350px]">
                  <h2 className="text-md text-black">
                    Are you sure you want to delete {fileName}?
                  </h2>
                  <div className="flex gap-2">
                    <DialogClose asChild>
                      <Button type="button" variant="secondary">
                        No, Take me Back
                      </Button>
                    </DialogClose>
                    <Button
                      type="button"
                      className="bg-red-600 hover:bg-red-900"
                      onClick={() => {
                        handleDelete();
                      }}
                    >
                      {loading ? 'Loading...' : 'Yes, Delete'}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </>
      );
    });
  };
  return (
    <>
      <section className="create_chat">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-center w-full min-h-[92vh] h-[92vh] py-4">
          {' '}
          <div className="grid grid-cols-2 md:grid-cols-3 p-2 md:p-1 gap-4 w-[95%] mx-auto md:h-[85vh]">
            <div className="flex items-center justify-center flex-col text-white col-span-2 md:col-span-1 md:h-[85vh] ">
              <Card className="h-full w-full flex flex-col max-h-screen ">
                <CardHeader>
                  <CardTitle>Update Sources</CardTitle>
                  <CardDescription>
                    Make changes to your Chatbot sources here.
                  </CardDescription>
                </CardHeader>
                <CardContent className="space-y-2 grow h-[55vh] ">
                  {' '}
                  <div className="space-y-1">
                    <Label htmlFor="limit">Data Limit</Label>
                    <Progress className="" value={(currsize / allsize) * 100} />
                  </div>
                  <div className="space-y-1">
                    <Label htmlFor="name">Current Sources</Label>
                  </div>
                  <ScrollArea className="w-full h-[70%] py-2 pr-2 rounded-md overflow-hidden  ">
                    {urls.map((url, index) => (
                      <div className="mt-2 mx-2">
                        <div className="flex w-full gap-x-4 ">
                          <Input
                            id="url"
                            type="url"
                            value={url}
                            disabled
                            autocomplete="off"
                            //  onChange={(event) => setUrl(event.target.value)}
                          />

                          <Button
                            variant="outline"
                            size="icon"
                            onClick={(e) => delete_url(index)}
                          >
                            <XMarkIcon className="h-4 w-4" />
                          </Button>
                        </div>
                      </div>
                    ))}

                    {renderSources()}
                    <div className="w-full mb-2"></div>
                  </ScrollArea>
                </CardContent>
                <CardFooter>
                  <Button
                    onClick={(e) => handleSubmit(e)}
                    disabled={loading}
                    className="w-full"
                  >
                    {loading ? (
                      <TbFidgetSpinner className="mr-2 h-4 w-4 animate-spin" />
                    ) : (
                      <></>
                    )}{' '}
                    Retrain Chatbot
                  </Button>
                </CardFooter>
              </Card>
            </div>

            <div className="flex items-center justify-center flex-col w-full h-full col-span-2 md:h-[85vh]">
              <Tabs
                defaultValue="account"
                className="h-full w-full flex flex-col max-h-screen"
              >
                <TabsList className="grid w-full grid-cols-2 h-[6vh]">
                  <TabsTrigger value="account">Files</TabsTrigger>
                  <TabsTrigger value="password">Urls</TabsTrigger>
                </TabsList>
                <TabsContent value="account" className="h-[86vh]">
                  <Card className="h-full yel  flex flex-col">
                    <CardHeader>
                      <CardTitle>Files</CardTitle>
                      <CardDescription>
                        Add files to your chatbot here. You can add up to {size}{' '}
                        of files
                      </CardDescription>
                    </CardHeader>
                    <CardContent className="space-y-2 h-[64vh]">
                      <div
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        className="w-full h-full rounded-lg max-h-full"
                      >
                        <header className="flex flex-col items-center justify-evenly rounded-lg ">
                          <div class="w-full md:px-9 py-4 h-full rounded-lg ">
                            <div
                              class="bg-gray-100 p-8 text-center rounded-lg border-dashed border-2 border-gray-300 hover:border-black transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-md"
                              id="dropzone"
                            >
                              <label
                                for="fileInput"
                                class="cursor-pointer flex flex-col items-center space-y-2"
                              >
                                <svg
                                  class="w-16 h-16 text-gray-400"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                  ></path>
                                </svg>
                                <span class="text-gray-600">
                                  Drag and drop your files here
                                </span>
                                <span class="text-gray-500 text-sm">
                                  (or click to select)
                                </span>
                              </label>
                              <input
                                type="file"
                                ref={fileInputRef}
                                id="fileInput"
                                style={{ display: 'none' }}
                                onChange={handleFileInputChange}
                                accept="application/pdf"
                                multiple
                              />
                            </div>
                          </div>
                        </header>{' '}
                        <Progress
                          className=""
                          value={(currsize / allsize) * 100}
                        />
                        <ScrollArea className="w-full h-[47%] py-2 pr-2 rounded-md overflow-hidden  ">
                          {files && files.length !== 0 ? (
                            <div className="flex md:h-[45%] overflow-y-scroll flex-col w-full">
                              <div
                                id="style-7"
                                className="grid grid-cols-1 md:grid-cols-4 md:mx-5 pt-4"
                              >
                                {files.map((file, index) => (
                                  <div
                                    key={index}
                                    className="rounded-xl h-10 border-2 border-black bg-gray-900 px-3 py-2 text-sm text-gray-50 mb-2 mx-1 flex items-center justify-between gap-2 "
                                  >
                                    <div className="flex gap-x-2 items-center w-[85%]">
                                      {(() => {
                                        switch (file.type) {
                                          case 'application/pdf':
                                            return (
                                              <BsFilePdfFill className="h-5 w-5 text-red-500" />
                                            );
                                          default:
                                            return <></>;
                                        }
                                      })()}

                                      <div
                                        className="truncate"
                                        style={{ maxWidth: '60%' }}
                                      >
                                        {file.name}
                                      </div>
                                    </div>
                                    <button
                                      className="text-gray-50  hover:text-red-500"
                                      onClick={() => handleDeleteFile(index)}
                                    >
                                      <XMarkIcon className="h-5 w-5" />
                                    </button>
                                  </div>
                                ))}
                              </div>{' '}
                            </div>
                          ) : (
                            ''
                          )}
                        </ScrollArea>
                      </div>
                    </CardContent>
                  </Card>
                </TabsContent>
                <TabsContent value="password" className="h-[86vh]">
                  <Card className="h-full w-full flex flex-col">
                    <CardHeader className="h-[12%]">
                      <CardTitle>URLs</CardTitle>
                      <CardDescription>Add URls to crawl here.</CardDescription>
                    </CardHeader>
                    <CardContent className="h-[50vh] grow overflow-y-scroll">
                      <ScrollArea className="w-full h-[100%] py-4 pr-4 space-y-2 rounded-md">
                        <div className="mt-2 mx-2 ">
                          <Input
                            id="url"
                            type="url"
                            placeholder="https://...."
                            value={url}
                            autocomplete="off"
                            onChange={(event) => setUrl(event.target.value)}
                          />
                        </div>
                        {urls.map((url, index) => (
                          <div className="mt-2 mx-2">
                            <div className="flex w-full gap-x-4 ">
                              <Input
                                id="url"
                                type="url"
                                value={url}
                                disabled
                                autocomplete="off"
                                //  onChange={(event) => setUrl(event.target.value)}
                              />

                              <Button
                                variant="outline"
                                size="icon"
                                onClick={(e) => delete_url(index)}
                              >
                                <XMarkIcon className="h-4 w-4" />
                              </Button>
                            </div>
                          </div>
                        ))}
                      </ScrollArea>
                    </CardContent>
                    <CardFooter className="h-[13%]">
                      <div className="flex pt-4 gap-x-4">
                        <Button onClick={handleURLS}>Add another URL</Button>
                        <Button onClick={handleURLS}>Save</Button>
                      </div>
                    </CardFooter>
                  </Card>
                </TabsContent>
              </Tabs>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
