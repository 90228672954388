/* eslint-disable */
import { apiDomain, Domain, Home } from "../../Env";
import axios from "axios";

export const getGoogleCalendarConnections = async () => {
  const auth_token = localStorage.getItem("auth_token");

  try {
    const response = await axios.get(
      `${apiDomain}account/api/get-gcal-connections/`,
      {
        headers: { Authorization: `Bearer ${auth_token}` },
      }
    );

    return response.data.connections;
  } catch (error) {
    return error.response.data;
  }
};