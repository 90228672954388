/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./style.css";
import { FaSquareCheck } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Separator } from "../Shadcn/ui/separator";
import { Button } from "../Shadcn/ui/button";
import { useNavigate } from "react-router-dom";
import { useToast } from "../Shadcn/ui/use-toast";
import { Domain } from "../Env";
import { Link } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardDescription,
} from "../Shadcn/ui/card";
import { Input } from "../Shadcn/ui/input";
import {
  CheckIcon,
  BuildingOfficeIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";

export default function Settings() {
  let userDetails = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const pricingData = [
    {
      name: "Free",
      icon: <CheckIcon className="h-6 w-6" />,
      price: "$0",
      annually: "$0",
      description: "For testing and hobby projects.",
      features: [
        "1 Chatbots",
        "1MB / Chatbot",
        "2 URLs per Chatbot",
        "Unlimited messages",
        "Own API key",
        "GPT-4 Turbo access",
        "Embed on unlimited websites",
        "Upload multiple files",
        "Customize chatbot widget",
        "Multiple File types supported",
      ],
    },
    {
      name: "Basic",
      icon: <CheckIcon className="h-6 w-6" />,
      price: "$19",
      annually: "$190",
      description: "For most businesses that want to optimize web queries.",
      features: [
        "3 Chatbots",
        "3 MB / Chatbot",
        "10 URLs per Chatbot",
        "Unlimited messages",
        "Own API key",
        "GPT-4 Turbo access",
        "Embed on unlimited websites",
        "Upload multiple files",
        "Customize chatbot widget",
        "Multiple File types supported",
        "Lead Collection",
        "Facebook Messenger Integration",
        "WhatsApp Integration",
      ],
    },
    {
      name: "Premium",
      icon: <SparklesIcon className="h-6 w-6" />,
      price: "$80",
      annually: "$800",
      description: "For medium sized businesses and enterprises.",
      features: [
        "15 Chatbots",
        "15 MB / Chatbot",
        "20 URLs per Chatbot",
        "Unlimited messages",
        "Own API key",
        "GPT-4 Turbo access",
        "Embed on unlimited websites",
        "Upload multiple files",
        "Customize chatbot widget",
        "Multiple File types supported",
        "Lead Collection",
        "Facebook Messenger Integration",
        "WhatsApp Integration",
        "Appointment Booking",
        "Twilio Integration",
        "Live Chat Handover (300 sessions/month)",
        "Zapier Integration (Coming Soon)",
        "Slack Integration (Coming Soon)",
        "Early access to Future features",
        "Remove Nitrobot.io branding",
      ],
    },
    {
      name: "Enterprise",
      icon: <BuildingOfficeIcon className="h-6 w-6" />,
      price: "$120",
      annually: "$500",
      description:
        "For large businesses and enterprises catering to a larger audience.  ",
      features: [
        "30 Chatbots",
        "30 MB / Chatbot",
        "50 URLs per Chatbot",
        "Unlimited messages",
        "Own API key",
        "GPT-4 Turbo access",
        "Embed on unlimited websites",
        "Upload multiple files",
        "Customize chatbot widget",
        "Multiple File types supported",
        "Lead Collection",
        "Facebook Messenger Integration",
        "WhatsApp Integration",
        "Appointment Booking",
        "Twilio Integration",
        "Woocommerce Integration",
        "Woocommerce Collect Order",
        "Woocommerce Product Recommendations",
        "Woocommerce Product Search",
        "Woocommerce Discount Coupons generation",
        "Woocommerce Order Tracking",
        "Live Chat Handover (1000 sessions/month)",
        "Zapier Integration (Coming Soon)",
        "Slack Integration (Coming Soon)",
        "Early access to Future features",
        "Remove Nitrobot.io branding",
        "Priority Support",
      ],
    },
    {
      name: "AppSumo 1",
      icon: <CheckIcon className="h-6 w-6" />,
      price: "$53",
      annually: "$53",
      description: "For most businesses that want to optimize web queries.",
      features: [
        "3 Chatbots",
        "3 MB / Chatbot",
        "10 URLs per Chatbot",
        "Unlimited messages",
        "Own API key",
        "GPT-4 Turbo access",
        "Embed on unlimited websites",
        "Upload multiple files",
        "Customize chatbot widget",
        "Multiple File types supported",
        "Lead Collection",
        "Facebook Messenger Integration",
        "WhatsApp Integration",
      ],
    },
    {
      name: "AppSumo 2",
      icon: <SparklesIcon className="h-6 w-6" />,
      price: "$106",
      annually: "$106",
      description: "For medium sized businesses and enterprises.",
      features: [
        "10 Chatbots",
        "15 MB / Chatbot",
        "20 URLs per Chatbot",
        "Unlimited messages",
        "Own API key",
        "GPT-4 Turbo access",
        "Embed on unlimited websites",
        "Upload multiple files",
        "Customize chatbot widget",
        "Multiple File types supported",
        "Lead Collection",
        "Facebook Messenger Integration",
        "WhatsApp Integration",
        "Appointment Booking",
        "Zapier Integration (Coming Soon)",
        "Slack Integration (Coming Soon)",
        "Early access to Future features",
        "Remove Nitrobot.io branding",
      ],
    },
    {
      name: "AppSumo 3",
      icon: <SparklesIcon className="h-6 w-6" />,
      price: "$159",
      annually: "$159",
      description: "For medium sized businesses and enterprises.",
      features: [
        "15 Chatbots",
        "15 MB / Chatbot",
        "20 URLs per Chatbot",
        "Unlimited messages",
        "Own API key",
        "GPT-4 Turbo access",
        "Embed on unlimited websites",
        "Upload multiple files",
        "Customize chatbot widget",
        "Multiple File types supported",
        "Lead Collection",
        "Facebook Messenger Integration",
        "WhatsApp Integration",
        "Appointment Booking",
        "Twilio Integration",
        "Live Chat Handover (100 sessions/month)",
        "Zapier Integration (Coming Soon)",
        "Slack Integration (Coming Soon)",
        "Early access to Future features",
        "Remove Nitrobot.io branding",
      ],
    },
    {
      name: "AppSumo 4",
      icon: <SparklesIcon className="h-6 w-6" />,
      price: "$212",
      annually: "$212",
      description: "For medium sized businesses and enterprises.",
      features: [
        "15 Chatbots",
        "15 MB / Chatbot",
        "20 URLs per Chatbot",
        "Unlimited messages",
        "Own API key",
        "GPT-4 Turbo access",
        "Embed on unlimited websites",
        "Upload multiple files",
        "Customize chatbot widget",
        "Multiple File types supported",
        "Lead Collection",
        "Facebook Messenger Integration",
        "WhatsApp Integration",
        "Appointment Booking",
        "Twilio Integration",
        "Live Chat Handover (300 sessions/month)",
        "Zapier Integration (Coming Soon)",
        "Slack Integration (Coming Soon)",
        "Early access to Future features",
        "Remove Nitrobot.io branding",
      ],
    },
    {
      name: "AppSumo 5",
      icon: <BuildingOfficeIcon className="h-6 w-6" />,
      price: "$265",
      annually: "$265",
      description:
        "For large businesses and enterprises catering to a larger audience.  ",
      features: [
        "30 Chatbots",
        "30 MB / Chatbot",
        "50 URLs per Chatbot",
        "Unlimited messages",
        "Own API key",
        "GPT-4 Turbo access",
        "Embed on unlimited websites",
        "Upload multiple files",
        "Customize chatbot widget",
        "Multiple File types supported",
        "Lead Collection",
        "Facebook Messenger Integration",
        "WhatsApp Integration",
        "Appointment Booking",
        "Twilio Integration",
        "Live Chat Handover (1000 sessions/month)",
        "Zapier Integration (Coming Soon)",
        "Slack Integration (Coming Soon)",
        "Early access to Future features",
        "Remove Nitrobot.io branding",
        "Priority Support",
      ],
    },
    {
      name: "AppSumo 6",
      icon: <BuildingOfficeIcon className="h-6 w-6" />,
      price: "$318",
      annually: "$318",
      description:
        "For large businesses and enterprises catering to a larger audience.  ",
      features: [
        "30 Chatbots",
        "30 MB / Chatbot",
        "50 URLs per Chatbot",
        "Unlimited messages",
        "Own API key",
        "GPT-4 Turbo access",
        "Embed on unlimited websites",
        "Upload multiple files",
        "Customize chatbot widget",
        "Multiple File types supported",
        "Lead Collection",
        "Facebook Messenger Integration",
        "WhatsApp Integration",
        "Appointment Booking",
        "Twilio Integration",
        "Woocommerce Integration",
        "Woocommerce Collect Order",
        "Woocommerce Product Recommendations",
        "Woocommerce Product Search",
        "Woocommerce Discount Coupons generation",
        "Woocommerce Order Tracking",
        "Live Chat Handover (1000 sessions/month)",
        "Zapier Integration (Coming Soon)",
        "Slack Integration (Coming Soon)",
        "Early access to Future features",
        "Remove Nitrobot.io branding",
        "Priority Support",
      ],
    },
  ];
  const [plan, setPlan] = useState(pricingData[0]);
  const authToken = localStorage.getItem("auth_token");

  const Billing = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`${Domain}account/api/billing/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`, // Include the token in the headers
        },
      });

      if (response.ok) {
        const data = await response.json();
        const redirect = data.billing_url;
        window.open(redirect, "_blank");
      } else {
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const [codes, setCodes] = useState([]);
  const [currentCode, setCurrentCode] = useState("");

  const handleAddCode = () => {
    if (currentCode && codes.length < 5) {
      setCodes((prev) => [...prev, currentCode]);
      setCurrentCode("");
    }
  };

  const handleDeleteCode = (index) => {
    setCodes((prev) => prev.filter((_, i) => i !== index));
  };

  const handleRedeem = async () => {
    setLoading(true);
    const formData = new FormData();
    codes.forEach((code) => formData.append("codes", code));

    try {
      const response = await fetch(
        `${Domain}account/api/activate-appsumo-codes/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`, // Assuming you're using Bearer token
          },
          body: formData, // Sending the codes as FormData
        },
      );

      const data = await response.json();

      if (response.ok) {
        // Handle success
        toast({
          title: "Success",
          description: data.message,
        });
        // Optionally clear codes or navigate the user
        setCodes([]); // Clear codes on success
      } else {
        // Handle errors
        toast({
          title: "Error",
          description: data.message,
        });
      }
    } catch (error) {
      console.error("Error redeeming codes:", error);
      toast({
        title: "Error",
        description: "An error occurred while redeeming codes.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log(userDetails);
    if (userDetails.plan_name === "free") {
      setPlan(pricingData[0]);
    } else if (userDetails.plan_name === "basic") {
      setPlan(pricingData[1]);
    } else if (userDetails.plan_name === "pro") {
      setPlan(pricingData[2]);
    } else if (userDetails.plan_name === "ultimate") {
      setPlan(pricingData[3]);
    } else if (userDetails.plan_name === "as1") {
      setPlan(pricingData[4]);
    } else if (userDetails.plan_name === "as2") {
      setPlan(pricingData[5]);
    } else if (userDetails.plan_name === "as3") {
      setPlan(pricingData[6]);
    } else if (userDetails.plan_name === "as4") {
      setPlan(pricingData[7]);
    } else if (userDetails.plan_name === "as5") {
      setPlan(pricingData[8]);
    } else if (userDetails.plan_name === "as6") {
      setPlan(pricingData[9]);
    }
  }, [userDetails]);

  return (
    <>
      <div className="flex w-full items-start justify-center px-4 py-4 sm:px-10 sm:py-6 lg:flex-none lg:px-8 xl:px-8">
        <div className="flex w-full flex-col items-start justify-center gap-x-4 md:p-3">
          {" "}
          <div className="flex w-full  flex-col items-center justify-start gap-y-3 md:flex-row md:justify-start md:gap-y-0">
            <div className="flex max-w-2xl flex-col items-start gap-y-2 text-black sm:text-left">
              <h4 className="text-3xl font-bold tracking-tight text-black sm:text-4xl ">
                Settings
              </h4>
              <p className="font-small lg:text-md tracking-tight text-gray-700 sm:text-sm">
                Manage your account settings
              </p>
            </div>
          </div>{" "}
          <Separator orientation="horizontal" className="my-3 md:my-6" />
          <div className="grid w-full grid-cols-1 gap-4 py-4 md:grid-cols-3">
            <div className="col-span-2 flex w-full flex-col items-center gap-y-4">
              <Card className="w-full">
                <CardHeader>
                  <h1 className="text-2xl font-bold text-black">
                    Redeem App Sumo Codes
                  </h1>
                  <CardDescription>
                    Redeem your App Sumo codes here, you can redeem multiple
                    codes. Limit of 5 codes per user.
                  </CardDescription>
                </CardHeader>
                <CardContent className="space-y-6">
                  <Input
                    placeholder="Enter your App Sumo code"
                    value={currentCode}
                    onChange={(e) => setCurrentCode(e.target.value)}
                  />
                  {codes.map((code, index) => (
                    <div key={index} className="flex w-full gap-x-4">
                      <Input value={code} disabled />
                      <Button onClick={() => handleDeleteCode(index)}>
                        Delete
                      </Button>
                    </div>
                  ))}
                  <div className="flex w-full items-center justify-end gap-x-4">
                    <Button
                      className="bg-slate-900  block w-fit rounded-md border-2 border-chat-purple py-2 text-center text-sm font-semibold text-black hover:bg-chat-purple hover:text-white disabled:cursor-not-allowed disabled:opacity-50"
                      onClick={handleAddCode}
                    >
                      Add another
                    </Button>
                    <Button
                      className="bg-slate-900  block w-fit rounded-md border-2 border-chat-purple py-2 text-center text-sm font-semibold text-black hover:bg-chat-purple hover:text-white disabled:cursor-not-allowed disabled:opacity-50"
                      onClick={handleRedeem}
                    >
                      Redeem
                    </Button>
                  </div>
                </CardContent>
              </Card>
              <Card className="w-full">
                <CardHeader>
                  <h1 className="text-2xl font-bold text-black">
                    Personal Details
                  </h1>
                  <CardDescription>
                    Your personal details and account information
                  </CardDescription>
                </CardHeader>
                <CardContent className="space-y-6">
                  <h4 className="text-lg">
                    Your Name:{" "}
                    <span className="font-bold">
                      {userDetails.user[0].username}
                    </span>
                  </h4>
                  <h4 className="text-lg">
                    Your Email:{" "}
                    <span className="font-bold">
                      {userDetails.user[0].email}
                    </span>
                  </h4>
                </CardContent>
              </Card>
              <Card className="w-full">
                <CardHeader>
                  <h1 className="text-2xl font-bold text-black">Usage</h1>
                  <CardDescription>
                    The number of chatbots you have created and are allowed to
                    create
                  </CardDescription>
                </CardHeader>
                <CardContent className="space-y-6">
                  <h4 className="text-lg">
                    Allowed Chatbots:{" "}
                    <span className="font-bold">
                      {userDetails.bots_allowed}
                    </span>
                  </h4>
                  <h4 className="text-lg">
                    Chatbots Created:{" "}
                    <span className="font-bold">
                      {userDetails.bots_created}
                    </span>
                  </h4>
                </CardContent>
              </Card>
            </div>
            <div
              className={`divide-slate-200 divide-y rounded-lg border-2  bg-white shadow-sm`}
            >
              <div className="p-6">
                <h2 className="item-center flex gap-x-2 text-xl font-bold leading-6 text-black">
                  {plan.icon}
                  {plan.name}
                </h2>
                <p className="text-slate-700 mt-2 text-base leading-tight text-black">
                  {plan.description}
                </p>
                <p className="mt-8">
                  <span className="text-slate-900 text-4xl font-bold tracking-tighter text-black">
                    {plan.price}
                  </span>

                  <span className="text-slate-500 text-base font-medium text-black">
                    /mo
                  </span>
                </p>

                <div className="mt-8 flex w-full flex-col gap-2 md:flex-row">
                  <Link className="w-full" to="/pricing">
                    <Button className="bg-slate-900  block w-full rounded-md border-2 border-chat-purple py-2 text-center text-sm font-semibold text-black hover:bg-chat-purple hover:text-white disabled:cursor-not-allowed disabled:opacity-50">
                      Upgrade Plan
                    </Button>
                  </Link>
                  {userDetails.plan_name !== "free" && (
                    <Button
                      className="bg-slate-900  block w-full rounded-md border-2 border-chat-purple py-2 text-center text-sm font-semibold text-black hover:bg-chat-purple hover:text-white disabled:cursor-not-allowed disabled:opacity-50"
                      onClick={Billing}
                      variant="outline"
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Billing"}
                    </Button>
                  )}
                </div>
              </div>
              <div className="px-6 pb-8 pt-6 ">
                <h3 className="text-slate-900 text-sm font-bold uppercase tracking-wide text-black">
                  What's included
                </h3>
                <ul role="list" className="mt-4 space-y-3">
                  {plan.features.map((feature) => (
                    <li className="flex space-x-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 flex-shrink-0 text-purple-700"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        aria-hidden="true"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M5 12l5 5l10 -10"></path>
                      </svg>
                      <span className="text-slate-700 text-base text-black">
                        {feature}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
