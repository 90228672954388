import * as React from "react"

import { cn } from "../../lib/utils"

const Input = React.forwardRef(({ className, type, ...props }, ref) => {
  return (
    (<input
      type={type}
      className={cn(
        "flex h-10 w-full text-black rounded-md border border-[#b7bbc1] bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-black file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 bg-white focus:outline-none focus:border-transparent focus:ring-1 focus:ring-white focus:ring-offset-2 focus:ring-offset-[#dfc7f9] transition duration-500 ease-in-out transform shadow-sm",
        className
      )}
      ref={ref}
      {...props} />)
  );
})
Input.displayName = "Input"

export { Input }
