/* eslint-disable */
import { useEffect, useState } from "react";
import { Input } from "../../Shadcn/ui/input";
import { Button } from "../../Shadcn/ui/button";
import { Switch } from "../../Shadcn/ui/switch";
import { Label } from "../../Shadcn/ui/label";
import { useSelector } from "react-redux";
import { useToast } from "../../Shadcn/ui/use-toast";
import { Separator } from "../../Shadcn/ui/separator";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { cn } from "../../lib/utils";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../Shadcn/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../Shadcn/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../Shadcn/ui/command";
import { Checkbox } from "../../Shadcn/ui/checkbox";
import { apiDomain } from "../../Env";
import axios from "axios";
import { getGoogleCalendarConnections } from "../Connections/google-calendar-connections";
import { Timezones } from "../utilities";
import { fetchChatData } from "../../Redux/Chat/dtlSlice";
import { useDispatch } from "react-redux";

function ChatAppointmentsSettings() {
  const [loading, setLoading] = useState(false);
  const [timeZoneBoxOpen, setTimeZoneBoxOpen] = useState(false);
  const dispatch = useDispatch();

  // Redux State Variables
  let chat_id = useSelector((state) => state.dtl.chat_id);
  let appointmentSettings = useSelector((state) => state.dtl.appointments);
  const [appointmentsEnabled, setAppointmentsEnabled] = useState(false);
  const [appointmentStartTime, setAppointmentStartTime] = useState("");
  const [appointmentEndTime, setAppointmentEndTime] = useState("");
  const [appointmentDuration, setAppointmentDuration] = useState("30");
  const [timeZone, setTimeZone] = useState("");
  const [daysOff, setDaysOff] = useState();
  const [provider, setProvider] = useState("nitrobot");
  const [googleCalendarId, setGoogleCalendarId] = useState("");
  const [googleCalendarConnectionId, setGoogleCalendarConnectionId] =
    useState("");

  // To store the connections
  const [googleCalendarConnections, setGoogleCalendarConnections] = useState(
    [],
  );

  const { toast } = useToast();

  // Update the state when the redux state changes
  useEffect(() => {
    if (appointmentSettings) {
      setAppointmentsEnabled(appointmentSettings.enabled);
      setAppointmentStartTime(appointmentSettings.appointment_start_time);
      setAppointmentEndTime(appointmentSettings.appointment_end_time);
      setAppointmentDuration(appointmentSettings.appointment_duration);
      setTimeZone(appointmentSettings.time_zone);
      setDaysOff(appointmentSettings.days_off.split(",") || []);
      setProvider(() => {
        if (
          appointmentSettings.provider !== null &&
          appointmentSettings.provider !== undefined &&
          appointmentSettings.provider !== ""
        ) {
          return appointmentSettings.provider;
        } else {
          return "nitrobot";
        }
      });
      setGoogleCalendarId(appointmentSettings.google_calendar_id);
      setGoogleCalendarConnectionId(
        appointmentSettings.google_calendar_connection_id,
      );
    }
  }, [appointmentSettings]);

  useEffect(() => {
    const fetchConnections = async () => {
      const result = await getGoogleCalendarConnections();
      setGoogleCalendarConnections(result);
    };

    fetchConnections();
  }, [localStorage.getItem("auth_token")]);

  useEffect(() => {
    if (timeZone === "") {
      return;
    }
    let t = timeZone.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
    setTimeZone(t);
  }, [timeZone]);

  // DAYS LIST
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  //   Handle the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("collect_appointments", appointmentsEnabled);
    formData.append("appointment_start_time", appointmentStartTime);
    formData.append("appointment_end_time", appointmentEndTime);
    formData.append("appointment_duration", appointmentDuration);
    formData.append("time_zone", timeZone);
    formData.append("days_off", daysOff.join(","));
    formData.append("appointment_calendar_provider", provider);
    formData.append("google_calendar_id", googleCalendarId);
    formData.append(
      "google_calendar_connection_id",
      googleCalendarConnectionId,
    );

    const response = await axios.post(
      `${apiDomain}api/edit-appointments-settings/${chat_id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      },
    );

    if (response.status === 200) {
      dispatch(fetchChatData(chat_id));
      toast({
        title: "Settings updated successfully.",
        description: "The settings have been updated successfully.",
      });
      setLoading(false);
      return;
    } else {
      toast({
        variant: "destructive",
        title: "Failed to update settings.",
        description:
          "There was an error updating the settings. Please reload the page and try again.",
      });
      setLoading(false);
      return;
    }
  };

  return (
    <div className="flex h-[92vh] min-h-[92vh] w-full items-start justify-center px-4 py-4 text-black sm:px-10 sm:py-6 lg:flex-none lg:px-8 xl:px-8">
      <div className="flex w-full flex-col items-start justify-center gap-x-4 md:p-3">
        {/* Page Title and Enable Switch */}
        <div className="flex w-full  flex-col items-center justify-start gap-y-3 md:flex-row md:justify-start md:gap-y-0">
          <div className="flex w-full items-center justify-between text-black sm:text-left">
            <div className="flex flex-col gap-y-2">
              <h4 className="text-xl font-bold tracking-tight text-black sm:text-4xl ">
                Appointment Settings
              </h4>
              <p className="font-small lg:text-md tracking-tight text-gray-700 sm:text-sm">
                Make changes to the appointment settings for the chatbot.
              </p>
              <p className="text-xs text-gray-500">
                  <span className="font text-xs font-semibold text-black mr-2">
                    Note:
                  </span>
                  This feature works best with GPT 4 Turbo model
                </p>
            </div>
            <div className="flex items-center gap-x-4">
              <Label
                className="text-md hidden font-semibold md:flex"
                htmlFor="appointmentsEnabled"
              >
                Enable Appointments
              </Label>
              <Switch
                id="appointmentsEnabled"
                checked={appointmentsEnabled}
                onCheckedChange={() =>
                  setAppointmentsEnabled(!appointmentsEnabled)
                }
              />
            </div>
          </div>
        </div>

        <Separator orientation="horizontal" className="my-3 md:my-4" />

        <form
          onSubmit={handleSubmit}
          className="w-full space-y-4 overflow-y-scroll"
        >
          {appointmentsEnabled && (
            <>
              <div className="space-y-3 rounded-md border-2 p-4 pb-6">
                {/* CALENDAR PROVDER */}
                <h2 className="text-lg font-bold">Calendar Provider</h2>

                <div className="flex w-full flex-col items-start gap-3">
                  <Label htmlFor="start-time">
                    Choose your appointments manager
                  </Label>
                  <Select
                    className="border-[#b7bbc1] placeholder-gray-500"
                    value={provider}
                    onValueChange={setProvider}
                    defaultValue={provider || "nitrobot"}
                    id="provider"
                    placeholder="Select the calendar provider"
                  >
                    <SelectTrigger className={cn("border-[#b7bbc1]")}>
                      <SelectValue
                        className="justify-start"
                        placeholder="Select the calendar provider"
                      />
                    </SelectTrigger>
                    <SelectContent className="">
                      <SelectGroup>
                        <SelectItem value="nitrobot">Nitrobot</SelectItem>
                        <SelectItem value="google">Google Calendar</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>

                {/* SETTINGS IF THE PROVIDER IS GOOGLE */}
                {provider === "google" && (
                  <div className="flex items-center gap-3">
                    <div className="flex w-1/2 flex-grow flex-col gap-3">
                      <Label className="text-black" htmlFor="connection">
                        Select Google Calendar Connection
                      </Label>
                      <Select
                        value={googleCalendarConnectionId}
                        onValueChange={setGoogleCalendarConnectionId}
                        id="connection"
                      >
                        <SelectTrigger className={cn("border-[#b7bbc1]")}>
                          <SelectValue
                            defaultValue={googleCalendarConnectionId}
                            placeholder={
                              googleCalendarConnectionId ||
                              "Select Calendar Connection"
                            }
                          />
                        </SelectTrigger>

                        <SelectContent className=" overflow-y-scroll ">
                          <SelectGroup>
                            <SelectLabel>Available Connections</SelectLabel>
                            {googleCalendarConnections.length > 0 &&
                              googleCalendarConnections.map((connection) => (
                                <SelectItem
                                  key={connection.connection_id}
                                  value={connection.connection_id}
                                  disabled={
                                    !connection.details.connection_status
                                  }
                                  className="flex items-center justify-between"
                                >
                                  {connection.details.email}
                                  {!connection.details.connection_status ? (
                                    <p className="text-red-800">
                                      (Not Connected)
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </SelectItem>
                              ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="flex w-1/2 flex-grow flex-col gap-3">
                      <Label htmlFor="google-calendar-id">
                        Google Calendar ID
                      </Label>
                      <Input
                        value={googleCalendarId}
                        onChange={(e) => {
                          setGoogleCalendarId(e.target.value);
                        }}
                        id="google-calendar-id"
                        placeholder="Google Calendar ID"
                        required
                        className="block"
                      />
                    </div>
                  </div>
                )}

                {/* REST OF THE SETTINGS */}

                <div className="grid w-full grid-cols-2 items-center gap-3">
                  {/* Days Off */}
                  <div className="flex w-full flex-grow flex-col gap-3">
                    <Label className="text-black">Days off</Label>
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          className={cn("w-full truncate border-[#b7bbc1] ")}
                        >
                          {daysOff.length > 0 ? (
                            <div className="flex w-full items-center gap-1 truncate md:overflow-visible">
                              {daysOff.map((day, index) => (
                                <div key={index}>
                                  {day}
                                  {", "}
                                </div>
                              ))}
                            </div>
                          ) : (
                            "Select"
                          )}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent>
                        <div className="grid gap-4">
                          <p className="text-sm text-black">
                            Bot will not book appointments for the days that you
                            select.
                          </p>
                          <div className="grid gap-2">
                            <div className="flex flex-col items-center gap-2">
                              {days.map((day) => (
                                <>
                                  <div className="flex w-full items-start justify-start gap-x-4">
                                    <Checkbox
                                      id={`day-${day}`}
                                      checked={daysOff.includes(day)}
                                      onCheckedChange={(checked) => {
                                        return checked
                                          ? setDaysOff([...daysOff, day])
                                          : setDaysOff(
                                              daysOff.filter((e) => e !== day),
                                            );
                                      }}
                                    />
                                    <Label
                                      htmlFor={`day-${day}`}
                                      className="text-sm font-medium leading-none text-black peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                    >
                                      {day}
                                    </Label>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>

                  {/* Appointment Duration */}
                  <div className="flex w-full flex-grow flex-col gap-3">
                    <Label htmlFor="appointment-duration">
                      Appointment Duration
                    </Label>
                    <Input
                      value={Number(appointmentDuration)}
                      onChange={(e) => {
                        setAppointmentDuration(String(e.target.value));
                      }}
                      type="number"
                      onKeyPress={(event) => {
                        if (event.key === "." || event.key === ",") {
                          event.preventDefault();
                        }
                      }}
                      min={0}
                      id="appointment-duration"
                      placeholder="Appointment Duration"
                      required
                    />
                  </div>
                </div>

                <h2 className="text-lg font-bold">Time Settings</h2>

                <div className="flex items-center gap-3">
                  {/* Start Time */}
                  <div className="flex w-1/2 flex-grow flex-col gap-3">
                    <Label htmlFor="start-time">Book Appointments From</Label>
                    <Input
                      value={appointmentStartTime}
                      onChange={(e) => {
                        setAppointmentStartTime(e.target.value);
                      }}
                      type="time"
                      id="start-time"
                      placeholder="Book Appointments From"
                      required
                      className="block"
                    />
                  </div>

                  {/* End Time */}
                  <div className="flex w-1/2 flex-grow flex-col gap-3">
                    <Label htmlFor="end-time">Book Appointments To</Label>
                    <Input
                      value={appointmentEndTime}
                      onChange={(e) => {
                        setAppointmentEndTime(e.target.value);
                      }}
                      type="time"
                      id="end-time"
                      placeholder="Book Appointments To"
                      required
                      className="block"
                    />
                  </div>
                </div>

                <div className="flex items-center gap-3">
                  {/* Time Zone */}
                  <div className="flex w-1/2 flex-col gap-3">
                    <Label htmlFor="time-zone">Time Zone</Label>
                    <Popover
                      open={timeZoneBoxOpen}
                      onOpenChange={setTimeZoneBoxOpen}
                    >
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          role="combobox"
                          aria-expanded={timeZoneBoxOpen}
                          className={cn(
                            "w-full justify-between border-[#b7bbc1]",
                          )}
                        >
                          {timeZone !== "" ? timeZone : "Select TimeZone"}
                          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-[200px] p-0">
                        <Command>
                          <CommandInput
                            placeholder="Search..."
                            className="h-9"
                          />
                          <CommandEmpty>No Timezone found.</CommandEmpty>
                          <CommandGroup className={cn("h-[200px]")}>
                            {Timezones.map((zone) => (
                              <CommandItem
                                key={zone}
                                value={zone}
                                onSelect={(currentValue) => {
                                  setTimeZone(currentValue);
                                  setTimeZoneBoxOpen(false);
                                }}
                                className={cn("cursor-pointer capitalize")}
                              >
                                {zone}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="item flex w-full justify-end">
            <Button disabled={loading} type="submit">
              {loading && (
                <>
                  <div
                    className="mr-2 inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                  ></div>
                  <span>Saving</span>
                </>
              )}
              {!loading && "Save Settings"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ChatAppointmentsSettings;

/* eslint-disable */
// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Domain } from "../../Env";
// import { useSelector } from "react-redux";
// import { Button } from "../../Shadcn/ui/button";
// import { ToastAction } from "../../Shadcn/ui/toast";
// import { Switch } from "../../Shadcn/ui/switch";
// import { FixedSizeList as List } from "react-window";
// import { useToast } from "../../Shadcn/ui/use-toast";
// import { Input } from "../../Shadcn/ui/input";
// import { Label } from "../../Shadcn/ui/label";
// import { Checkbox } from "../../Shadcn/ui/checkbox";
// import { getGoogleCalendarConnections } from "../Connections/google-calendar-connections";
// import {
//   Select,
//   SelectContent,
//   SelectGroup,
//   SelectItem,
//   SelectLabel,
//   SelectTrigger,
//   SelectValue,
// } from "../../Shadcn/ui/select";
// import {
//   Popover,
//   PopoverContent,
//   PopoverTrigger,
// } from "../../Shadcn/ui/popover";
// import { Timezones } from "../utilities";
// import {
//   Card,
//   CardContent,
//   CardDescription,
//   CardFooter,
//   CardHeader,
//   CardTitle,
// } from "../../Shadcn/ui/card";
// import { RadioGroup, RadioGroupItem } from "../../Shadcn/ui/radio-group";

// export default function AppointmentSettings() {
//   const [appointmentsFrom, setAppointmentsFrom] = useState("");
//   const [appointmentsTo, setAppointmentsTo] = useState("");
//   const [duration, setDuration] = useState("");
//   const [g_ID, setG_ID] = useState("primary");
//   const [daysOff, setDaysOff] = useState([]);
//   const [selectedZone, setSelectedZone] = useState("ARabian");
//   const [connections, setConnections] = useState([]);
//   const [connectionID, setConnectionID] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [manager, setManager] = useState("nitrobot");
//   const [disabled, setDisabled] = useState(false);

//   // Collect or Not
//   const [collect_appointments, setCollect_appointments] = useState(false);
//   const State = useSelector((state) => state.dtl);
//   const chatID = State.chat_id;
//   const { toast } = useToast();

//   const handleAppointmentChange = async (e) => {
//     setCollect_appointments(e);
//     setDisabled(true);
//     const formData = new FormData();
//     formData.append("collect_appointments", e);
//     formData.append("chat_id", chatID);
//     try {
//       const response = await axios.post(`${Domain}api/update-chat/`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
//         },
//       });
//       if (response.status === 200) {
//         setDisabled(false);
//         toast({
//           title: "Success",
//           description: "Action completed successfully",
//         });

//         // console.log(response.data);
//       } else {
//         setDisabled(false);
//         toast({
//           variant: "destructive",
//           title: "Uh oh! Something went wrong.",
//           description: "Action failed, please try again",
//         });
//         // console.log(response.data);
//       }
//     } catch (error) {
//       setDisabled(false);
//       toast({
//         variant: "destructive",
//         title: "Uh oh! Something went wrong.",
//         description: "Action failed, please try again",
//       });
//     }
//   };

//   useEffect(() => {
//     const updateStatesFromStore = () => {
//       setSelectedZone(State.time_zone);
//       setAppointmentsFrom(State.appointment_start_time);
//       setAppointmentsTo(State.appointment_end_time);
//       setDuration(State.appointment_duration);
//       setG_ID(State.google_calendar_id);
//       setDaysOff((State.days_off && State.days_off.split(",")) || []);
//       setCollect_appointments(State.collect_appointments);
//     };
//     updateStatesFromStore();
//   }, [State]);

//   useEffect(() => {
//     const fetchConnections = async () => {
//       const connections = await getGoogleCalendarConnections();
//       // console.log(connections);
//       setConnections(connections);
//     };

//     fetchConnections();
//   }, [localStorage.getItem("auth_token")]);

//   const handleSubmit = async () => {
//     if (
//       appointmentsFrom === "" ||
//       appointmentsTo === "" ||
//       duration === "" ||
//       selectedZone === ""
//     ) {
//       toast({
//         variant: "destructive",
//         title: "Uh oh! Something went wrong.",
//         description: "Please fill all the fields",
//       });

//       return;
//     }
//     setLoading(true);
//     const formData = new FormData();
//     formData.append("chat_id", chatID);
//     formData.append("time_zone", selectedZone);
//     formData.append("appointment_start_time", appointmentsFrom);
//     formData.append("appointment_end_time", appointmentsTo);
//     formData.append("appointment_duration", duration);
//     formData.append("days_off", daysOff.join(","));
//     formData.append("google_calendar_id", g_ID);
//     formData.append("connection_id", connectionID);

//     try {
//       const response = await axios.post(
//         `${Domain}api/set-appointment-details/`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
//           },
//         },
//       );

//       if (response.status === 200) {
//         setLoading(false);
//         toast({
//           title: "Action completed successfully",
//           description: response.data.message,
//         });
//       } else {
//         setLoading(false);
//         toast({
//           variant: "destructive",
//           title: "Uh oh! Something went wrong.",
//           description: response.data.message,
//         });
//       }
//     } catch (error) {
//       setLoading(false);
//       // console.log(error);
//       toast({
//         variant: "destructive",
//         title: "Uh oh! Something went wrong.",
//         description: "Contact Support",
//       });
//     }
//   };

//   const days = [
//     "Monday",
//     "Tuesday",
//     "Wednesday",
//     "Thursday",
//     "Friday",
//     "Saturday",
//     "Sunday",
//   ];

//   return (
//     <>
//       <div className="flex h-[92vh] min-h-[92vh] w-full flex-col items-start justify-center py-4 md:flex-row md:items-center">
//         {" "}
//         <div className="mx-auto grid w-[95%] grid-cols-2 gap-4 p-2 md:h-[85vh] md:grid-cols-4 md:p-1">
//           <div className="col-span-4 flex w-full flex-col items-center justify-start text-white md:col-span-4 md:h-[85vh] ">
//             <Card className="flex h-full w-full flex-col">
//               <CardHeader className="flex w-full flex-row items-center justify-between">
//                 <div className="flex flex-col gap-y-2">
//                   <CardTitle>Configure Appointments Settings</CardTitle>
//                   <CardDescription>
//                     Make changes to calender details here. Click save when
//                     you're done.
//                   </CardDescription>
//                 </div>
//                 <div className="flex items-center gap-x-4">
//                   <Label
//                     htmlFor="col_app"
//                     className="hidden text-black md:flex"
//                   >
//                     Collect Appointments
//                   </Label>

//                   <Switch
//                     disabled={disabled}
//                     checked={collect_appointments}
//                     value={collect_appointments}
//                     onClick={(e) =>
//                       handleAppointmentChange(!collect_appointments)
//                     }
//                     id="col_app"
//                   />
//                 </div>
//               </CardHeader>
//               <CardContent className="w-full grow">
//                 <div className="grid gap-4 pb-2">
//                   <div className="flex flex-col items-start justify-start gap-y-2 border-t-2 py-3 ">
//                     <h4 className="text-lg font-semibold">
//                       Choose your appointments manager
//                     </h4>
//                     <RadioGroup
//                       defaultValue={manager}
//                       onValueChange={(e) => {
//                         setManager(e);
//                       }}
//                     >
//                       <div className="flex gap-x-4">
//                         <div className="flex items-center space-x-2">
//                           <RadioGroupItem value="nitrobot" id="nitrobot" />
//                           <Label htmlFor="nitrobot">Nitrobot</Label>
//                         </div>
//                         <div className="flex items-center space-x-2">
//                           <RadioGroupItem value="g-cal" id="g-cal" />
//                           <Label htmlFor="g-cal">Google Calender</Label>
//                         </div>
//                       </div>
//                     </RadioGroup>
//                   </div>

//                   {manager !== "nitrobot" && (
//                     <>
//                       <Label className="text-black" htmlFor="connection">
//                         Calendar Connection
//                       </Label>
//                       <Select
//                         value={connectionID}
//                         onValueChange={setConnectionID}
//                         id="connection"
//                       >
//                         <SelectTrigger className=" text-black">
//                           <SelectValue
//                             defaultValue={connectionID}
//                             placeholder={
//                               connectionID || "Select Calendar Connection"
//                             }
//                           />
//                         </SelectTrigger>
//                         <SelectContent className=" overflow-y-scroll ">
//                           <SelectGroup>
//                             <SelectLabel>Available Connections</SelectLabel>
//                             {connections.length > 0 &&
//                               connections.map((connection) => (
//                                 <SelectItem
//                                   key={connection.connection_id}
//                                   value={connection.connection_id}
//                                   disabled={
//                                     !connection.details.connection_status
//                                   }
//                                   className="flex items-center justify-between"
//                                 >
//                                   {connection.details.email}
//                                   {!connection.details.connection_status ? (
//                                     <p className="text-red-800">
//                                       (Not Connected)
//                                     </p>
//                                   ) : (
//                                     ""
//                                   )}
//                                 </SelectItem>
//                               ))}
//                           </SelectGroup>
//                         </SelectContent>
//                       </Select>
//                     </>
//                   )}

//                   <div className="grid grid-cols-4 items-center gap-4">
//                     <div className="col-span-2 grid w-full items-center gap-1.5">
//                       <Label className="text-black" htmlFor="t_z">
//                         Time Zone
//                       </Label>

//                       <Select
//                         value={selectedZone}
//                         onValueChange={setSelectedZone}
//                       >
//                         <SelectTrigger className=" text-black">
//                           <SelectValue
//                             defaultValue={selectedZone}
//                             placeholder={selectedZone || "Select your Timezone"}
//                           />
//                         </SelectTrigger>
//                         <SelectContent className="h-[300px] overflow-y-scroll ">
//                           <SelectGroup>
//                             <SelectLabel>Time Zones</SelectLabel>
//                             <List
//                               height={300} // Set the height of the list
//                               itemCount={Timezones.length} // Total number of items
//                               itemSize={35} // Height of each item
//                               width={"100%"} // Width of the list
//                             >
//                               {({ index, style }) => (
//                                 <SelectItem
//                                   key={Timezones[index]}
//                                   value={Timezones[index]}
//                                   style={style}
//                                 >
//                                   {Timezones[index]}
//                                 </SelectItem>
//                               )}
//                             </List>
//                           </SelectGroup>
//                         </SelectContent>
//                       </Select>
//                     </div>

//                     <div className="col-span-2 grid w-full items-center gap-1.5">
//                       <Label className="text-black" htmlFor="d_off">
//                         Days off
//                       </Label>
//                       <Popover>
//                         <PopoverTrigger asChild>
//                           <Button variant="outline" className="">
//                             Select Days
//                           </Button>
//                         </PopoverTrigger>
//                         <PopoverContent className="">
//                           <div className="grid gap-4">
//                             <p className="text-sm text-black">
//                               Set the holidays.
//                             </p>
//                             <div className="grid gap-2">
//                               <div className="flex flex-col items-center gap-2">
//                                 {days.map((days) => (
//                                   <>
//                                     <div className="flex w-full items-start justify-start gap-x-4">
//                                       <Checkbox
//                                         id="terms"
//                                         checked={daysOff.includes(days)}
//                                         onCheckedChange={(checked) => {
//                                           return checked
//                                             ? setDaysOff([...daysOff, days])
//                                             : setDaysOff(
//                                                 daysOff.filter(
//                                                   (day) => day !== days,
//                                                 ),
//                                               );
//                                         }}
//                                       />
//                                       <Label
//                                         htmlFor="terms"
//                                         className="text-sm font-medium leading-none text-black peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
//                                       >
//                                         {days}
//                                       </Label>
//                                     </div>
//                                   </>
//                                 ))}
//                               </div>
//                             </div>
//                           </div>
//                         </PopoverContent>
//                       </Popover>
//                     </div>
//                   </div>

//                   <div className="grid grid-cols-4 items-center gap-4">
//                     <div className="col-span-2 grid w-full items-center gap-1.5">
//                       <Label className="text-black" htmlFor="at_st">
//                         Start Time
//                       </Label>
//                       <Input
//                         className=" text-black"
//                         type="time"
//                         id="at_st"
//                         step="1"
//                         value={appointmentsFrom}
//                         onChange={(event) => {
//                           setAppointmentsFrom(event.target.value);
//                         }}
//                       />
//                     </div>

//                     <div className="col-span-2 grid w-full items-center gap-1.5">
//                       <Label className="text-black" htmlFor="at_ed">
//                         End Time
//                       </Label>
//                       <Input
//                         className=" text-black"
//                         type="time"
//                         id="at_ed"
//                         step="1"
//                         value={appointmentsTo}
//                         onChange={(event) => {
//                           setAppointmentsTo(event.target.value);
//                         }}
//                       />
//                     </div>
//                   </div>
//                   <div className="grid grid-cols-4 items-center gap-4">
//                     <div className="col-span-2 grid w-full items-center gap-1.5">
//                       <Label className="text-black" htmlFor="duration">
//                         Duration
//                       </Label>
//                       <Input
//                         className=" text-black"
//                         type="number"
//                         id="duration"
//                         placeholder={0}
//                         value={duration}
//                         onChange={(event) => setDuration(event.target.value)}
//                       />
//                     </div>
//                     {manager !== "nitrobot" && (
//                       <>
//                         <div className="col-span-2 grid w-full items-center gap-1.5">
//                           <Label className="text-black" htmlFor="cal_id">
//                             Calender ID
//                           </Label>
//                           <Input
//                             className=" text-black"
//                             type="text"
//                             id="cal_id"
//                             placeholder="Calender ID"
//                             value={g_ID}
//                             onChange={(event) => setG_ID(event.target.value)}
//                           />
//                         </div>
//                       </>
//                     )}
//                   </div>
//                 </div>
//               </CardContent>
//               <CardFooter>
//                 <div className="flex w-full items-center justify-end">
//                   <Button
//                     onClick={handleSubmit}
//                     className=""
//                     disabled={loading}
//                   >
//                     {loading ? "Loading..." : "Save Changes"}
//                   </Button>
//                 </div>
//               </CardFooter>
//             </Card>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
