/* eslint-disable */
import { Link } from "react-router-dom";
export default function Error() {
  return (
    <main class="h-screen w-full flex flex-col justify-center items-center bg-[#000000]">
      <h1 class="text-5xl font-extrabold text-white tracking-widest">404</h1>
      <Link to="/">
        {" "}
        <button class="mt-4">
          <a class="relative inline-block text-sm font-medium text-[#ffffff] group active:text-[#ffffff] focus:outline-none focus:ring">
            <span class="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 group-hover:translate-y-0 group-hover:translate-x-0"></span>

            <span class="relative block px-6 py-2 bg-[#ffffff] border border-current text-black">
              Go Home
            </span>
          </a>
        </button>
      </Link>
    </main>
  );
}
