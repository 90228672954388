/* eslint-disable */
import React from "react";
import { useSelector } from "react-redux";
import { CodeBracketIcon } from "@heroicons/react/24/outline";
import { Button } from "../../Shadcn/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../../Shadcn/ui/dialog";
import { Share2 } from "lucide-react";
import { cn } from "../../lib/utils";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useToast } from "../../Shadcn/ui/use-toast";
import { Domain } from "../../Env";
import { Switch } from "../../Shadcn/ui/switch";
import { Label } from "../../Shadcn/ui/label";
import { Copy } from "lucide-react";
import axios from "axios";

export default function Share() {
  return (
    <>
      <div className="flex h-[250px] w-full flex-col justify-between rounded-2xl border bg-white p-6 pt-8 hover:shadow-lg">
        <div>
          <div className={`flex items-center justify-start`}>
            <CodeBracketIcon className="h-10 w-10 text-black" />
          </div>
          <h6 className="mb-3 mt-4 font-normal tracking-tight text-black sm:text-sm lg:text-xl">
            Embed Chatbot
          </h6>
          <p className="font-small lg:text-md tracking-tight text-gray-600 sm:text-sm">
            Embed the chatbot bubble on your website
          </p>
        </div>
        <CodeViewer />
      </div>
    </>
  );
}

export function CodeViewer({ isCollapsed, theme }) {
  const chatId = useSelector((state) => state.dtl.chat_id);
  const shared = useSelector((state) => state.dtl.shared);
  const [enableShared, setEnableShared] = useState(shared);
  const [disabled, setDisabled] = useState(false);
  const { toast } = useToast();

  const handleCopy = () => {
    toast({
      title: "Copied!",
      description: "Embed code copied to clipboard",
    });
    navigator.clipboard.writeText(
      `<script>
      window.embeddedChatbotConfig = { chatbotId: "${chatId}",};
      </script>
    <script src="https://cdn.nitrobot.io/static/js/embed.min.js" defer></script>
    `,
    );
  };

  const handleEnableShared = async (e) => {
    setEnableShared(e);
    setDisabled(true);
    const formData = new FormData();
    formData.append("public", e);
    formData.append("chat_id", chatId);
    try {
      const response = await axios.post(`${Domain}api/update-chat/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      });
      if (response.status === 200) {
        setDisabled(false);
      } else {
        setDisabled(false);
        setEnableShared(false);
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "Action failed, please try again",
        });
        // console.log(response.data);
      }
    } catch (error) {
      setDisabled(false);
      setEnableShared(false);
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "Action failed, please try again",
      });
    }
  };
  return (
    <Dialog>
      <DialogTrigger asChild>
        <div
          className={cn(
            "flex  w-full items-center ",
            isCollapsed ? "justify-center" : "px-2",
            theme === "dark" ? "px-0" : "",
          )}
        >
          <Button
            className={isCollapsed ? "" : "h-[36px] justify-start px-2 py-0 "}
            size={isCollapsed ? "icon" : ""}
            variant={theme === "dark" ? "default" : "ghost"}
            asChild
          >
            <div
              className={`flex cursor-pointer  items-center text-black ${isCollapsed ? "justify-center" : "w-full justify-start gap-x-2"}`}
            >
              <Share2 className="h-4 w-4 text-black" />
              {isCollapsed ? "" : "Embed Chatbot"}
            </div>
          </Button>
        </div>
      </DialogTrigger>
      <DialogContent className=" sm:max-w-[625px]">
        <DialogHeader>
          <DialogTitle className="text-black">Embed Chatbot</DialogTitle>
          <DialogDescription>
            You can use the following code to integrate your chatbot bubble into
            your application.
          </DialogDescription>
        </DialogHeader>
        <div className="flex w-full items-center justify-between rounded-md border p-4 text-black">
          <Label className="text-md flex" htmlFor="enableShared">
            Shared
          </Label>
          <Switch
            id="enableShared"
            checked={enableShared}
            disabled={disabled}
            onCheckedChange={() => handleEnableShared(!enableShared)}
          />
        </div>
        <div className="grid gap-4">
          <div className="rounded-md bg-black p-6">
            <pre>
              <code className="grid w-full gap-1 text-sm text-muted-foreground [&_span]:h-4 ">
                {enableShared ? (
                  <div className="mb-4 w-full overflow-scroll md:overflow-hidden">
                    &lt;
                    <span style={{ color: "#7ec699" }}>script</span>
                    &gt;
                    <br />
                    <span style={{ color: "#dcdcaa" }}>window</span>.
                    <span style={{ color: "#9cdcfe" }}>
                      embeddedChatbotConfig
                    </span>{" "}
                    = &#123;
                    <br />
                    <span style={{ color: "#9cdcfe" }}> chatbotId</span>
                    <span style={{ color: "#dcdcaa" }}>:</span>{" "}
                    <span style={{ color: "#ce9178" }}>"{chatId}"</span>,<br />
                    &#125;;
                    <br />
                    &lt;
                    <span style={{ color: "#7ec699" }}>/script</span>
                    &gt;
                    <br />
                    &lt;
                    <span style={{ color: "#7ec699" }}>script</span>
                    <span style={{ color: "#dcdcaa" }}> src</span>
                    <span style={{ color: "#ce9178" }}>
                      ="https://cdn.nitrobot.io/static/js/embed.min.js"
                    </span>
                    <span style={{ color: "#569cd6" }}> defer</span>
                    &gt;&lt;
                    <span style={{ color: "#7ec699" }}>/script</span>
                    &gt;
                    <br />
                  </div>
                ) : (
                  <>
                    {" "}
                    <p className="text-sm text-muted-foreground">
                      Your Chatbot is not shared yet.
                      <br />
                      Please share your chatbot to get the code.
                    </p>
                  </>
                )}
              </code>
            </pre>
          </div>
        </div>
        <div className="flex w-full items-center justify-center">
          {enableShared && (
            <Button
              type="button"
              className="text-md flex items-center gap-x-4 bg-black px-3 text-white"
              onClick={() => {
                handleCopy();
              }}
            >
              Copy
              <Copy className="h-4 w-4" />
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function Delete({ isCollapsed, theme }) {
  const chatId = useSelector((state) => state.dtl.chat_id);
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const authToken = localStorage.getItem("auth_token");
  const { toast } = useToast();

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${Domain}api/delete-chat/${chatId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        toast({
          title: "Success!",
          description: "Chatbot deleted successfully.",
        });
        window.location.href = "/chatbots";
      } else {
        throw new Error("Failed to delete chatbot");
      }
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "Chatbot could not be deleted. Please try again.",
      });
    } finally {
      setLoading(false);
      setIsDialogOpen(false); // Close dialog after operation
    }
  };

  return (
    <>
      <Dialog onOpenChange={setIsDialogOpen}>
        <DialogTrigger asChild>
          <div
            className={cn(
              "flex  w-full items-center ",
              isCollapsed ? "justify-center" : "px-2",
              theme === "dark" ? "px-0" : "",
            )}
          >
            <Button
              className={cn(
                isCollapsed
                  ? ""
                  : "group mt-1 h-[36px] justify-start px-2 py-0 hover:bg-red-600 hover:text-white",
                theme === "dark" ? "mt-0" : "",
              )}
              size={isCollapsed ? "icon" : ""}
              variant={theme === "dark" ? "default" : "ghost"}
              asChild
            >
              <div
                className={`flex cursor-pointer items-center text-red-500 ${isCollapsed ? "justify-center" : "w-full justify-start gap-x-2"}`}
              >
                <TrashIcon className="h-4 w-4 text-red-500 group-hover:text-white" />
                {isCollapsed ? "" : "Delete"}
              </div>
            </Button>
          </div>
        </DialogTrigger>
        <DialogContent className="h-[200px] p-6 sm:max-w-[450px]">
          <DialogHeader>
            <DialogTitle className="text-red-500">Delete Chatbot</DialogTitle>
            <DialogDescription>
              <h2 className="text-md mb-4 mt-2 text-black">
                Are you sure you want to delete this chatbot? This action cannot
                be undone.
              </h2>
            </DialogDescription>
          </DialogHeader>

          <div className="flex w-full justify-end gap-2">
            <DialogClose asChild>
              <Button
                type="button"
                variant="secondary"
                onClick={() => setIsDialogOpen(false)}
              >
                No, Take me Back
              </Button>
            </DialogClose>
            <Button
              type="button"
              className="bg-red-600 hover:bg-red-900"
              onClick={handleDelete}
              disabled={loading}
            >
              {loading ? "Deleting..." : "Yes, Delete"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
