import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Domain } from "../../Env";

const initialState = {
  live_chats: [],
};

const fetchList = createAsyncThunk("live/fetchList", async () => {
  const authToken = localStorage.getItem("auth_token");

  try {
    const response = await fetch(`${Domain}lc/chats/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch live chats");
    }

    const data = await response.json();
    return data.conversations;
  } catch (error) {
    // console.log(error);
    return false;
  }
});

export const liveSlice = createSlice({
  name: "live",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchList.pending, (state) => {})
      .addCase(fetchList.fulfilled, (state, action) => {
        if (action.payload === false) {
          return;
        } else {
          state.live_chats = action.payload;
        }
      })
      .addCase(fetchList.rejected, (state, action) => {
        // console.log(action);
      });
  },
});

export { fetchList };
export default liveSlice.reducer;
