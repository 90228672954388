/* eslint-disable */
import { useState, useEffect, React } from "react";
import { useSelector } from "react-redux";
import { SingleAppointmentCard } from "./SingleAppointmentCard";
import { apiDomain } from "../../Env";
import { Separator } from "../../Shadcn/ui/separator";

function ChatAppointments() {
  const [appointments, setAppointments] = useState([]);
  const authToken = localStorage.getItem("auth_token");
  const chatId = useSelector((state) => state.dtl.chat_id);

  useEffect(() => {
    if (chatId === null || chatId === undefined || chatId === "") {
      return;
    }
    const fetchChats = async () => {
      try {
        const response = await fetch(
          `${apiDomain}api/get-appointments/${chatId}/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          },
        );

        if (response.ok) {
          const data = await response.json();
          setAppointments(data.appointments);
        } else {
          setAppointments([]);
        }
      } catch (error) {
        setAppointments([]);
      }
    };

    fetchChats();
  }, [chatId]);

  return (
    <div className="flex h-[92vh] min-h-[92vh] w-full items-start justify-center px-4 py-4 sm:px-10 sm:py-6 lg:flex-none lg:px-8 xl:px-8">
      <div className="flex w-full flex-col items-start justify-center gap-x-4 md:p-3">
        {" "}
        <div className="flex w-full  flex-col items-center justify-start gap-y-3 md:flex-row md:justify-start md:gap-y-0">
          <div className="flex max-w-2xl flex-col items-start gap-y-2 text-black sm:text-left">
            <h4 className="text-xl font-bold tracking-tight text-black sm:text-4xl ">
              Booked Appointments
            </h4>
            <p className="font-small lg:text-md tracking-tight text-gray-700 sm:text-sm">
              Here's a list of all the appointments booked by the chatbot
            </p>
          </div>
        </div>{" "}
        <Separator orientation="horizontal" className="my-3 md:my-6" />
        <div className="mt-8 grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {appointments.length > 0 ? (
            appointments.map((appointment) => (
              <SingleAppointmentCard appointment={appointment} />
            ))
          ) : (
            <p className="text-black w-full text-center">No Appointments available.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatAppointments;
