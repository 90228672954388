/* eslint-disable */
import { useEffect, useState } from "react";
import { Input } from "../../Shadcn/ui/input";
import { Button } from "../../Shadcn/ui/button";
import { Switch } from "../../Shadcn/ui/switch";
import { Label } from "../../Shadcn/ui/label";
import { useSelector } from "react-redux";
import { useToast } from "../../Shadcn/ui/use-toast";
import { Separator } from "../../Shadcn/ui/separator";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../Shadcn/ui/select";
import { Domain } from "../../Env";
import axios from "axios";
import { fetchChatData } from "../../Redux/Chat/dtlSlice";
import { useDispatch } from "react-redux";

function ChatWoocommerce() {
  const dispatch = useDispatch();
  let wc_settings = useSelector((state) => state.dtl.woocommerce);
  let chat_id = useSelector((state) => state.dtl.chat_id);
  const [loading, setLoading] = useState(false);
  const [woocommerceEnabled, setWoocommerceEnabled] = useState(false);
  const [woocommerceStoreUrl, setWoocommerceStoreUrl] = useState("");
  const [woocommerceConsumerKey, setWoocommerceConsumerKey] = useState("");
  const [woocommerceConsumerSecret, setWoocommerceConsumerSecret] =
    useState("");

  // Features
  const [productSearch, setProductSearch] = useState(false);
  const [orderCollection, setorderCollection] = useState(false);
  const [orderStatus, setorderStatus] = useState(false);
  const [discountCoupons, setDiscountCoupons] = useState(false);
  // Discount Settings
  const [discountType, setDiscountType] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [individualUse, setIndividualUse] = useState(false);
  const [excludeSaleItems, setExcludeSaleItems] = useState(false);
  const [minimumAmount, setMinimumAmount] = useState(0);

  const { toast } = useToast();

  // Update the state when the redux state changes
  useEffect(() => {
    if (wc_settings) {
    //  // console.log(wc_settings);
      setWoocommerceEnabled(wc_settings.woocommerce_enabled || false);
      setWoocommerceStoreUrl(wc_settings.woocommerce_store_url || "");
      setWoocommerceConsumerKey(wc_settings.woocommerce_consumer_key || "");
      setWoocommerceConsumerSecret(
        wc_settings.woocommerce_consumer_secret || "",
      );
      // Features
      if (wc_settings.woocommerce_features) {
        setProductSearch(
          wc_settings.woocommerce_features.wc_product_search || false,
        );
        setorderCollection(
          wc_settings.woocommerce_features.wc_collect_orders || false,
        );
        setorderStatus(
          wc_settings.woocommerce_features.wc_order_status || false,
        );
        setDiscountCoupons(
          wc_settings.woocommerce_features.wc_discount_coupons || false,
        );
        // Discount Settings
        if (wc_settings.woocommerce_features.discount_settings) {
          setDiscountType(
            wc_settings.woocommerce_features.discount_settings.discount_type ||
              "",
          );
          setDiscountAmount(
            wc_settings.woocommerce_features.discount_settings
              .discount_amount || 0,
          );
          setIndividualUse(
            wc_settings.woocommerce_features.discount_settings.individual_use ||
              false,
          );
          setExcludeSaleItems(
            wc_settings.woocommerce_features.discount_settings
              .exclude_sale_items || false,
          );
          setMinimumAmount(
            wc_settings.woocommerce_features.discount_settings.minimum_amount ||
              0,
          );
        }
      }
    }
  }, [wc_settings]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("chat_id", chat_id);
    formData.append("woocommerce_enabled", woocommerceEnabled);
    formData.append("woocommerce_store_url", woocommerceStoreUrl);
    formData.append("woocommerce_consumer_key", woocommerceConsumerKey);
    formData.append("woocommerce_consumer_secret", woocommerceConsumerSecret);
    formData.append("wc_product_search", productSearch);
    formData.append("wc_collect_orders", orderCollection);
    formData.append("wc_order_status", orderStatus);
    formData.append("wc_discount_coupons", discountCoupons);
    formData.append("discount_type", discountType);
    formData.append("discount_amount", discountAmount);
    formData.append("individual_use", individualUse);
    formData.append("exclude_sale_items", excludeSaleItems);
    formData.append("minimum_amount", minimumAmount);

    const response = await axios.post(
      `${Domain}wc/update-chat-settings/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      },
    );

    if (response.status === 200) {
      dispatch(fetchChatData(chat_id));
      toast({
        variant: "success",
        title: "Settings updated successfully.",
        description: "The settings have been updated successfully.",
      });
      setLoading(false);
      return;
    } else {
      toast({
        variant: "destructive",
        title: "Failed to update settings.",
        description:
          "There was an error updating the settings. Please reload the page and try again.",
      });
      setLoading(false);
      return;
    }
  };

  return (
    <>
      <div className="flex h-[92vh] min-h-[92vh] w-full items-start justify-center px-4 py-4 text-black sm:px-10 sm:py-6 lg:flex-none lg:px-8 xl:px-8">
        <div className="flex w-full flex-col items-start justify-center gap-x-4 md:p-3">
          {" "}
          <div className="flex w-full  flex-col items-center justify-start gap-y-3 md:flex-row md:justify-start md:gap-y-0">
            <div className="flex w-full items-center justify-between text-black sm:text-left">
              <div className="flex flex-col gap-y-2">
                <span className="inline-flex w-fit items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                  Experimental
                </span>
                <h4 className="text-xl font-bold tracking-tight text-black sm:text-4xl ">
                  Woocommerce Settings
                </h4>
                <p className="font-small lg:text-md tracking-tight text-gray-700 sm:text-sm">
                  Configure your Woocommerce settings here.
                </p>
                <p className="text-xs text-gray-500">
                  <span className="font text-xs font-semibold text-black mr-2">
                    Note:
                  </span>
                  This feature works best with GPT 4 Turbo model
                </p>
              </div>
              <div className="flex items-center gap-x-4">
                <Label
                  className="text-md hidden font-semibold md:flex"
                  htmlFor="woocommerceEnabled"
                >
                  Enable Woocommerce
                </Label>
                <Switch
                  id="woocommerceEnabled"
                  checked={woocommerceEnabled}
                  onCheckedChange={() =>
                    setWoocommerceEnabled(!woocommerceEnabled)
                  }
                />
              </div>
            </div>
          </div>
          <Separator orientation="horizontal" className="my-3 md:my-4" />
          <form
            onSubmit={handleSubmit}
            className="w-full space-y-4 overflow-y-scroll"
          >
            {woocommerceEnabled && (
              <>
                <div className="space-y-3 rounded-md border-2 p-4">
                  <h1 className="text-lg font-bold text-black">
                    Enabled Features
                  </h1>
                  <div className="grid grid-cols-1 items-center gap-4 md:flex md:justify-start">
                    <div className="flex w-full items-center justify-between gap-4 rounded-md border bg-white p-3 shadow-sm md:w-[150px] md:flex-col md:items-start">
                      <Label htmlFor="productSearch">Product Search</Label>
                      <Switch
                        id="productSearch"
                        checked={productSearch}
                        onCheckedChange={() => setProductSearch(!productSearch)}
                      />
                    </div>
                    <div className="flex w-full items-center justify-between gap-4 rounded-md border bg-white p-3 shadow-sm md:w-[150px] md:flex-col md:items-start">
                      <Label htmlFor="orderCollection">Collect Orders</Label>
                      <Switch
                        id="orderCollection"
                        checked={orderCollection}
                        onCheckedChange={() =>
                          setorderCollection(!orderCollection)
                        }
                      />
                    </div>
                    <div className="flex w-full items-center justify-between gap-4 rounded-md border bg-white p-3 shadow-sm md:w-[150px] md:flex-col md:items-start">
                      <Label htmlFor="orderStatus">Order Status</Label>
                      <Switch
                        id="orderStatus"
                        checked={orderStatus}
                        onCheckedChange={() => setorderStatus(!orderStatus)}
                      />
                    </div>
                    <div className="flex w-full items-center justify-between gap-4 rounded-md border bg-white p-3 shadow-sm md:w-[150px] md:flex-col md:items-start">
                      <Label htmlFor="discountCoupons">Discount Coupons</Label>
                      <Switch
                        id="discountCoupons"
                        checked={discountCoupons}
                        onCheckedChange={() =>
                          setDiscountCoupons(!discountCoupons)
                        }
                      />
                    </div>
                  </div>

                  {discountCoupons && (
                    <>
                      <h1 className="text-md font-medium text-black">
                        Discount Settings
                      </h1>
                      <div className="flex flex-col items-start gap-4">
                        <div className="w-full">
                          <Label htmlFor="discountType">Discount Type</Label>
                          <Select
                            className=""
                            value={discountType}
                            onValueChange={setDiscountType}
                            defaultValue="fixed_cart"
                            id="discountType"
                          >
                            <SelectTrigger className="">
                              <SelectValue placeholder="Discount Type" />
                            </SelectTrigger>
                            <SelectContent className="">
                              <SelectGroup>
                                <SelectItem value="fixed_cart">
                                  Fixed Cart
                                </SelectItem>
                                <SelectItem value="percent">Percent</SelectItem>
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="w-full">
                          <Label htmlFor="discountAmount">
                            Discount Amount
                          </Label>
                          <Input
                            value={discountAmount}
                            onChange={(e) => {
                              setDiscountAmount(e.target.value);
                            }}
                            id="discountAmount"
                            placeholder="Discount Amount"
                            required
                          />
                        </div>
                        <div className="w-full">
                          <Label htmlFor="minimumAmount">Minimum Amount</Label>
                          <Input
                            value={minimumAmount}
                            onChange={(e) => {
                              setMinimumAmount(e.target.value);
                            }}
                            id="minimumAmount"
                            placeholder="Minimum Amount"
                            required
                          />
                        </div>
                        <div className="flex gap-4">
                          <div className="flex w-[150px] flex-col items-start gap-4 rounded-md border bg-white p-3 shadow-sm">
                            <Label htmlFor="individualUse">
                              Individual Use
                            </Label>
                            <Switch
                              id="individualUse"
                              checked={individualUse}
                              onCheckedChange={() =>
                                setIndividualUse(!individualUse)
                              }
                            />
                          </div>
                          <div className="flex w-[150px] flex-col items-start gap-4 rounded-md border bg-white p-3 shadow-sm">
                            <Label htmlFor="excludeSaleItems">
                              Exclude Sale Items
                            </Label>
                            <Switch
                              id="excludeSaleItems"
                              checked={excludeSaleItems}
                              onCheckedChange={() =>
                                setExcludeSaleItems(!excludeSaleItems)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="space-y-3 rounded-md border-2 p-4">
                  <h1 className="text-lg font-bold text-black">API Keys</h1>
                  <p className="text-sm text-black">
                    To enable Woocommerce, you need to provide your Woocommerce
                    Store's URL, Consumer Key and Consumer Secret.
                  </p>
                  <div className="w-full">
                    <Label htmlFor="store-url">Store URL</Label>
                    <Input
                      value={woocommerceStoreUrl}
                      onChange={(e) => {
                        setWoocommerceStoreUrl(e.target.value);
                      }}
                      id="store-url"
                      placeholder="Store URL"
                      required
                    />
                  </div>
                  <div className="w-full">
                    <Label htmlFor="consumer-key">Consumer Key</Label>
                    <Input
                      value={woocommerceConsumerKey}
                      onChange={(e) => {
                        setWoocommerceConsumerKey(e.target.value);
                      }}
                      id="consumer-key"
                      placeholder="Consumer Key"
                      required
                    />
                  </div>
                  <div className="w-full">
                    <Label htmlFor="consumer-secret">Consumer Secret</Label>
                    <Input
                      value={woocommerceConsumerSecret}
                      onChange={(e) => {
                        setWoocommerceConsumerSecret(e.target.value);
                      }}
                      id="consumer-secret"
                      placeholder="Consumer Secret"
                      required
                    />
                  </div>
                </div>
              </>
            )}
            <div className="item flex w-full justify-end">
              <Button disabled={loading} type="submit">
                {loading && (
                  <>
                    <div
                      className="mr-2 inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    ></div>
                    <span>Saving</span>
                  </>
                )}
                {!loading && "Save Settings"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ChatWoocommerce;
