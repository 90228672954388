/* eslint-disable */
import React from "react";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { apiDomain } from "../../Env";

// GOOGLE_OAUTH2_CLIENT_ID="549520510636-k72h1nndacopobn4vd2qs0n9pe163dtg.apps.googleusercontent.com"
// GOOGLE_OAUTH2_CLIENT_SECRET="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"
// GOOGLE_OAUTH2_CALLBACK="http://localhost:8000/api/v1/auth/login/google/"
// [5:07 AM]
// https://app.nitrobot.io/api/v1/auth/login/google/

const GoogleLoginButton = ({ onClick }) => (
  <button
    onClick={onClick}
    type="button"
    className="transition-smooth flex w-full appearance-none items-center justify-center rounded-lg border border-gray-500 bg-black px-3 py-3 leading-tight text-gray-700 shadow hover:bg-gray-800 hover:text-gray-800 focus:outline-none"
  >
    <FcGoogle className="h-6 w-6 fill-current text-gray-700" />
  </button>
);

const FacebookLoginButton = ({ onClick }) => (
  <button
    onClick={onClick}
    type="button"
    className="transition-smooth flex w-full appearance-none items-center justify-center rounded-lg border border-gray-500 bg-black px-3 py-3 leading-tight text-gray-700 shadow hover:bg-gray-800 hover:text-gray-800 focus:outline-none"
  >
    <FaFacebookF className="h-6 w-6 fill-current text-[#4267B2]" />
  </button>
);

export const onGoogleLoginSuccess = () => {
  const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
  const redirectUri = "api/v1/auth/login/google/";
  const scope = [
    "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/userinfo.profile",
  ].join(" ");

  const params = {
    response_type: "code",
    client_id:
      "549520510636-k72h1nndacopobn4vd2qs0n9pe163dtg.apps.googleusercontent.com",
    redirect_uri: `https://app.nitrobot.io/api/v1/auth/login/google/`,
    prompt: "select_account",
    access_type: "offline",
    scope,
  };

  const urlParams = new URLSearchParams(params).toString();
  window.location = `${googleAuthUrl}?${urlParams}`;
};

const Auth = () => {
  const onFacebookLoginSuccess = () => {
    const facebookAuthUrl = "https://www.facebook.com/v18.0/dialog/oauth";
    const redirectUri = "api/v1/auth/login/facebook/";

    const params = {
      app_id: "1304124130470871",
      redirect_uri: `${apiDomain}/${redirectUri}`,
      state: "some_string",
      config_id: "976018386837946",
    };

    const urlParams = new URLSearchParams(params).toString();
    window.location = `${facebookAuthUrl}?${urlParams}`;
  };

  return (
    <div className="mt-2 flex w-full items-center justify-between">
      <div className="mx-2 w-full border-t border-gray-400 px-3 pt-4 md:w-full">
        <GoogleLoginButton onClick={onGoogleLoginSuccess} />
      </div>
      {/* <div className="w-full md:w-1/3 px-3 pt-4 mx-2 border-t border-gray-400">
        <FacebookLoginButton onClick={onFacebookLoginSuccess} />
      </div> */}
    </div>
  );
};

export default Auth;
