/* eslint-disable */
import { useEffect, useState } from "react";
import { Input } from "../../Shadcn/ui/input";
import { Button } from "../../Shadcn/ui/button";
import { Switch } from "../../Shadcn/ui/switch";
import { Label } from "../../Shadcn/ui/label";
import { useSelector } from "react-redux";
import { useToast } from "../../Shadcn/ui/use-toast";
import { Separator } from "../../Shadcn/ui/separator";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../Shadcn/ui/select";
import UpgradeDialog from "./UpgradeDialog";

export function ChatWoocommerce() {
  const [woocommerceEnabled, setWoocommerceEnabled] = useState(false);
  const [woocommerceStoreUrl, setWoocommerceStoreUrl] = useState("");
  const [woocommerceConsumerKey, setWoocommerceConsumerKey] = useState("");
  const [woocommerceConsumerSecret, setWoocommerceConsumerSecret] =
    useState("");

  // Features
  const [productSearch, setProductSearch] = useState(false);
  const [orderCollection, setorderCollection] = useState(false);
  const [orderStatus, setorderStatus] = useState(false);
  const [discountCoupons, setDiscountCoupons] = useState(false);
  // Discount Settings
  const [discountType, setDiscountType] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [individualUse, setIndividualUse] = useState(false);
  const [excludeSaleItems, setExcludeSaleItems] = useState(false);
  const [minimumAmount, setMinimumAmount] = useState(0);

  // Update the state when the redux state changes
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="flex h-[92vh] min-h-[92vh] w-full items-start justify-center px-4 py-4 text-black sm:px-10 sm:py-6 lg:flex-none lg:px-8 xl:px-8">
        <div className="flex w-full flex-col items-start justify-center gap-x-4 md:p-3">
          {" "}
          <div className="flex w-full  flex-col items-center justify-start gap-y-3 md:flex-row md:justify-start md:gap-y-0">
            <div className="flex w-full items-center justify-between text-black sm:text-left">
              <div className="flex flex-col gap-y-2 max-w-3xl">
                <span className="inline-flex w-fit items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                  Experimental
                </span>
                <h4 className="flex items-center gap-4 text-xl font-bold tracking-tight text-black sm:text-4xl md:flex-row ">
                  Woocommerce Settings
                  <span class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-center text-xs font-medium uppercase text-purple-700 ring-1 ring-inset ring-purple-700/10">
                    Upgrade Plan
                  </span>
                </h4>
                <p className="font-small lg:text-md tracking-tight text-gray-700 sm:text-sm">
                  Here's a demo of all the configurable settings for the
                  Woocommerce Integration. This feature is not included in your
                  current plan. Please upgrade your account to access this
                  feature.
                </p>
              </div>
              <div className="flex items-center gap-x-4">
                <Label
                  className="text-md hidden font-semibold md:flex"
                  htmlFor="woocommerceEnabled"
                >
                  Enable
                </Label>
                <Switch
                  id="woocommerceEnabled"
                  checked={woocommerceEnabled}
                  onCheckedChange={() =>
                    setWoocommerceEnabled(!woocommerceEnabled)
                  }
                />
              </div>
            </div>
          </div>
          <Separator orientation="horizontal" className="my-3 md:my-4" />
          <form className="w-full space-y-4 overflow-y-scroll">
            {woocommerceEnabled && (
              <>
                <div className="space-y-3 rounded-md border-2 p-4">
                  <h1 className="text-lg font-bold text-black">
                    Enabled Features
                  </h1>
                  <div className="grid grid-cols-1 items-center gap-4 md:flex md:justify-start">
                    <div className="flex w-full items-center justify-between gap-4 rounded-md border bg-white p-3 shadow-sm md:w-[150px] md:flex-col md:items-start">
                      <Label htmlFor="productSearch">Product Search</Label>
                      <Switch
                        id="productSearch"
                        checked={productSearch}
                        onCheckedChange={() => setProductSearch(!productSearch)}
                      />
                    </div>
                    <div className="flex w-full items-center justify-between gap-4 rounded-md border bg-white p-3 shadow-sm md:w-[150px] md:flex-col md:items-start">
                      <Label htmlFor="orderCollection">Collect Orders</Label>
                      <Switch
                        id="orderCollection"
                        checked={orderCollection}
                        onCheckedChange={() =>
                          setorderCollection(!orderCollection)
                        }
                      />
                    </div>
                    <div className="flex w-full items-center justify-between gap-4 rounded-md border bg-white p-3 shadow-sm md:w-[150px] md:flex-col md:items-start">
                      <Label htmlFor="orderStatus">Order Status</Label>
                      <Switch
                        id="orderStatus"
                        checked={orderStatus}
                        onCheckedChange={() => setorderStatus(!orderStatus)}
                      />
                    </div>
                    <div className="flex w-full items-center justify-between gap-4 rounded-md border bg-white p-3 shadow-sm md:w-[150px] md:flex-col md:items-start">
                      <Label htmlFor="discountCoupons">Discount Coupons</Label>
                      <Switch
                        id="discountCoupons"
                        checked={discountCoupons}
                        onCheckedChange={() =>
                          setDiscountCoupons(!discountCoupons)
                        }
                      />
                    </div>
                  </div>

                  {discountCoupons && (
                    <>
                      <h1 className="text-md font-medium text-black">
                        Discount Settings
                      </h1>
                      <div className="flex flex-col items-start gap-4">
                        <div className="w-full">
                          <Label htmlFor="discountType">Discount Type</Label>
                          <Select
                            className=""
                            value={discountType}
                            onValueChange={setDiscountType}
                            defaultValue="fixed_cart"
                            id="discountType"
                          >
                            <SelectTrigger className="">
                              <SelectValue placeholder="Discount Type" />
                            </SelectTrigger>
                            <SelectContent className="">
                              <SelectGroup>
                                <SelectItem value="fixed_cart">
                                  Fixed Cart
                                </SelectItem>
                                <SelectItem value="percent">Percent</SelectItem>
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="w-full">
                          <Label htmlFor="discountAmount">
                            Discount Amount
                          </Label>
                          <Input
                            value={discountAmount}
                            onChange={(e) => {
                              setDiscountAmount(e.target.value);
                            }}
                            id="discountAmount"
                            placeholder="Discount Amount"
                            required
                          />
                        </div>
                        <div className="w-full">
                          <Label htmlFor="minimumAmount">Minimum Amount</Label>
                          <Input
                            value={minimumAmount}
                            onChange={(e) => {
                              setMinimumAmount(e.target.value);
                            }}
                            id="minimumAmount"
                            placeholder="Minimum Amount"
                            required
                          />
                        </div>
                        <div className="flex gap-4">
                          <div className="flex w-[150px] flex-col items-start gap-4 rounded-md border bg-white p-3 shadow-sm">
                            <Label htmlFor="individualUse">
                              Individual Use
                            </Label>
                            <Switch
                              id="individualUse"
                              checked={individualUse}
                              onCheckedChange={() =>
                                setIndividualUse(!individualUse)
                              }
                            />
                          </div>
                          <div className="flex w-[150px] flex-col items-start gap-4 rounded-md border bg-white p-3 shadow-sm">
                            <Label htmlFor="excludeSaleItems">
                              Exclude Sale Items
                            </Label>
                            <Switch
                              id="excludeSaleItems"
                              checked={excludeSaleItems}
                              onCheckedChange={() =>
                                setExcludeSaleItems(!excludeSaleItems)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="space-y-3 rounded-md border-2 p-4">
                  <h1 className="text-lg font-bold text-black">API Keys</h1>
                  <p className="text-sm text-black">
                    To enable Woocommerce, you need to provide your Woocommerce
                    Store's URL, Consumer Key and Consumer Secret.
                  </p>
                  <div className="w-full">
                    <Label htmlFor="store-url">Store URL</Label>
                    <Input
                      value={woocommerceStoreUrl}
                      onChange={(e) => {
                        setWoocommerceStoreUrl(e.target.value);
                      }}
                      id="store-url"
                      placeholder="Store URL"
                      required
                    />
                  </div>
                  <div className="w-full">
                    <Label htmlFor="consumer-key">Consumer Key</Label>
                    <Input
                      value={woocommerceConsumerKey}
                      onChange={(e) => {
                        setWoocommerceConsumerKey(e.target.value);
                      }}
                      id="consumer-key"
                      placeholder="Consumer Key"
                      required
                    />
                  </div>
                  <div className="w-full">
                    <Label htmlFor="consumer-secret">Consumer Secret</Label>
                    <Input
                      value={woocommerceConsumerSecret}
                      onChange={(e) => {
                        setWoocommerceConsumerSecret(e.target.value);
                      }}
                      id="consumer-secret"
                      placeholder="Consumer Secret"
                      required
                    />
                  </div>
                </div>
              </>
            )}
            <div className="item flex w-full justify-end">
              <Button
                type="button"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Save Settings
              </Button>
            </div>
          </form>
        </div>
        <UpgradeDialog open={open} setOpen={setOpen} />
      </div>
    </>
  );
}
