/* eslint-disable */
import * as React from "react";
import { useEffect, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Switch } from "../../Shadcn/ui/switch";
import { ArrowUpDown, ChevronDown, MoreHorizontal } from "lucide-react";
import Papa from "papaparse";
import { Button } from "../../Shadcn/ui/button";
import { Checkbox } from "../../Shadcn/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../Shadcn/ui/dropdown-menu";
import { Input } from "../../Shadcn/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../Shadcn/ui/table";
import { Separator } from "../../Shadcn/ui/separator";
import { Label } from "../../Shadcn/ui/label";
import UpgradeDialog from "./UpgradeDialog";
//---------------------------------------------------------------

export const columns = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },

  {
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          className=""
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      return (
        <div className="px-4 text-left font-medium text-black">
          {row.getValue("name")}
        </div>
      );
    },
  },

  {
    accessorKey: "email",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Email
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="px-4 lowercase text-black">{row.getValue("email")}</div>
    ),
  },

  {
    accessorKey: "phone",
    header: () => <div className="text-left">Phone</div>,
    cell: ({ row }) => {
      // Format the amount as a dollar amount

      return (
        <div className="text-left font-medium text-black">
          {row.getValue("phone")}
        </div>
      );
    },
  },

  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => {
      const payment = row.original;

      return (
        <DropdownMenu className="">
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className=" h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="" align="start">
            <DropdownMenuLabel className="">Actions</DropdownMenuLabel>{" "}
            <DropdownMenuSeparator className="" />
            <DropdownMenuItem
              classname=""
              onClick={() => navigator.clipboard.writeText(payment.email)}
            >
              Copy Email
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];
//---------------------------------------------------------------

export function GetLeads() {
  const [sorting, setSorting] = React.useState([]);
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [rowSelection, setRowSelection] = React.useState({});
  const [leads, setLeads] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([
    {
      name: "John Doe",
      email: "john,doe@mail.com",
      phone: "+13471234567",
      chat_id: "",
      date: "",
      lead_id: "",
      conversation_id_id: "",
    },
  ]);

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  // Use this function to export the data via CSV

  const exportData = (data, fileName, type) => {
    // Wrap data in an array
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a); // Append the anchor to the body to ensure compatibility with certain browsers
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a); // Clean up by removing the anchor from the body
  };

  const handleDownload = () => {
    const csvData = Papa.unparse(data);
    exportData(csvData, "Leads.csv", "text/csv;charset=utf-8;");
  };

  // ---------------------------------------------

  const [Open, setOpen] = useState(false);
  const handleLeadsChange = async (e) => {
    setLeads(e);
    setOpen(true);
    setLeads(false);
  };

  return (
    <>
      <div className="flex h-[92vh] min-h-[92vh] w-full items-start justify-center px-4 py-4 sm:px-10 sm:py-6 lg:flex-none lg:px-8 xl:px-8">
        <div className="flex w-full flex-col items-start justify-center gap-x-4 md:p-3">
          {" "}
          <div className="flex w-full  flex-col items-center justify-start gap-y-3 md:flex-row md:justify-start md:gap-y-0">
            <div className="flex w-full items-center justify-between text-black sm:text-left">
              <div className="flex flex-col gap-y-2">
                <h4 className="text-xl font-bold tracking-tight text-black sm:text-4xl flex items-center gap-x-4 ">
                  Collected Leads
                  <span class="inline-flex items-center rounded-md h-fit bg-purple-50 px-2 py-1 text-center text-xs font-medium uppercase text-purple-700 ring-1 ring-inset ring-purple-700/10">
                    Upgrade Plan
                  </span>
                </h4>
                <p className="font-small lg:text-md tracking-tight text-gray-700 sm:text-sm">
                  Here's the list of contact details of all the users who have
                  interacted with the chatbot.
                </p>
              </div>
              <div className="flex items-center gap-x-4">
                <Label
                  className="text-md hidden font-semibold md:flex"
                  htmlFor="col_app"
                >
                  Collect leads
                </Label>
                <Switch
                  checked={leads}
                  value={leads}
                  onClick={(e) => handleLeadsChange(!leads)}
                  id="col_app"
                />
              </div>
            </div>
          </div>{" "}
          <Separator orientation="horizontal" className="my-3 md:my-4" />
          <div className="mx-auto w-[100%]">
            <div className="flex items-center justify-between py-4">
              <Input
                placeholder="Filter names..."
                value={table.getColumn("name")?.getFilterValue() ?? ""}
                onChange={(event) =>
                  table.getColumn("name")?.setFilterValue(event.target.value)
                }
                className="max-w-sm "
              />

              <div className="flex gap-x-4">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="outline" className="ml-auto  text-black">
                      Columns <ChevronDown className="ml-2 h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="" align="end">
                    {table
                      .getAllColumns()
                      .filter((column) => column.getCanHide())
                      .map((column) => {
                        return (
                          <DropdownMenuCheckboxItem
                            key={column.id}
                            className="capitalize "
                            checked={column.getIsVisible()}
                            onCheckedChange={(value) =>
                              column.toggleVisibility(!!value)
                            }
                          >
                            {column.id}
                          </DropdownMenuCheckboxItem>
                        );
                      })}
                  </DropdownMenuContent>
                </DropdownMenu>

                <Button
                  onClick={() => {
                    handleDownload();
                  }}
                  variant="outline"
                  className="ml-auto  text-black"
                >
                  Download CSV
                </Button>
              </div>
            </div>
            <div className="rounded-md border bg-white shadow-md ">
              <Table classname="bg-white">
                <TableHeader classname="">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow classname="" key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <TableHead classname="" key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                          </TableHead>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody classname="">
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow
                        key={row.id}
                        classname=" text-black"
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell classname=" text-black" key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow classname="">
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center text-black "
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <div className="flex items-center justify-end space-x-2 py-4">
              <div className="flex-1 text-sm text-muted-foreground ">
                {table.getFilteredSelectedRowModel().rows.length} of{" "}
                {table.getFilteredRowModel().rows.length} row(s) selected.
              </div>
              <div className="space-x-2">
                <Button
                  variant="outline"
                  size="sm"
                  classname=""
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  Previous
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  classname=""
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
          <UpgradeDialog open={Open} setOpen={setOpen} />
        </div>
      </div>
    </>
  );
}
