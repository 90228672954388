import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "../../Shadcn/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../Shadcn/ui/card";
import { Label } from "../../Shadcn/ui/label";
import { Textarea } from "../../Shadcn/ui/textarea";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../Shadcn/ui/select";
import { Separator } from "../../Shadcn/ui/separator";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../Shadcn/ui/hover-card";
import { Slider } from "../../Shadcn/ui/slider";
import { useToast } from "../../Shadcn/ui/use-toast";
import axios from "axios";
import { Domain } from "../../Env";
import { ComboboxDemo } from "./CreateFunction";
import { fetchChatData } from "../../Redux/Chat/dtlSlice";
import { useDispatch } from "react-redux";

export default function EditFunction() {
  const chat_ID = useSelector((state) => state.dtl.chat_id);
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const dispatch = useDispatch();

  // Chat Create Form

  const [sysmsg, setSysmsg] = useState("");
  const [chatModel, setChatModel] = useState("");
  const [temperature, setTemperature] = useState(0);
  const State = useSelector((state) => state.dtl);
  const [OpenAiKey, setOpenAiKey] = useState("");

  // --------------------------------------------------------

  useEffect(() => {
    setTemperature(State.temperature || 0);
    setSysmsg(State.sys_message);
    setChatModel(State.chat_model);
    setOpenAiKey(State.api_key);
  }, [State]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!chatModel) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "Please fill all the fields",
      });
      return;
    }

    setLoading(true);

    const formData = new FormData();

    formData.append("chat_model", chatModel);
    formData.append("temperature", temperature);
    formData.append("sys_message", sysmsg);
    formData.append("chat_id", chat_ID);
    formData.append("openai_key", OpenAiKey);
    try {
      const response = await axios.post(`${Domain}api/update-chat/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      });

      //  // console.log(response);

      if (response.status === 200) {
        setLoading(false);
        dispatch(fetchChatData(chat_ID));
        toast({
          title: "Success",
          description: "Chat Updated Successfully",
        });

        // console.log(response.data);
      } else {
        setLoading(false);
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "Chat Update Failed",
        });
        // console.log(response.data);
      }
    } catch (error) {
      setLoading(false);
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "Chat Update Failed",
      });
    }
  };

  return (
    <>
      <div className="justify-centerpy-4 flex h-[92vh] min-h-[92vh] w-full flex-col items-start md:flex-row md:items-center">
        {" "}
        <div className="mx-auto grid w-[95%] grid-cols-2 gap-4 p-2 md:h-[85vh] md:grid-cols-4 md:p-1">
          <div className="col-span-4 flex w-full flex-col items-center justify-start text-white md:col-span-4 md:h-[85vh] ">
            <Card className="flex h-full w-full flex-col">
              <CardHeader className="flex w-full flex-col">
                <CardTitle>AI Settings</CardTitle>
                <CardDescription>
                  Craft your chatbot response settings to your liking
                </CardDescription>
              </CardHeader>
              <div className="h-[1px] w-full px-6">
                <Separator />
                <Separator />
              </div>
              <CardContent className="mt-4 w-full grow space-y-2 ">
                <div className="space-y-1 ">
                  <Label htmlFor="sysmsg">System Message</Label>
                  <Textarea
                    id="sysmsg"
                    value={sysmsg}
                    rows={6}
                    className="w-full resize-none overflow-hidden "
                    onChange={(event) => setSysmsg(event.target.value)}
                    placeholder="Type your message here."
                  />
                </div>
                <div className="flex items-center justify-between gap-x-4 space-y-1 ">
                  <div className="w-full">
                    <Label htmlFor="name">Model</Label>
                    <Select
                      className=""
                      value={chatModel}
                      onValueChange={setChatModel}
                      defaultValue="gpt-3.5-turbo-0125"
                    >
                      <SelectTrigger className="">
                        <SelectValue placeholder="GPT Model" />
                      </SelectTrigger>
                      <SelectContent className="">
                        <SelectGroup>
                          <SelectLabel>Model</SelectLabel>
                          <SelectItem value="gpt-3.5-turbo-0125">
                            GPT 3
                          </SelectItem>
                          <SelectItem value="gpt-4-0125-preview">
                            GPT 4
                          </SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="w-full">
                    <Label htmlFor="api-key">API Key</Label>
                    <ComboboxDemo
                      setOpenAiKey={setOpenAiKey}
                      key_value={State.api_key}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="grid gap-2 py-2 pb-3">
                    <HoverCard openDelay={200}>
                      <HoverCardTrigger asChild>
                        <div className="grid gap-4">
                          <div className="flex items-center justify-between">
                            <Label htmlFor="temperature">Temperature</Label>
                            <span className="w-12 rounded-md border border-transparent px-2 py-0.5 text-right text-sm text-muted-foreground hover:border-border">
                              {temperature}
                            </span>
                          </div>
                          <Slider
                            id="temperature"
                            max={1}
                            defaultValue={[temperature]}
                            step={0.1}
                            onValueChange={(values) =>
                              setTemperature(values[0])
                            }
                            className="[&_[role=slider]]:h-4 [&_[role=slider]]:w-4"
                            aria-label="Temperature"
                          />
                        </div>
                      </HoverCardTrigger>
                      <HoverCardContent
                        align="start"
                        className="w-[260px] text-sm"
                        side="left"
                      >
                        Controls randomness: lowering results in less random
                        completions. As the temperature approaches zero, the
                        model will become deterministic and repetitive.
                      </HoverCardContent>
                    </HoverCard>
                  </div>
                </div>
              </CardContent>
              <CardFooter>
                <div className="flex w-full items-center justify-end">
                  <Button
                    onClick={handleSubmit}
                    className=""
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Update Chat"}
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
