import { SiMessenger } from "react-icons/si";
import { SiWhatsapp } from "react-icons/si";
import { SiTwilio } from "react-icons/si";
import { Button } from "../../Shadcn/ui/button";
import { useNavigate } from "react-router-dom";

const FacebookPaywall = () => {
  const navigate = useNavigate();
  const handleUpgrade = () => {
    navigate("/pricing");
  };
  return (
    <div className="border-stone-600 transition-smooth flex h-[200px] w-full flex-col justify-between rounded-xl border bg-white p-6 pt-8 duration-100 ease-in-out hover:shadow-md">
      <div>
        <div className={`flex items-center justify-start`}>
          <SiMessenger className="h-10 w-10 text-black" />
        </div>
        <h6 className="mt-4 font-normal tracking-tight text-[#22281E] sm:text-sm lg:text-xl">
          Facebook Messenger
        </h6>
      </div>
      <div className={`w-full`}>
        <Button onClick={() => handleUpgrade()} className="w-full">
          Upgrade to Connect
        </Button>{" "}
      </div>
    </div>
  );
};

const WhatsappPaywall = () => {
  const navigate = useNavigate();
  const handleUpgrade = () => {
    navigate("/pricing");
  };
  return (
    <div className="border-stone-600 transition-smooth flex h-[200px] w-full flex-col justify-between rounded-xl border bg-white p-6 pt-8 duration-100 ease-in-out hover:shadow-md">
      <div>
        <div className={`flex items-center justify-start`}>
          <SiWhatsapp className="h-10 w-10 text-black" />
        </div>
        <h6 className="mt-4 font-normal tracking-tight text-[#22281E] sm:text-sm lg:text-xl">
          Whatsapp
        </h6>
      </div>
      <div className={`w-full`}>
        <Button onClick={() => handleUpgrade()} className="w-full">
          Upgrade to Connect
        </Button>{" "}
      </div>
    </div>
  );
};

const TwilioPaywall = () => {
  const navigate = useNavigate();
  const handleUpgrade = () => {
    navigate("/pricing");
  };
  return (
    <div className="border-stone-600 transition-smooth flex h-[200px] w-full flex-col justify-between rounded-xl border bg-white p-6 pt-8 duration-100 ease-in-out hover:shadow-md">
      <div>
        <div className={`flex items-center justify-start`}>
          <SiTwilio className="h-10 w-10 text-black" />
        </div>
        <h6 className="mt-4 font-normal tracking-tight text-[#22281E] sm:text-sm lg:text-xl">
          Twilio
        </h6>
      </div>
      <div className={`w-full`}>
        <Button onClick={() => handleUpgrade()} className="w-full">
          Upgrade to Connect
        </Button>{" "}
      </div>
    </div>
  );
};

export { FacebookPaywall, WhatsappPaywall, TwilioPaywall };
