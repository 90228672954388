/* eslint-disable */
import React from "react";
import { useSelector } from "react-redux";
import {
  CreditCard,
  LifeBuoy,
  LogOut,
  Mail,
  MessageSquare,
  Settings,
  User,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../Shadcn/ui/dropdown-menu";
import { Button } from "../../Shadcn/ui/button";
import { Home, Domain } from "../../Env";
import { useNavigate } from "react-router-dom";

function HeaderDropdown() {
  const navigate = useNavigate();
  const username = useSelector((state) => state.user.user[0].username);
  const authToken = localStorage.getItem("auth_token");

  const handleSomething = async () => {
    if (authToken) {
      const response = await fetch(`${Domain}account/api/billing/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        window.open(data.billing_url, "_blank");
      } else {
        alert(
          "Something went wrong. Please try again. If the problem persists, please contact support."
        );
      }
    } else {
      window.location.href = `${Home}`;
    }
  };

  const handleLogout = () => {
    if (authToken) {
      localStorage.removeItem("auth_token");
      localStorage.removeItem("user");
      localStorage.removeItem("user_data");
      window.location.href = `${Home}`;
    } else {
      window.location.href = `${Home}`;
    }
  };

  return (
    <DropdownMenu className="">
      <DropdownMenuTrigger asChild>
        <Button className="">
          <User className="mr-2 h-4 w-4" /> {username}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56 ">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem
            onClick={() => {
              navigate("/settings");
            }}
          >
            <User className="mr-2 h-4 w-4" />
            <span>Profile</span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleSomething}>
            <CreditCard className="mr-2 h-4 w-4" />
            <span>Billing</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              navigate("/settings");
            }}
          >
            <Settings className="mr-2 h-4 w-4" />
            <span>Settings</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          <LifeBuoy className="mr-2 h-4 w-4" />
          <span>Support</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={(e) => {
            handleLogout();
          }}
        >
          <LogOut className="mr-2 h-4 w-4" />
          <span>Log out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default HeaderDropdown;
