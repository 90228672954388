/* eslint-disable */
import { useState, React } from "react";
import axios from "axios";
import {
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Eye } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogClose,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "src/Shadcn/ui/dialog";
import { Button } from "src/Shadcn/ui/button";
import { useToast } from "src/Shadcn/ui/use-toast";
import { apiDomain } from "src/Env";
import { Separator } from "src/Shadcn/ui/separator";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "src/Shadcn/ui/card";
import { Link } from "react-router-dom";

export function SingleInstanceCard({ conversation, removeConversation }) {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDelete = async () => {
    try {
      const formData = new FormData();
      formData.append("conversation_id", conversation.conversion_id);

      setLoading(true);
      const response = await axios.post(
        `${apiDomain}api/delete-conversation/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        },
      );

      if (response.status === 200) {
        removeConversation(conversation.conversion_id);
        toast({
          title: "Success",
          description: "Conversation Deleted Successfully.",
        });
        setLoading(false);
        setIsDialogOpen(false);
      } else {
        toast({
          variant: "destructive",
          description: "Uh oh! Something went wrong.",
        });
      }
    } catch (error) {
      toast({
        variant: "destructive",
        description: "Uh oh! Something went wrong.",
      });
      setLoading(false);
      setIsDialogOpen(false);
    }
  };

  function timeConverter(dateStr) {
    let date = new Date(dateStr);
    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes();
    let suffix = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutes} ${suffix}`;
  }

  // New function to decide whether to show date or time
  function displayDateTime(dateStr) {
    const now = new Date();
    const leadDate = new Date(dateStr);
    const hoursDifference = Math.abs(now - leadDate) / (1000 * 60 * 60);
    if (hoursDifference < 24) {
      // If less than 24 hours have passed, show only the time
      return timeConverter(dateStr);
    } else {
      // Otherwise, show the date followed by the time
      return `${leadDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
      })}`;
    }
  }

  return (
    <>
      <Card className="flex h-full flex-col">
        <CardHeader>
          <div className="flex w-full justify-between">
            <CardTitle className="mb-2 capitalize">
              {" "}
              {conversation.lead_name}
            </CardTitle>
            <CardDescription>
              {displayDateTime(conversation.lead_date)}
            </CardDescription>{" "}
          </div>

          <Separator orientation="horizontal" />
        </CardHeader>
        <CardContent className="grow">
          <div className="flex items-center">
            <div className="grid w-full grid-cols-1 gap-y-1 md:flex-row">
              <div className="col-span-1 flex items-center gap-x-2 rounded px-2 text-sm font-medium leading-none">
                {conversation.lead_last_msg.role === "assistant"
                  ? "AI: "
                  : "Customer: "}
                <p className="w-full truncate text-gray-600">
                  {" "}
                  {conversation.lead_last_msg.content}
                </p>
              </div>
            </div>
          </div>
        </CardContent>
        <CardFooter className="flex items-center justify-end gap-x-2 pt-0">
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
              <Button
                size="icon"
                type="button"
                className="bg-red-600 hover:bg-red-900"
              >
                <TrashIcon className="inline-block h-5 w-5" />
              </Button>
            </DialogTrigger>
            <DialogContent className="h-[200px] p-6 sm:max-w-[450px]">
              <DialogHeader>
                <DialogTitle className="text-red-500">
                  Delete Conversation
                </DialogTitle>
                <DialogDescription>
                  <h2 className="text-md mb-4 mt-2 text-black">
                    Are you sure you want to delete this conversation? This
                    action cannot be undone.
                  </h2>
                </DialogDescription>
              </DialogHeader>

              <div className="flex w-full justify-end gap-2">
                <DialogClose asChild>
                  <Button type="button" variant="secondary">
                    No Take me Back
                  </Button>
                </DialogClose>
                <Button
                  type="button"
                  className="bg-red-600 hover:bg-red-900"
                  onClick={handleDelete}
                >
                  {loading ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="h-5 w-5 animate-spin"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                      />
                    </svg>
                  ) : (
                    <>
                      <TrashIcon className="mr-2 inline-block h-4 w-4" />
                      Yes
                    </>
                  )}
                </Button>
              </div>
            </DialogContent>
          </Dialog>
          <Link to={`/conversation/${conversation.conversion_id}`}>
            <Button size="icon" type="button" className="px-3 shadow-none">
              <Eye className="inline-block h-6 w-6" />
            </Button>
          </Link>
        </CardFooter>
      </Card>
    </>
  );
}
