/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Domain } from '../../Env';

const initialState = {
  bubble_bg: '',
  bubble_text_color: '',
  chat_id: '',
  chat_model: '',
  desc: '',
  header_bg: '',
  header_text_color: '',
  initial_message: '',
  intro_message: '',
  message_suggestions: [],
  name: '',
  plan: '',
  shared: true,
  sys_message: '',
  temperature: 0,
  appointment_link: '',
  bubble_image: '',
  header_image: '',
  messages:''
};

const fetchintcData = createAsyncThunk('intc/fetchData', async (chat_id) => {
  const authToken = localStorage.getItem('auth_token');
  const response = await fetch(`${Domain}api/get-chat/${chat_id}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  });

  if (response.ok) {
    const data = await response.json();
    // console.log(data.chat);
    return data.chat;
  } else {
    const data = await response.json();
    // console.log(data);
    return false;
  }
});

export const intcSlice = createSlice({
  name: 'intc',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchintcData.pending, (state) => {})
      .addCase(fetchintcData.fulfilled, (state, action) => {
        // console.log(action);
        state.bubble_bg = action.payload.bubble_bg;
        state.bubble_text_color = action.payload.bubble_text_color;
        state.chat_id = action.payload.chat_id;
        state.chat_model = action.payload.chat_model;
        state.desc = action.payload.desc;
        state.header_bg = action.payload.header_bg;
        state.header_text_color = action.payload.header_text_color;
        state.initial_message = action.payload.initial_message;
        state.intro_message = action.payload.intro_message;
        state.message_suggestions = action.payload.message_suggestions;
        state.name = action.payload.name;
        state.plan = action.payload.plan;
        state.shared = action.payload.shared;
        state.sys_message = action.payload.sys_message;
        state.temperature = action.payload.temperature;
        state.messages = action.payload.messages;
        if (action.payload.appointment_link) {
          state.appointment_link = action.payload.appointment_link;
        }
        if (action.payload.bubble_image) {
          state.bubble_image = action.payload.bubble_image;
        }
        if (action.payload.header_image) {
          state.header_image = action.payload.header_image;
        }
      })
      .addCase(fetchintcData.rejected, (state, action) => {
        // console.log(action);
      });
  },
});

export { fetchintcData };
export default intcSlice.reducer;
