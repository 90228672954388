/* eslint-disable */
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import "./style.css";
import { Button } from "../Shadcn/ui/button";
import { useNavigate } from "react-router-dom";
import {
  SiMessenger,
  SiSlack,
  SiZapier,
  SiTwilio,
  SiWhatsapp,
} from "react-icons/si";
import React from "react";
import IntegrationsOpenAiConnections from "../Components/IntegrationPage/OpenAi";
import IntegrationsGoogleCalendar from "../Components/IntegrationPage/GoogleCalenederPage";
import { Separator } from "../Shadcn/ui/separator";

export default function Integrations() {
  const navigate = useNavigate();
  function navigateToChatbots() {
    navigate("/chatbots");
  }

  function onSlackConnect() {
    return;
  }

  const Integration = [
    {
      title: "Messenger",
      // subtitle: "Connect your Facebook to your account",
      icon: <SiMessenger className="h-10 w-10 text-black" />,
      status: "Dis-Connected",
      function: navigateToChatbots,
      disabled: false,
      buttonText: "Connect via chatbot",
    },

    {
      title: " Twilio",
      // subtitle: "Connect your Twilio to your account",
      icon: <SiTwilio className="h-10 w-10 text-black" />,
      status: "Dis-Connected",
      function: navigateToChatbots,
      disabled: false,
      buttonText: "Connect via chatbot",
    },
    {
      title: "Whatsapp",
      // subtitle: "Connect your Whatsapp to your account",
      icon: <SiWhatsapp className="h-10 w-10 text-black" />,
      status: "Dis-Connected",
      function: navigateToChatbots,
      disabled: false,
      buttonText: "Connect via chatbot",
    },
    {
      title: " Slack",
      // subtitle: "Connect your Slack to your account",
      icon: <SiSlack className="h-10 w-10 text-black" />,
      status: "Dis-Connected",
      function: onSlackConnect,
      disabled: true,
      buttonText: "Comming Soon",
    },
    {
      title: "Zapier",
      // subtitle: "Connect your Zapier to your account",
      icon: <SiZapier className="h-10 w-10 text-black" />,
      status: "Dis-Connected",
      function: onSlackConnect,
      disabled: true,
      buttonText: "Comming Soon",
    },
  ];

  return (
    <div className="flex w-full items-start justify-center px-4 py-4 sm:px-10 sm:py-6 lg:flex-none lg:px-8 xl:px-8">
      <div className="flex w-full flex-col items-start justify-center gap-x-4 md:p-3">
        {" "}
        <div className="flex w-full  flex-col items-center justify-start gap-y-3 md:flex-row md:justify-between md:gap-y-0">
          <div className="flex max-w-2xl flex-col items-start gap-y-2 text-black sm:text-left">
            <h4 className="text-3xl font-bold tracking-tight text-black sm:text-4xl ">
              Connections
            </h4>
            <p className="font-small lg:text-md tracking-tight text-gray-700 sm:text-sm">
              Here are the list apps you can connect to your chatbot
            </p>
          </div>
        </div>{" "}
        <Separator orientation="horizontal" className="my-2 md:mb-2 md:mt-6" />
        <div className="mt-6 grid w-full grid-cols-4 ">
          <div className="col-span-4 grid w-full grid-cols-1 gap-5 lg:col-span-4 lg:grid-cols-4 ">
            <IntegrationsGoogleCalendar />
            <IntegrationsOpenAiConnections />
            {Integration.map((integration) => (
              <div className="border-stone-600 transition-smooth flex h-[200px] w-full flex-col justify-between rounded-xl border bg-white p-6 pt-8 duration-100 ease-in-out hover:shadow-md">
                <div>
                  <div
                    className={`flex ${
                      integration.status === "Connected"
                        ? "justify-between"
                        : "justify-start"
                    } items-center`}
                  >
                    {integration.icon}
                    {integration.status === "Connected" && (
                      <div className="flex items-center rounded-2xl bg-green-500 p-1 px-2 pl-1 text-xs text-black">
                        <CheckBadgeIcon className="mr-1 h-5 w-5 text-black" />{" "}
                        Connected
                      </div>
                    )}
                  </div>
                  <h6 className="mt-4 font-normal tracking-tight text-[#22281E] sm:text-sm lg:text-xl">
                    {integration.title}
                  </h6>
                </div>
                <div
                  className={`w-full ${
                    integration.secondary ? "flex gap-x-2" : ""
                  }`}
                >
                  <Button
                    onClick={(e) => integration.function()}
                    className="w-full"
                    disabled={integration.disabled}
                  >
                    {integration.disabled ? "Comming Soon" : integration.buttonText}
                  </Button>{" "}
                  {integration.secondary ? (
                    <Button
                      onClick={(e) => integration.secondary()}
                      className="w-full"
                    >
                      Subscribe
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
