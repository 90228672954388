/* eslint-disable */
import { Link } from 'react-router-dom';
import { fetchData } from '../Redux/Chat/dtlSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData  } from '../Redux/User/userSlice';

export default function Subscribe() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.user);

  const check = () => {
    // console.log(state);
  };

  const handleToken = () => {
    const token = localStorage.getItem('auth_token');
    dispatch(fetchUserData('1d0ca4d2-e6f1-48bb-8970-5912dd6cfb79'));
  };

  return (
    <main className="py-10 w-full flex flex-col justify-center items-center font-Inter">
      {/* <div class="bg-[#d2a3ff] px-2 text-sm rounded py-2 px-4">Purchase successfull!</div> */}
      <h1 className="text-4xl text-center text-gray-900">
        Purchase Was Successful!
      </h1>
      <Link to="/chatbots">
        {' '}
        <button className="mt-5 rounded-md py-2 px-4 lg:ml-auto bg-[#dec7f7] text-gray-800 hover:outline-none hover:ring-2 hover:ring-offset-2 hover:ring-[#724e98] hover:bg-gray-100">
          Create a Bot
        </button>
      </Link>

      {/* <button onClick={handleState}>handle state</button>*/}
      <button onClick={check}>check state</button>
      <button onClick={handleToken}>check user  fetch</button>
    </main>
  );
}
