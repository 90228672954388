/* eslint-disable */
import { FcGoogle } from "react-icons/fc";
import { cn } from "../../lib/utils";
import { Button } from "../../Shadcn/ui/button";
import { Label } from "../../Shadcn/ui/label";
import { TbFidgetSpinner } from "react-icons/tb";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useToast } from "../../Shadcn/ui/use-toast";
import { useNavigate } from "react-router-dom";
import { Home, Domain } from "../../Env";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { onGoogleLoginSuccess } from "./External_OAuth";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "../../Shadcn/ui/input-otp";

export function UserAuthForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { token } = useParams();
  const navigate = useNavigate();
  const authToken = localStorage.getItem("auth_token");
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { toast } = useToast();

  if (token) {
    if (token === "error") {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "Error logging in. Please try again.",
      });
    } else {
      localStorage.setItem("auth_token", token);
      window.location.href = `${Home}`;
    }
  }

  useEffect(() => {
    if (authToken && isAuthenticated) {
      navigate("/chatbots");
    }
  }, [authToken, isAuthenticated]);

  async function onSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    if (!username || !password) {
      setIsLoading(false);
      return toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "Please fill in all the fields.",
      });
    }

    try {
      const response = await fetch(`${Domain}account/api/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setIsLoading(false);
        localStorage.setItem("auth_token", data.token);
        localStorage.setItem("user", data.user);
        window.location.href = `${Home}`;
      } else {
        const data = await response.json();
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: data.detail,
        });
        setPassword("");
        setUsername("");
        setIsLoading(false);
      }
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: error,
      });
      setIsLoading(false);
    }
  }

  return (
    <div className={cn("grid gap-6")}>
      <form onSubmit={onSubmit}>
        <div className="grid gap-2">
          <div className="grid gap-1">
            <Label className="sr-only font-Inter" htmlFor="username">
              Username
            </Label>
            <input
              id="username"
              placeholder="username"
              type="text"
              autoCapitalize="none"
              autoComplete="username"
              autoCorrect="off"
              disabled={isLoading}
              className="font-Inter ring-whitering-offset-2 block w-full transform rounded-lg border border-transparent bg-white px-4 py-2 text-sm text-black placeholder-gray-500 ring-1 ring-[#e2e8f0] transition duration-500 ease-in-out   focus:border-transparent focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-2 focus:ring-offset-[#dfc7f9] "
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="grid gap-1">
            <Label className="sr-only font-Inter" htmlFor="password">
              Password
            </Label>
            <input
              id="password"
              placeholder="password"
              type="password"
              autoCapitalize="none"
              autoComplete="password"
              autoCorrect="off"
              disabled={isLoading}
              className="font-Inter ring-whitering-offset-2 block w-full transform rounded-lg border border-transparent bg-white px-4 py-2 text-sm text-black placeholder-gray-500 ring-1 ring-[#e2e8f0] transition duration-500 ease-in-out   focus:border-transparent focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-2 focus:ring-offset-[#dfc7f9] "
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <Button className="" disabled={isLoading}>
            {isLoading && (
              <TbFidgetSpinner className="mr-2 h-4 w-4 animate-spin" />
            )}
            Sign In with Username
          </Button>
        </div>
      </form>
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background  px-2 text-muted-foreground">
            Or continue with
          </span>
        </div>
      </div>
      <Button
        className="text-black"
        variant="outline"
        type="button"
        disabled={isLoading}
        onClick={onGoogleLoginSuccess}
      >
        {isLoading ? (
          <TbFidgetSpinner className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          <FcGoogle className="mr-2 h-6 w-6" />
        )}{" "}
      </Button>
    </div>
  );
}

export function UserSignupForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [f_name, setF_name] = useState("");
  const [l_name, setL_name] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [value, setValue] = useState("");
  const [swap, setSwap] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    const authToken = localStorage.getItem("auth_token");
    if (authToken) {
      navigate("/chatbots");
    }
  }, []);

  async function onSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    if (!username || !email || !f_name || !l_name || !password || !password2) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "Please fill in all the fields.",
      });
      setIsLoading(false);
      return;
    }
    if (password === password2) {
      try {
        const response = await fetch(`${Domain}account/api/register/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: username,
            password: password,
            email: email,
            first_name: f_name,
            last_name: l_name,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          setIsLoading(false);
       //   // console.log(data);
          setSwap(true);
        } else {
          const data = await response.json();
          if (data.username) {
            toast({
              variant: "destructive",
              title: "Uh oh! Something went wrong.",
              description: "Username already exists",
            });
            setIsLoading(false);
            return;
          } else if (data.error) {
            toast({
              variant: "destructive",
              title: "Uh oh! Something went wrong.",
              description: data.error,
            });
            setIsLoading(false);
            return;
          }
          toast({
            variant: "destructive",
            title: "Uh oh! Something went wrong.",
            description: "Error occurred during register",
          });
          setIsLoading(false);
        }
      } catch (error) {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "Error occurred during register",
        });
        setIsLoading(false);
      }
    } else {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "Incorrect Password",
      });
      setIsLoading(false);
    }
  }

  async function onOTPSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    if (!value || value.length < 6) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "Please enter the OTP.",
      });
      setIsLoading(false);
      return;
    }

    const payload = {
      email: email,
      otp: value,
    };

    try {
      const response = await fetch(`${Domain}account/api/verify-otp/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        toast({
          title: "Success",
          description: "Account created successfully. Please login to continue",
        });
        setIsLoading(false);
        navigate("/login");
      } else {
        // Handle register failure here
        const data = await response.json();
        if ((data.error = "Invalid OTP")) {
          toast({
            variant: "destructive",
            title: "Uh oh! Something went wrong.",
            description: "Uh oh! Invalid OTP.",
          });
          setIsLoading(false);
          return;
        } else if (data.error) {
          toast({
            variant: "destructive",
            title: "Uh oh! Invalid OTP.",
            description: data.error,
          });
          setIsLoading(false);
          return;
        }
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "Error occurred during register",
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "Error occurred during register",
      });
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className={cn("grid gap-6")}>
        {swap ? (
          <div className="">
            {" "}
            <form onSubmit={onOTPSubmit}>
              <div className="grid gap-2">
                <div className="grid gap-1">
                  <Label className="sr-only" htmlFor="username">
                    Username
                  </Label>
                  <div className="space-y-2">
                    <InputOTP
                      maxLength={6}
                      value={value}
                      onChange={(value) => setValue(value)}
                      render={({ slots }) => (
                        <InputOTPGroup>
                          {slots.map((slot, index) => (
                            <React.Fragment key={index}>
                              <InputOTPSlot
                                className="rounded-md border"
                                key={index}
                                {...slot}
                              />
                              {index !== slots.length - 1 && (
                                <InputOTPSeparator />
                              )}
                            </React.Fragment>
                          ))}{" "}
                        </InputOTPGroup>
                      )}
                    />
                  </div>
                </div>

                <Button className="mt-2" disabled={isLoading}>
                  {isLoading && (
                    <TbFidgetSpinner className="mr-2 h-4 w-4 animate-spin" />
                  )}
                  Submit OTP
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <>
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold font-Inter  tracking-tight text-black">
                Create your Account
              </h1>
              <p className="text-sm text-muted-foreground font-Inter">
                Enter your information below to create to your account
              </p>
            </div>
            <form onSubmit={onSubmit}>
              <div className="grid gap-2">
                <div className="grid gap-1">
                  <Label className="sr-only" htmlFor="username">
                    Username
                  </Label>
                  <input
                    id="username"
                    placeholder="username"
                    type="text"
                    autoCapitalize="none"
                    autoComplete="username"
                    autoCorrect="off"
                    disabled={isLoading}
                    className="ring-whitering-offset-2 block w-full transform rounded-lg border border-transparent bg-white px-4 py-2 text-sm text-black placeholder-gray-500 ring-1 ring-[#e2e8f0] transition duration-500 ease-in-out   focus:border-transparent focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-2 focus:ring-offset-[#dfc7f9] "
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>

                <div className="grid grid-cols-2 gap-2">
                  <div className="grid gap-1">
                    <Label className="sr-only" htmlFor="f_name">
                      First Name
                    </Label>
                    <input
                      id="f_name"
                      placeholder="first name"
                      type="text"
                      autoCapitalize="none"
                      autoComplete="text"
                      autoCorrect="off"
                      disabled={isLoading}
                      className="ring-whitering-offset-2 block w-full transform rounded-lg border border-transparent bg-white px-4 py-2 text-sm text-black placeholder-gray-500 ring-1 ring-[#e2e8f0] transition duration-500 ease-in-out   focus:border-transparent focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-2 focus:ring-offset-[#dfc7f9] "
                      value={f_name}
                      onChange={(e) => setF_name(e.target.value)}
                    />
                  </div>
                  <div className="grid gap-1">
                    <Label className="sr-only" htmlFor="l_name">
                      Last Name
                    </Label>
                    <input
                      id="l_name"
                      placeholder="last name"
                      type="text"
                      autoCapitalize="none"
                      autoComplete="text"
                      autoCorrect="off"
                      disabled={isLoading}
                      className="ring-whitering-offset-2 block w-full transform rounded-lg border border-transparent bg-white px-4 py-2 text-sm text-black placeholder-gray-500 ring-1 ring-[#e2e8f0] transition duration-500 ease-in-out   focus:border-transparent focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-2 focus:ring-offset-[#dfc7f9] "
                      value={l_name}
                      onChange={(e) => setL_name(e.target.value)}
                    />
                  </div>
                </div>
                <div className="grid gap-1">
                  <Label className="sr-only" htmlFor="email">
                    Username
                  </Label>
                  <input
                    id="email"
                    placeholder="email"
                    type="email"
                    autoCapitalize="none"
                    autoComplete="email"
                    autoCorrect="off"
                    disabled={isLoading}
                    className="ring-whitering-offset-2 block w-full transform rounded-lg border border-transparent bg-white px-4 py-2 text-sm text-black placeholder-gray-500 ring-1 ring-[#e2e8f0] transition duration-500 ease-in-out   focus:border-transparent focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-2 focus:ring-offset-[#dfc7f9] "
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="grid grid-cols-2 gap-2">
                  <div className="grid gap-1">
                    <Label className="sr-only" htmlFor="password">
                      Password
                    </Label>
                    <input
                      id="password"
                      placeholder="password"
                      type="password"
                      autoCapitalize="none"
                      autoComplete="password"
                      autoCorrect="off"
                      disabled={isLoading}
                      className="ring-whitering-offset-2 block w-full transform rounded-lg border border-transparent bg-white px-4 py-2 text-sm text-black placeholder-gray-500 ring-1 ring-[#e2e8f0] transition duration-500 ease-in-out   focus:border-transparent focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-2 focus:ring-offset-[#dfc7f9] "
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="grid gap-1">
                    <Label className="sr-only" htmlFor="password2">
                      Confirm Password
                    </Label>
                    <input
                      id="password2"
                      placeholder="confirm password"
                      type="password"
                      autoCapitalize="none"
                      autoComplete="password"
                      autoCorrect="off"
                      disabled={isLoading}
                      className="ring-whitering-offset-2 block w-full transform rounded-lg border border-transparent bg-white px-4 py-2 text-sm text-black placeholder-gray-500 ring-1 ring-[#e2e8f0] transition duration-500 ease-in-out   focus:border-transparent focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-2 focus:ring-offset-[#dfc7f9] "
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                    />
                  </div>
                </div>
                <Button className=" " disabled={isLoading}>
                  {isLoading && (
                    <TbFidgetSpinner className="mr-2 h-4 w-4 animate-spin" />
                  )}
                  Sign up with Email
                </Button>
              </div>
            </form>
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t" />
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-background  px-2 text-muted-foreground">
                  Or continue with
                </span>
              </div>
            </div>
            <Button
              className="text-black"
              variant="outline"
              type="button"
              disabled={isLoading}
              onClick={onGoogleLoginSuccess}
            >
              {isLoading ? (
                <TbFidgetSpinner className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <FcGoogle className="mr-2 h-6 w-6" />
              )}{" "}
            </Button>
          </>
        )}
      </div>
    </>
  );
}
