/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import { cn } from '../../lib/utils';
import { buttonVariants } from '../../Shadcn/ui/button';
import { UserAuthForm } from './Forms';

export default function Login() {
  return (
    <>
      <div className="md:hidden">
        <image
          src="https://static5.depositphotos.com/1004999/458/i/950/depositphotos_4582193-stock-photo-beautiful-spring-park.jpg"
          width={1280}
          height={843}
          alt="Authentication"
          className="block :hidden"
        />
        <image
          src="https://static5.depositphotos.com/1004999/458/i/950/depositphotos_4582193-stock-photo-beautiful-spring-park.jpg"
          width={1280}
          height={843}
          alt="Authentication"
          className="hidden :block"
        />
      </div>
      <div className="container relative h-[100vh] flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <Link
          to="/signup"
          className={cn(
            buttonVariants({ variant: 'ghost' }),
            'absolute right-4 top-4 text-black md:right-8 md:top-8'
          )}
        >
          Signup
        </Link>
        <div className="relative hidden h-full flex-col p-10 text-white bg-black :border-r lg:flex">
          <div className="absolute inset-0" />
          <div className="relative z-20 text-white flex items-center text-lg font-medium ">
            <div className='mr-2'>
              <svg
                width="50"
                height="50"
                viewBox="0 0 288 288"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M234.06 0H53.67C24.0289 0 0 24.0289 0 53.67V234.06C0 263.701 24.0289 287.73 53.67 287.73H234.06C263.701 287.73 287.73 263.701 287.73 234.06V53.67C287.73 24.0289 263.701 0 234.06 0Z"
                  fill="black"
                />
                <path
                  d="M83.6695 245.8L82.6695 240.38C76.0116 204.019 78.4402 166.576 89.7395 131.38C90.8595 127.92 92.0595 124.5 93.2995 121.21L100.71 124.02C99.5095 127.18 98.3595 130.48 97.2795 133.81C86.9597 165.947 84.3056 200.053 89.5295 233.4C108.509 222.442 125.988 209.071 141.53 193.62L142.27 192.88C151.659 183.431 160.18 173.156 167.73 162.18C172.8 154.78 181.289 142.4 188.189 125.44L195.529 128.44C189.889 141.936 182.758 154.759 174.27 166.67C166.759 177.589 158.318 187.837 149.04 197.3L147.93 198.53C130.322 216.138 110.31 231.164 88.4895 243.16L83.6695 245.8Z"
                  fill="white"
                />
                <path
                  d="M205.29 245.8L200.429 243.13C178.964 231.144 159.358 216.096 142.23 198.46L141.12 197.3C132.016 187.783 123.696 177.546 116.24 166.69C110.44 158.22 100.74 144.06 93.2395 124.03L92.7195 122.64L93.2495 121.24C103.865 93.2224 120.02 67.6326 140.75 46L143.609 43L146.48 46C168.316 68.7586 185.054 95.9078 195.579 125.64C196.249 127.51 196.909 129.45 197.579 131.41C214.089 182.57 209.16 224.66 206.279 240.41L205.29 245.8ZM149.01 194.02C164.03 209.283 180.98 222.519 199.429 233.39C202.059 215.26 204.369 178.13 190.079 133.84C189.469 131.95 188.84 130.09 188.2 128.29C178.513 100.935 163.359 75.8388 143.66 54.53C125.364 74.3688 110.973 97.4791 101.24 122.65C108.31 141.04 117.36 154.24 122.81 162.21C130.319 173.139 138.732 183.418 147.96 192.94L149.01 194.02Z"
                  fill="white"
                />
                <path
                  d="M205.29 245.8L200.429 243.13C178.964 231.144 159.358 216.096 142.23 198.46L141.12 197.3C132.016 187.783 123.696 177.546 116.24 166.69C110.44 158.22 100.74 144.06 93.2395 124.03L92.7195 122.64L93.2495 121.24C103.865 93.2224 120.02 67.6326 140.75 46L143.609 43L146.48 46C168.316 68.7586 185.054 95.9078 195.579 125.64C196.249 127.51 196.909 129.45 197.579 131.41C214.089 182.57 209.16 224.66 206.279 240.41L205.29 245.8ZM149.01 194.02C164.03 209.283 180.98 222.519 199.429 233.39C202.059 215.26 204.369 178.13 190.079 133.84C189.469 131.95 188.84 130.09 188.2 128.29C178.513 100.935 163.359 75.8388 143.66 54.53C125.364 74.3688 110.973 97.4791 101.24 122.65C108.31 141.04 117.36 154.24 122.81 162.21C130.319 173.139 138.732 183.418 147.96 192.94L149.01 194.02Z"
                  fill="white"
                />
              </svg>
            </div>
            Nexachat
          </div>
          <div className="relative  z-20 mt-auto">
            <blockquote className=" text-white  space-y-2">
              <p className="text-lg text-white ">
                &ldquo;This Chatbot has been an immense help for my support
                Team&rdquo;
              </p>
              <footer className="text-sm text-white ">Sofia Davis</footer>
            </blockquote>
          </div>
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl text-black font-semibold tracking-tight">
                Sign in 
              </h1>
              <p className="text-sm text-muted-foreground">
                Enter your email below to signin to your account
              </p>
            </div>
            <UserAuthForm />
            <p className="px-8 text-center text-sm text-muted-foreground">
              By clicking continue, you agree to our{' '}
              <Link
                to="/terms-of-service"
                className="underline underline-offset-4  hover:text-primary"
              >
                Terms of Service
              </Link>{' '}
              and{' '}
              <Link
                to="/privacy-policy"
                className="underline underline-offset-4  hover:text-primary"
              >
                Privacy Policy
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
