import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Domain } from "../../Env";

const initialState = {
  chatData: {},
};

const fetchSingleLiveChat = createAsyncThunk(
  "live/fetchChat",
  async (conv_id) => {
    const authToken = localStorage.getItem("auth_token");

    try {
      const response = await fetch(`${Domain}lc/m/${conv_id}/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch chat data");
      }

      const data = await response.json();
      // console.log("Chat data", data);
      return data;
    } catch (error) {
      // console.log(error);
      return false;
    }
  },
);

export const singleLiveChat = createSlice({
  name: "singleLiveChat",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleLiveChat.pending, (state) => {})
      .addCase(fetchSingleLiveChat.fulfilled, (state, action) => {
        if (action.payload === false) {
          return;
        } else {
          state.chatData = action.payload;
        }
      })
      .addCase(fetchSingleLiveChat.rejected, (state, action) => {
        // console.log(action);
      });
  },
});

export { fetchSingleLiveChat };
export default singleLiveChat.reducer;
