import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Domain } from "../../Env";

const initialState = {
  user: [
    {
      email: "",
      username: "",
      token: "",
      id: "",
    },
  ],
  plan_name: "",
  subscription_status: "",
  bots_allowed: 0,
  bots_created: 0,
  features: {
    AppointmentBooking: false,
    LeadCollection: false,
    LiveChat: false,
    FacebookMessenger: false,
    WhatsApp: false,
    Twilio: false,
    WooCommerce: false,
  },
};

// Define the features available for each plan
const PLAN_FEATURES = {
  free: {
    AppointmentBooking: false,
    LeadCollection: false,
    LiveChat: false,
    FacebookMessenger: false,
    WhatsApp: false,
    Twilio: false,
    WooCommerce: false,
  },
  basic: {
    AppointmentBooking: false,
    LeadCollection: true,
    LiveChat: false,
    FacebookMessenger: true,
    WhatsApp: true,
    Twilio: false,
    WooCommerce: false,
  },
  pro: {
    AppointmentBooking: true,
    LeadCollection: true,
    LiveChat: true,
    FacebookMessenger: true,
    WhatsApp: true,
    Twilio: true,
    WooCommerce: false,
  },
  ultimate: {
    AppointmentBooking: true,
    LeadCollection: true,
    LiveChat: true,
    FacebookMessenger: true,
    WhatsApp: true,
    Twilio: true,
    WooCommerce: true,
  },
  as1: {
    AppointmentBooking: false,
    LeadCollection: true,
    LiveChat: false,
    FacebookMessenger: true,
    WhatsApp: true,
    Twilio: false,
    WooCommerce: false,
  },
  as2: {
    AppointmentBooking: true,
    LeadCollection: true,
    LiveChat: false,
    FacebookMessenger: true,
    WhatsApp: true,
    Twilio: false,
    WooCommerce: false,
  },
  as3: {
    AppointmentBooking: true,
    LeadCollection: true,
    LiveChat: true,
    FacebookMessenger: true,
    WhatsApp: true,
    Twilio: true,
    WooCommerce: false,
  },
  as4: {
    AppointmentBooking: true,
    LeadCollection: true,
    LiveChat: true,
    FacebookMessenger: true,
    WhatsApp: true,
    Twilio: true,
    WooCommerce: false,
  },
  as5: {
    AppointmentBooking: true,
    LeadCollection: true,
    LiveChat: true,
    FacebookMessenger: true,
    WhatsApp: true,
    Twilio: true,
    WooCommerce: false,
  },
  as6: {
    AppointmentBooking: true,
    LeadCollection: true,
    LiveChat: true,
    FacebookMessenger: true,
    WhatsApp: true,
    Twilio: true,
    WooCommerce: true,
  },
};

const TIME_DELTA = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async (token, { dispatch, rejectWithValue }) => {
    const authToken = token || localStorage.getItem("auth_token");
    const localData = localStorage.getItem("user_data");
    const localDataTime = localStorage.getItem("user_data_time");
    const now = new Date().getTime();

    if (
      localData &&
      localDataTime &&
      now - parseInt(localDataTime, 10) < TIME_DELTA
    ) {
      const data = JSON.parse(localData);

      dispatch(updateUserFeatures({ plan: data.plan_name }));
      return data;
    } else {
      try {
        const response = await fetch(`${Domain}account/api/user-details/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!response.ok) throw new Error("Network response was not ok.");

        const data = await response.json();
        dispatch(updateUserFeatures({ plan: data.plan_name }));
        localStorage.setItem("user_data", JSON.stringify(data));
        localStorage.setItem("user_data_time", now.toString());
        return data;
      } catch (error) {
        console.error("Error fetching user data:", error);
        return rejectWithValue(error.message);
      }
    }
  },
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    signout(state) {
      state.user[0] = initialState.user[0];
      state.plan_name = initialState.plan_name;
      state.subscription_status = initialState.subscription_status;
      state.bots_allowed = initialState.bots_allowed;
      state.bots_created = initialState.bots_created;
      state.features = initialState.features;
      localStorage.removeItem("user_data");
      localStorage.removeItem("user_data_time");
      localStorage.removeItem("auth_token");
    },
    updateUserFeatures(state, action) {
      state.features = PLAN_FEATURES[action.payload.plan] || {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {})
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.user[0].email = action.payload.user.email;
        state.user[0].username = action.payload.user.username;
        state.user[0].token = action.payload.user.token;
        state.user[0].id = action.payload.user.id;
        state.plan_name = action.payload.plan_name;
        state.subscription_status = action.payload.subscription_status;
        state.bots_allowed = action.payload.bots_allowed;
        state.bots_created = action.payload.bots_created;
      })
      .addCase(fetchUserData.rejected, (state, action) => {});
  },
});

export const { signout, updateUserFeatures } = userSlice.actions;

export default userSlice.reducer;
