/* eslint-disable */
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { apiDomain } from '../Env';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { User2 } from 'lucide-react';
import { Button } from '../Shadcn/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../Shadcn/ui/card';
import { Input } from '../Shadcn/ui/input';
import { Label } from '../Shadcn/ui/label';
import { Textarea } from '../Shadcn/ui/textarea';
import { ScrollArea } from '../Shadcn/ui/scroll-area';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { Separator } from '../Shadcn/ui/separator';

export function SingleConversation() {
  const authToken = localStorage.getItem('auth_token');
  const { conversationId } = useParams();
  const [messages, setMessages] = useState([]);
  const [data, setData] = useState([]);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const fetchMessages = async () => {
    try {
      const response = await axios.get(
        `${apiDomain}api/get-conversation/${conversationId}/`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setData(response.data.details);
      let messages_resp = response.data.conversation;
      // console.log(messages_resp);
      setMessages(messages_resp);
    } catch (error) {}
  };

  useEffect(() => {
    fetchMessages(conversationId);
  }, [conversationId]);


  const properTime = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days === 0) {
      return date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    } else if (days === 1) {
      return 'Yesterday';
    } else {
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
  };

  return (
    <>
      <div className="flex w-full">
        <div className="h-[100vh] flex items-center flex-col justify-center w-full md:w-[4vw]">
          <Button
            asChild
            variant="outline"
            className="w-full h-full dark rounded-none"
          >
            <Link to={`/chats/${data.chat_id}/conversation`}>
              <ChevronLeftIcon className="h-5 w-5" />
            </Link>
          </Button>
        </div>
        <div className="bg-white rounded-none border border-[#e5e7eb] w-[76%] h-[100vh]">
          <ScrollArea className="w-[100%] h-[100%] px-4">
            {/* AI RESPONSE HERE */}
            <div className="grid grid-cols-10 w-full gap-y-2 pt-4">
              {messages.length > 0 &&
                messages
                  .filter(
                    (message) =>
                      (message.role === 'assistant' &&
                        message.content !== null &&
                        !message.tool_calls) ||
                      (message.role !== 'tool' && message.role !== 'assistant')
                  )
                  .map((message, index) =>
                    message.role === 'assistant' ? (
                      <div
                        key={index}
                        class="flex gap-3 col-span-6 col-start-1 text-gray-600 text-sm flex-1"
                      >
                        <span class="relative flex items-center justify-center shrink-0 overflow-hidden rounded-full border-2 border-black w-8 h-8 bg-black">
                          <svg
                            stroke="none"
                            fill="white"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            height="20"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M9.813 15.924L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                            ></path>
                          </svg>
                        </span>
                        <p class="leading-relaxed p-2 px-4 border-2 text-black rounded-md bg-[#f6f6f6]">
                          <ReactMarkdown
                            children={message.content}
                            rehypePlugins={[rehypeRaw]}
                            remarkPlugins={[remarkGfm]}
                          />
                        </p>
                      </div>
                    ) : (
                      <div
                        key={index}
                        class="flex gap-3  flex-row-reverse col-span-6 col-end-11  text-gray-600 text-sm flex-1"
                      >
                        <span class="relative flex items-center justify-center shrink-0 overflow-hidden rounded-full border-2 border-black w-8 h-8 bg-black">
                          <User2 className="text-white" size={20} />
                        </span>
                        <p class="leading-relaxed flex flex-col items-end p-2 px-4 text-black border-2 rounded-md bg-[#f6f6f6]">
                          <ReactMarkdown
                            children={message.content}
                            rehypePlugins={[rehypeRaw]}
                            remarkPlugins={[remarkGfm]}
                          />
                        </p>
                      </div>
                    )
                  )}

              <div className="col-span-10" ref={messagesEndRef} />
            </div>
            {/* LIVE CHAT RESPONSE HERE */}
            {/* {data &&
            data.map((item, index) =>
              item.type === 'human' ? (
                <div
                  key={index}
                  class="flex gap-3 w-[95%] text-gray-600 text-sm flex-1"
                >
                  <span class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
                    <div class="rounded-full bg-gray-100 border p-1">
                      <svg
                        stroke="none"
                        fill="black"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        height="20"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"></path>
                      </svg>
                    </div>
                  </span>
                  <p class="leading-relaxed">
                    <span class="block font-bold text-gray-700">You </span>
                    {item.content}
                  </p>
                </div>
              ) : (
                <div
                  key={index}
                  class="flex gap-3 w-[95%]  text-gray-600 text-sm flex-1"
                >
                  <span class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
                    <div class="rounded-full bg-gray-100 border p-1">
                      <svg
                        stroke="none"
                        fill="black"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        height="20"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9.813 15.924L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                        ></path>
                      </svg>
                    </div>
                  </span>
                  <p class="leading-relaxed">
                    <span class="block font-bold text-gray-700">Nitrobot </span>
                    {item.content}
                  </p>
                </div>
              )
            )} */}
          </ScrollArea>
        </div>
        <div className="bg-white rounded-none w-[20%] h-[100vh]">
          <Card className="h-full w-full flex flex-col max-h-screen rounded-none ">
            <CardHeader>
              <CardTitle>
                <div className="w-full flex items-center justify-center">
                  <div className="rounded-full h-24 w-24 bg-black flex items-center justify-center">
                    <User2 className="text-white h-16 w-16" />
                  </div>
                </div>
              </CardTitle>
              <CardTitle>
                <div className="w-full text-center">
                  {data.lead_name ? data.lead_name : 'Lead Name'}
                </div>
              </CardTitle>
            </CardHeader>
            <Separator orientation='horizontal' />
            <CardContent className="space-y-2 grow">
              <div className="my-2 w-full h-1"></div>
              <div className="space-y-1">
                <Label htmlFor="description">Email</Label>
                <Input
                  id="description"
                  autocomplete="off"
                  readonly
                  value={data.lead_email}
                />
              </div>
              <div className="space-y-1">
                <Label htmlFor="description">Phone</Label>
                <Input
                  id="description"
                  autocomplete="off"
                  readonly
                  value={data.lead_phone}
                />
              </div>
              <div className="space-y-1">
                <Label htmlFor="sysmsg">Created</Label>
                <Input
                  id="description"
                  autocomplete="off"
                  readonly
                  value={properTime(data.lead_date)}
                />
              </div>
              <div className="space-y-1">
                <Label htmlFor="sysmsg">Note</Label>
                <Textarea id="sysmsg" placeholder="Type your message here." />
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}
