/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { ScrollArea } from "../../Shadcn/ui/scroll-area";
import { Button } from "../../Shadcn/ui/button";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import axios from "axios";
import { Domain } from "../../Env";
import { useSelector } from "react-redux";
import { Dot } from "lucide-react";

export default function ChatFunction() {
  const CHAT_HISTORY_KEY = "chatHistory";
  const [message, setMessage] = useState("");
  const [isChatting, setIsChatting] = useState(false);

  const [ai, setAi] = useState(() => {
    // Load chat history from local storage or default to empty array
    const storedHistory = sessionStorage.getItem(CHAT_HISTORY_KEY);
    return storedHistory ? JSON.parse(storedHistory) : [];
  });

  const chatId = useSelector((state) => state.dtl.chat_id);
  const chat = useSelector((state) => state.dtl);
  const initial = useSelector((state) => state.dtl.bubble.initial_message);
  const [conv_id, setConv_id] = useState(() => {
    const storedConvId = sessionStorage.getItem("conv_id");
    return storedConvId ? storedConvId : null;
  });
  const authToken = localStorage.getItem("auth_token");
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [ai, isChatting]);
  useEffect(() => {
    sessionStorage.setItem(CHAT_HISTORY_KEY, JSON.stringify(ai));
  }, [ai]);

  const establishChatFunctionality = async () => {
    if (!message) {
      return;
    }
    setTimeout(() => {
      setIsChatting(true);
    }, 1000);

    setMessage("");

    // Add the user's message to the chat history

    setAi((prevAiResult) => [
      ...prevAiResult,
      {
        type: "human",
        content: message,
      },
    ]);

    const formData = new FormData();
    formData.append("chat_id", chatId);
    formData.append("message", message);
    formData.append("save_history", true);
    if (conv_id) {
      formData.append("conv_id", conv_id);
    }

    try {
      const response = await axios.post(`${Domain}api/get-answer/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { answer, conversation_id } = response.data;

      if (conversation_id) {
        sessionStorage.setItem("conv_id", conversation_id);
        setConv_id(conversation_id);
      }
      setAi((prevResult) => [
        ...prevResult,
        {
          type: "ai",
          content: answer,
        },
      ]);
    } catch (error) {
      //    // console.log(error);
    } finally {
      setIsChatting(false);
    }

  };


  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      establishChatFunctionality();
    }
  };
  return (
    <div className="h-[92vh] min-h-[92vh]  w-[100%] border border-[#e5e7eb] bg-white px-6 py-4">
      <ScrollArea className="h-[90%] w-[100%]">
        <div class="my-4 flex w-[95%]  flex-1 gap-3 text-sm text-gray-600">
          <span class="relative flex h-8 w-8 shrink-0 overflow-hidden rounded-full">
            <div class="rounded-full border bg-gray-100 p-1">
              <svg
                stroke="none"
                fill="black"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                ></path>
              </svg>
            </div>
          </span>
          <p class="leading-relaxed">
            <span class="block font-bold text-gray-700">{chat.name}</span>
            <div className="prose prose-sm max-w-none">{initial}</div>
          </p>
        </div>

        {/* AI RESPONSE HERE */}

        {ai.map((item, index) =>
          item.type === "human" ? (
            <div
              key={index}
              class="my-4 flex w-[95%] flex-1 gap-3 text-sm text-gray-600"
            >
              <span class="relative flex h-8 w-8 shrink-0 overflow-hidden rounded-full">
                <div class="rounded-full border bg-gray-100 p-1">
                  <svg
                    stroke="none"
                    fill="black"
                    stroke-width="0"
                    viewBox="0 0 16 16"
                    height="20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"></path>
                  </svg>
                </div>
              </span>
              <p class="leading-relaxed">
                <span class="block font-bold text-gray-700">You </span>
                <div className="prose prose-sm max-w-none">
                  <ReactMarkdown
                    children={item.content}
                    rehypePlugins={[rehypeRaw]}
                    remarkPlugins={[remarkGfm]}
                  />
                </div>
              </p>
            </div>
          ) : (
            <div
              key={index}
              class="my-4 flex w-[95%]  flex-1 gap-3 text-sm text-gray-600"
            >
              <span class="relative flex h-8 w-8 shrink-0 overflow-hidden rounded-full">
                <div class="rounded-full border bg-gray-100 p-1">
                  <svg
                    stroke="none"
                    fill="black"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    height="20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                    ></path>
                  </svg>
                </div>
              </span>
              <p class="leading-relaxed">
                <span class="block font-bold text-gray-700">{chat.name}</span>
                <div className="prose prose-sm max-w-none">
                  {" "}
                  <ReactMarkdown
                    children={item.content}
                    rehypePlugins={[rehypeRaw]}
                    remarkPlugins={[remarkGfm]}
                  />
                </div>
              </p>
            </div>
          ),
        )}

        {isChatting && (
          <div class="my-4 flex w-[95%]  flex-1 gap-3 text-sm text-gray-600">
            <span class="relative flex h-8 w-8 shrink-0 overflow-hidden rounded-full">
              <div class="rounded-full border bg-gray-100 p-1">
                <svg
                  stroke="none"
                  fill="black"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  height="20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                  ></path>
                </svg>
              </div>
            </span>
            <p class="leading-relaxed">
              <span class="block font-bold text-gray-700">{chat.name}</span>

              <div className="prose prose-sm flex max-w-none items-center">
                <span>
                  <Dot className="h-4 w-4 animate-bounce text-black delay-0" />
                </span>
                <span>
                  {" "}
                  <Dot className="h-4 w-4 animate-bounce text-black delay-200" />
                </span>
                <span>
                  {" "}
                  <Dot className="delay-400 h-4 w-4 animate-bounce text-black" />
                </span>
              </div>
            </p>
          </div>
        )}
        <div ref={messagesEndRef} />
      </ScrollArea>
      <div className="input flex h-[10%] w-[100%] items-center gap-x-2">
        <input
          type="text"
          required
          placeholder="Enter your message"
          className="ring-whitering-offset-2 block w-full transform rounded-lg border border-transparent bg-white px-4 py-2 text-sm text-black placeholder-gray-500 ring-1 ring-[gray] ring-offset-black transition duration-500 ease-in-out  focus:border-transparent focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-2 focus:ring-offset-[#dfc7f9] "
          value={message}
          onKeyDown={handleKeyPress}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button disabled={isChatting} onClick={establishChatFunctionality}>
          Send{" "}
        </Button>
      </div>
    </div>
  );
}
