import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogClose,
  DialogTitle,
  DialogDescription,
} from "src/Shadcn/ui/dialog";
import { Button } from "src/Shadcn/ui/button";
export default function UpgradeDialog({ open, setOpen }) {
  const navigate = useNavigate();
  const handleUpgrade = () => {
    navigate("/pricing");
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="h-[200px] p-6 sm:max-w-[450px]">
        <DialogHeader>
          <DialogTitle className="text-black">Upgrade Plan</DialogTitle>
          <DialogDescription>
            <h2 className="text-md mb-4 mt-2 text-black">
              This feature is not included in your current plan. Please upgrade
              your account to access this feature.
            </h2>
          </DialogDescription>
        </DialogHeader>

        <div className="flex w-full justify-end gap-2">
          <DialogClose asChild></DialogClose>
          <Button type="button" onClick={handleUpgrade}>
            Upgrade
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
