import React from "react";
import { Navigate } from "react-router-dom";

function Protected({ isSignedIn, children, isAllowed, Paywall }) {
  const authToken = localStorage.getItem("auth_token");

  // Redirect to login if not signed in
  if (!isSignedIn || !authToken) {
    return <Navigate to="/login" replace />;
  }

  // Check if isAllowed prop is present and is false
  if (typeof isAllowed !== "undefined" && !isAllowed) {
    return Paywall ? <Paywall /> : <Navigate to="/404" replace />;
  }

  
  return children;
}

export default Protected;
