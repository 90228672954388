/* eslint-disable */
import { useEffect, useState } from "react";
import { Input } from "../../Shadcn/ui/input";
import { Button } from "../../Shadcn/ui/button";
import { Switch } from "../../Shadcn/ui/switch";
import { Label } from "../../Shadcn/ui/label";
import { useToast } from "../../Shadcn/ui/use-toast";
import { Separator } from "../../Shadcn/ui/separator";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { cn } from "../../lib/utils";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../Shadcn/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../Shadcn/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../Shadcn/ui/command";
import { Checkbox } from "../../Shadcn/ui/checkbox";
import { Timezones } from "../utilities";
import UpgradeDialog from "./UpgradeDialog";

export function ChatAppointmentsSettings() {
  const [timeZoneBoxOpen, setTimeZoneBoxOpen] = useState(false);

  // Redux State Variables
  const [appointmentsEnabled, setAppointmentsEnabled] = useState(false);
  const [appointmentStartTime, setAppointmentStartTime] = useState("09:00:00");
  const [appointmentEndTime, setAppointmentEndTime] = useState("17:00:00");
  const [appointmentDuration, setAppointmentDuration] = useState("30");
  const [timeZone, setTimeZone] = useState("");
  const [daysOff, setDaysOff] = useState([]);
  const [provider, setProvider] = useState("nitrobot");
  const [googleCalendarId, setGoogleCalendarId] = useState("");
  const [googleCalendarConnectionId, setGoogleCalendarConnectionId] =
    useState("");

  // To store the connections
  const googleCalendarConnections = [];

  // Update the state when the redux state changes

  useEffect(() => {
    if (timeZone === "") {
      return;
    }
    let t = timeZone.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
    setTimeZone(t);
  }, [timeZone]);

  // DAYS LIST
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [open, setOpen] = useState(false);

  return (
    <div className="flex h-[92vh] min-h-[92vh] w-full items-start justify-center px-4 py-4 text-black sm:px-10 sm:py-6 lg:flex-none lg:px-8 xl:px-8">
      <div className="flex w-full flex-col items-start justify-center gap-x-4 md:p-3">
        <div className="flex w-full  flex-col items-center justify-start gap-y-3 md:flex-row md:justify-start md:gap-y-0">
          <div className="flex w-full items-center justify-between text-black sm:text-left">
            <div className="flex flex-col gap-y-2 max-w-3xl">
              <h4 className="flex items-center gap-4 text-xl font-bold tracking-tight text-black sm:text-4xl md:flex-row">
                Appointment Settings
                <span class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-center text-xs font-medium uppercase text-purple-700 ring-1 ring-inset ring-purple-700/10">
                Upgrade Plan
                </span>
              </h4>
              <p className="font-small lg:text-md tracking-tight text-gray-700 sm:text-sm">
                Here's a demo of all the configurable settings for the
                Appointment Booking feature. This feature is not included in
                your current plan. Please upgrade your account to access this
                feature.
              </p>
            </div>
            <div className="flex items-center gap-x-4">
              <Label
                className="text-md hidden font-semibold md:flex"
                htmlFor="appointmentsEnabled"
              >
                Enable
              </Label>
              <Switch
                id="appointmentsEnabled"
                checked={appointmentsEnabled}
                onCheckedChange={() =>
                  setAppointmentsEnabled(!appointmentsEnabled)
                }
              />
            </div>
          </div>
        </div>

        <Separator orientation="horizontal" className="my-3 md:my-4" />

        <form className="w-full space-y-4 overflow-y-scroll">
          {appointmentsEnabled && (
            <>
              <div className="space-y-3 rounded-md border-2 p-4 pb-6">
                <h2 className="text-lg font-bold">Calendar Provider</h2>

                <div className="flex w-full flex-col items-start gap-3">
                  <Label htmlFor="start-time">
                    Choose your appointments manager
                  </Label>
                  <Select
                    className="border-[#b7bbc1] placeholder-gray-500"
                    value={provider}
                    onValueChange={setProvider}
                    defaultValue={provider || "nitrobot"}
                    id="provider"
                    placeholder="Select the calendar provider"
                  >
                    <SelectTrigger className={cn("border-[#b7bbc1]")}>
                      <SelectValue
                        className="justify-start"
                        placeholder="Select the calendar provider"
                      />
                    </SelectTrigger>
                    <SelectContent className="">
                      <SelectGroup>
                        <SelectItem value="nitrobot">Nitrobot</SelectItem>
                        <SelectItem value="google">Google Calendar</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>

                {provider === "google" && (
                  <div className="flex items-center gap-3">
                    <div className="flex w-1/2 flex-grow flex-col gap-3">
                      <Label className="text-black" htmlFor="connection">
                        Select Google Calendar Connection
                      </Label>
                      <Select
                        value={googleCalendarConnectionId}
                        onValueChange={setGoogleCalendarConnectionId}
                        id="connection"
                      >
                        <SelectTrigger className={cn("border-[#b7bbc1]")}>
                          <SelectValue
                            defaultValue={googleCalendarConnectionId}
                            placeholder={
                              googleCalendarConnectionId ||
                              "Select Calendar Connection"
                            }
                          />
                        </SelectTrigger>

                        <SelectContent className=" overflow-y-scroll ">
                          <SelectGroup>
                            <SelectLabel>Available Connections</SelectLabel>
                            {googleCalendarConnections &&
                              googleCalendarConnections.length > 0 &&
                              googleCalendarConnections.map((connection) => (
                                <SelectItem
                                  key={connection.connection_id}
                                  value={connection.connection_id}
                                  disabled={
                                    !connection.details.connection_status
                                  }
                                  className="flex items-center justify-between"
                                >
                                  {connection.details.email}
                                  {!connection.details.connection_status ? (
                                    <p className="text-red-800">
                                      (Not Connected)
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </SelectItem>
                              ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="flex w-1/2 flex-grow flex-col gap-3">
                      <Label htmlFor="google-calendar-id">
                        Google Calendar ID
                      </Label>
                      <Input
                        value={googleCalendarId}
                        onChange={(e) => {
                          setGoogleCalendarId(e.target.value);
                        }}
                        id="google-calendar-id"
                        placeholder="Google Calendar ID"
                        required
                        className="block"
                      />
                    </div>
                  </div>
                )}

                <div className="grid w-full grid-cols-2 items-center gap-3">
                  <div className="flex w-full flex-grow flex-col gap-3">
                    <Label className="text-black">Days off</Label>
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          className={cn("w-full truncate border-[#b7bbc1] ")}
                        >
                          {daysOff.length > 0 ? (
                            <div className="flex w-full items-center gap-1 truncate md:overflow-visible">
                              {daysOff.map((day, index) => (
                                <div key={index}>
                                  {day}
                                  {", "}
                                </div>
                              ))}
                            </div>
                          ) : (
                            "Select"
                          )}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent>
                        <div className="grid gap-4">
                          <p className="text-sm text-black">
                            Bot will not book appointments for the days that you
                            select.
                          </p>
                          <div className="grid gap-2">
                            <div className="flex flex-col items-center gap-2">
                              {days.map((day) => (
                                <>
                                  <div className="flex w-full items-start justify-start gap-x-4">
                                    <Checkbox
                                      id={`day-${day}`}
                                      checked={daysOff.includes(day)}
                                      onCheckedChange={(checked) => {
                                        return checked
                                          ? setDaysOff([...daysOff, day])
                                          : setDaysOff(
                                              daysOff.filter((e) => e !== day),
                                            );
                                      }}
                                    />
                                    <Label
                                      htmlFor={`day-${day}`}
                                      className="text-sm font-medium leading-none text-black peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                    >
                                      {day}
                                    </Label>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>

                  <div className="flex w-full flex-grow flex-col gap-3">
                    <Label htmlFor="appointment-duration">
                      Appointment Duration
                    </Label>
                    <Input
                      value={Number(appointmentDuration)}
                      onChange={(e) => {
                        setAppointmentDuration(String(e.target.value));
                      }}
                      type="number"
                      onKeyPress={(event) => {
                        if (event.key === "." || event.key === ",") {
                          event.preventDefault();
                        }
                      }}
                      min={0}
                      id="appointment-duration"
                      placeholder="Appointment Duration"
                      required
                    />
                  </div>
                </div>

                <h2 className="text-lg font-bold">Time Settings</h2>

                <div className="flex items-center gap-3">
                  <div className="flex w-1/2 flex-grow flex-col gap-3">
                    <Label htmlFor="start-time">Book Appointments From</Label>
                    <Input
                      value={appointmentStartTime}
                      onChange={(e) => {
                        setAppointmentStartTime(e.target.value);
                      }}
                      type="time"
                      id="start-time"
                      placeholder="Book Appointments From"
                      required
                      className="block"
                    />
                  </div>

                  <div className="flex w-1/2 flex-grow flex-col gap-3">
                    <Label htmlFor="end-time">Book Appointments To</Label>
                    <Input
                      value={appointmentEndTime}
                      onChange={(e) => {
                        setAppointmentEndTime(e.target.value);
                      }}
                      type="time"
                      id="end-time"
                      placeholder="Book Appointments To"
                      required
                      className="block"
                    />
                  </div>
                </div>

                <div className="flex items-center gap-3">
                  <div className="flex w-1/2 flex-col gap-3">
                    <Label htmlFor="time-zone">Time Zone</Label>
                    <Popover
                      open={timeZoneBoxOpen}
                      onOpenChange={setTimeZoneBoxOpen}
                    >
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          role="combobox"
                          aria-expanded={timeZoneBoxOpen}
                          className={cn(
                            "w-full justify-between border-[#b7bbc1]",
                          )}
                        >
                          {timeZone !== "" ? timeZone : "Select TimeZone"}
                          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-[200px] p-0">
                        <Command>
                          <CommandInput
                            placeholder="Search..."
                            className="h-9"
                          />
                          <CommandEmpty>No Timezone found.</CommandEmpty>
                          <CommandGroup className={cn("h-[200px]")}>
                            {Timezones.map((zone) => (
                              <CommandItem
                                key={zone}
                                value={zone}
                                onSelect={(currentValue) => {
                                  setTimeZone(currentValue);
                                  setTimeZoneBoxOpen(false);
                                }}
                                className={cn("cursor-pointer capitalize")}
                              >
                                {zone}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="item flex w-full justify-end">
            <Button
              onClick={() => {
                setOpen(true);
              }}
              type="button"
            >
              Save Settings
            </Button>
          </div>
        </form>
        <UpgradeDialog open={open} setOpen={setOpen} />
      </div>
    </div>
  );
}
