/* eslint-disable */
import { useState, React } from "react";
import {
  CalendarIcon,
  ClockIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogClose,
} from "src/Shadcn/ui/dialog";
import { Button } from "src/Shadcn/ui/button";
import { useToast } from "src/Shadcn/ui/use-toast";
import { Separator } from "src/Shadcn/ui/separator";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "src/Shadcn/ui/card";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "src/Shadcn/ui/select";

export function SingleAppointmentCard({ appointment }) {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(appointment.status || "Pending");
  const [open, setOpen] = useState(false);

  const handleDelete = async () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      toast({
        title: "Success",
        description: "Appointment Deleted Successfully.",
      });
      setOpen(false);
    }, 2000);
  };

  const handleUpdate = async (value) => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      toast({
        title: "Success",
        description: "Appointment Updated Successfully.",
      });
      setOpen(false);
    }, 2000);
  };

  const handleStatusChange = async (value) => {
    setStatus(value);
    handleUpdate(value);
  };

  function timeConverter(dateStr) {
    let date = new Date(dateStr);
    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes();
    let suffix = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutes}${suffix}`;
  }

  const handleAddToCalendar = () => {
    const event = {
      title: "Appointment with " + appointment.name,
      start: appointment.appointment_start_time,
      end: appointment.appointment_end_time,
      details: appointment.appointment_summary,
      location: "Online Meeting",
    };

    const baseURL =
      "https://calendar.google.com/calendar/render?action=TEMPLATE";
    const url = `${baseURL}&text=${encodeURIComponent(event.title)}&dates=${event.start}/${event.end}&details=${encodeURIComponent(event.details)}&location=${encodeURIComponent(event.location)}`;

    window.open(url, "_blank");
  };

  return (
    <>
      <Card className="flex h-full flex-col">
        <CardHeader>
          <CardTitle> {appointment.name}</CardTitle>
          <CardDescription>{appointment.email}</CardDescription>{" "}
          <CardDescription>{appointment.phone_number}</CardDescription>{" "}
          <Separator orientation="horizontal" />
        </CardHeader>
        <CardContent className="grow">
          <div className="grid gap-y-6">
            <div className="flex items-start justify-between space-x-4">
              <div className="flex items-center space-x-4">
                <div className="grid w-full grid-cols-1 gap-y-2 md:flex-row">
                  <p className="col-span-1 flex items-center gap-x-2 rounded px-2 text-sm font-medium leading-none">
                    <CalendarIcon className="mr-1 inline-block h-5 w-5" />
                    {new Date(
                      appointment.appointment_start_time,
                    ).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </p>
                  <div className="col-span-1 flex items-center gap-x-2 rounded px-2">
                    <ClockIcon className="mr-1 inline-block h-5 w-5" />
                    <p className="text-sm font-medium leading-none">
                      {" "}
                      {timeConverter(appointment.appointment_start_time)}
                    </p>
                    <p className="text-sm leading-none text-muted-foreground">
                      to
                    </p>
                    <p className="text-sm font-medium leading-none">
                      {timeConverter(appointment.appointment_end_time)}
                    </p>
                  </div>
                </div>
              </div>

              <Select
                className=""
                value={status}
                onValueChange={(value) => {
                  handleStatusChange(value);
                }}
                defaultValue="Pending"
              >
                <SelectTrigger className="ml-auto w-[110px]">
                  <SelectValue placeholder="GPT Model" />
                </SelectTrigger>
                <SelectContent className="">
                  <SelectGroup>
                    <SelectLabel>Status</SelectLabel>
                    <SelectItem value="Pending">Pending</SelectItem>
                    <SelectItem value="Approved">Approved</SelectItem>
                    <SelectItem value="Cancelled">Cancelled</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div>
              <p className="text-sm font-medium leading-none">Summary</p>
              <p className="text-sm text-muted-foreground">
                {" "}
                {appointment.appointment_summary}
              </p>
            </div>
          </div>
        </CardContent>
        <CardFooter className="flex items-center justify-end gap-x-2 pt-0">
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
              <Button
                onClick={() => {
                  setOpen(true);
                }}
                type="button"
                className="bg-red-600 hover:bg-red-900"
              >
                <TrashIcon className="inline-block h-5 w-5" />
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[350px]">
              <h2 className="text-md text-black">
                Are you sure you want to delete this appointment?
              </h2>

              <div className="flex gap-2">
                <DialogClose asChild>
                  <Button type="button" variant="secondary">
                    No Take me Back
                  </Button>
                </DialogClose>
                <Button
                  type="button"
                  className="bg-red-600 hover:bg-red-900"
                  onClick={handleDelete}
                >
                  {loading ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="h-5 w-5 animate-spin"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                      />
                    </svg>
                  ) : (
                    <>
                      <TrashIcon className="mr-2 inline-block h-4 w-4" />
                      Yes
                    </>
                  )}
                </Button>
              </div>
            </DialogContent>
          </Dialog>
          <Button onClick={() => handleAddToCalendar()} type="button">
            Add to Calender
          </Button>
        </CardFooter>
      </Card>
    </>
  );
}
