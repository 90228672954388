// export const Domain = "https://api.nitrobot.io/";
// export const Home = "https://app.nitrobot.io/";
// export const apiDomain = "https://api.nitrobot.io/";
// export const socketDomain = "api.nitrobot.io/";
// export const whatsAppverifyToken = "2d248352-5289-4005-8497-b5cb0726722f";

export const Domain = "http://localhost:8000/";
export const Home = "http://localhost:3000/";
export const apiDomain = "http://localhost:8000/";
export const socketDomain = "localhost:8000/";
export const whatsAppverifyToken = "2d248352-5289-4005-8497-b5cb0726722f";
